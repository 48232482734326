

import { BrowserRouter, Navigate, Route, Router, Routes } from 'react-router-dom';


import React, { Fragment, useContext } from 'react';
import HomeProfesionalView from '../views/profesional/home_profesional_view';
import TurnosProfesionalView from '../views/profesional/turnos_profesional_view';
import ValoracionesProfesionalView from '../views/profesional/valoraciones_profesional_view';
import PerfilProfesionalView from '../views/profesional/perfil_profesional_view';
import { AuthContext } from '../context/auth_context';
import RegisterConfirmationView from '../views/profesional/register_confirmation_view';
import DocumentacionProfesionalView from '../views/profesional/documentacion_profesional_view';




function PorfesionalNavigator() {
  return (
  
    <PrivateRoute>
        <Routes>
            <Route  path="/" element={<HomeProfesionalView></HomeProfesionalView>} />
            <Route  path="/turnos" element={<TurnosProfesionalView></TurnosProfesionalView>} />
            <Route  path="/valoraciones" element={<ValoracionesProfesionalView></ValoracionesProfesionalView>} />
            <Route  path="/perfil" element={<PerfilProfesionalView></PerfilProfesionalView>} />
            <Route  path="/confirmacion" element={<RegisterConfirmationView></RegisterConfirmationView>} />
            <Route  path="/documentacion" element={<DocumentacionProfesionalView></DocumentacionProfesionalView>} />

        </Routes>  
    </PrivateRoute>
    
  );
}



function PrivateRoute({ children }) {
  
    const { expired, loading } = useContext(AuthContext);


   let user = JSON.parse(localStorage.getItem('user'));
   let token = localStorage.getItem('token');
   if(user !== null && token !== null){
 

     if(user?.idUserType === 1 || user?.idUserType === 2 || user?.idUserType === 3){
      if(!expired && !loading){
        return children;
      }
       return <></>
     }
     return<Navigate to="/" />
 
   }
   return <Navigate to="/" />
  
   

	  return user?.idUserType === 1 || user?.idUserType === 2 || user?.idUserType === 3 ? children : <Navigate to="/" />;

    // return 2==2 ? children : <Navigate to="/" />;
}

export default PorfesionalNavigator;
