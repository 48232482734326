

import { BrowserRouter, Navigate, Route, Router, Routes } from 'react-router-dom';


import React, { Fragment, useContext } from 'react';
import HomeClinicaView from '../views/clinica/home_clinica_view';
import ValoracionesClinicalView from '../views/clinica/valoraciones_clinica_view';
import PublicarTurnoClinicaView from '../views/clinica/publicar_turno_clinica_view';
import FacturaciónClinicalView from '../views/clinica/facturacion_clinica_view';
import PerfilClinicaView from '../views/clinica/perfil_clinica_view';
import ResumenClinicalView from '../views/clinica/resumen_clinica_view';
import UsuariosBloqueadosClinicView from '../views/clinica/usuarios_bloqueados_clinica_view';
import DocumentacionUsuarioClinicaView from '../views/clinica/documentación_usuario_clinica_view';
import { AuthContext } from '../context/auth_context';
import RegisterAddCenterView from '../views/clinica/register_add_center_view';
import ProfesionalesClinicaView from '../views/clinica/profesionales_clinica_view';
import PerfilProfesionalClinicView from '../views/clinica/perfil_profesional_clinica_view';






function ClinicNavigator() {
  return (
  
    <PrivateRoute>
        <Routes>
            <Route  path="/" element={<HomeClinicaView></HomeClinicaView>} />
            <Route  path="/valoraciones" element={<ValoracionesClinicalView></ValoracionesClinicalView>} />
            <Route  path="/publicar" element={<PublicarTurnoClinicaView></PublicarTurnoClinicaView>} />
            <Route  path="/facturacion" element={<FacturaciónClinicalView></FacturaciónClinicalView>} />
            <Route  path="/perfil" element={<PerfilClinicaView></PerfilClinicaView>} />
            <Route  path="/resumen" element={<ResumenClinicalView></ResumenClinicalView>} />
            <Route  path="/usuarios" element={<UsuariosBloqueadosClinicView></UsuariosBloqueadosClinicView>} />
            <Route  path="/profesionales" element={<ProfesionalesClinicaView></ProfesionalesClinicaView>} />
            <Route  path="/profesionales/:idUser" element={<PerfilProfesionalClinicView></PerfilProfesionalClinicView>} />
            <Route path="/center" element={<RegisterAddCenterView></RegisterAddCenterView>}/>
            <Route path="/documentacion/:idUser" element={<DocumentacionUsuarioClinicaView></DocumentacionUsuarioClinicaView>}/>

        </Routes>  
    </PrivateRoute>
    
  );
}



function PrivateRoute({ children }) {


  const { expired, loading } = useContext(AuthContext);


  let user = JSON.parse(localStorage.getItem('user'));
  let token = localStorage.getItem('token');
  if(user !== null && token !== null){


    if(user?.idUserType === 4 ){
     if(!expired && !loading){
       return children;
     }
      return <></>
    }
    return<Navigate to="/" />

  }
  return <Navigate to="/" />
  
  // const { user } = useContext(AuthContext);

  // return user?.idUserType === 4 ? children : <Navigate to="/" />;



  

}


export default ClinicNavigator;
