import React, {useContext, useEffect, useState} from 'react'
import CheckBox from '../../components/nu_checkbox'
import { profile_data } from '../../utils/forms'
import NuComboBox from '../../components/nu_combobox'
import Renderif from '../../utils/renderif';
import { useNavigate } from 'react-router-dom';
import { Service } from '../../services/api';
import { AuthContext } from '../../context/auth_context';
import NuLoading from '../../components/nu_loading';
import { NotificationContext } from '../../context/notification_context';
import { FaRegCalendar, FaRegCalendarPlus, FaRegCalendarTimes } from 'react-icons/fa';
import { darkBlue } from '../../styles/colors';
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";
import { TbMapPinPlus } from "react-icons/tb";

export default function RegisterConfirmationView() {
    const navigate = useNavigate();

    var forms = profile_data;

    const {user, user_data, setUserData} = useContext(AuthContext)
    const {notify} = useContext(NotificationContext);

    const [state, setState] = useState({})

   

    useEffect(()=>{
       
        const init = async () => {
            setUserData();
        };
        init()
        return () => {}
    },[])


  return (
    <>
  
    <div className='container-fluid h-100'>

        <div className='d-flex h-100'>

            <div className='col-12 col-md-3 d-none d-md-block'>
                <div className='register_bg bg-cover'></div>
            </div>
            
            <div className='col-12 col-md-9 d-flex flex-column overflow-auto hide-scroll'>


                <div className='contianer p-1 px-md-5'>
                

                <div className='d-flex flex-column justify-content-center align-items-center my-4 '>
                    <img width={100} src='/assets/tick-circle.png'></img>
                    <div className='f-32 bold text-center'>¡Tu perfil está completo!</div>
                </div>

                <div className='bg-gray-00 -gray r-10 p-3 p-md-5 m-1 mx-md-5 mt-3'>
                   <div className='d-flex  align-items-center'>
                        <div className='pe-5'>
                            <div className='nu-icon nu-icon--big'>
                                <TbMapPinPlus size={35} color={darkBlue}></TbMapPinPlus>
                            </div>
                        </div>
                        <div className='f-16'>
                        Se te mostrarán todos los turnos que coinciden con tu criterio de elección. Como Profesional, <span className='text-darkblue bolder'>#TúEliges</span>.
                        </div>
                   </div>
                </div>

                <div className='bg-gray-00 -gray r-10  p-3 p-md-5 m-1 mx-md-5 mt-3'>
                   <div className='d-flex  align-items-center'>
                        <div className='pe-3 pe-md-5'>
                            <div className='nu-icon nu-icon--big'>
                                <HiOutlineClipboardDocumentCheck size={50} color={darkBlue}></HiOutlineClipboardDocumentCheck>
                            </div>
                        </div>
                        <div className='f-16'>
                        Verás que te mostraremos también <span className='bolder'>turnos de acogida remunerados</span>. Es importante que realices turnos de acogida en centros y/o unidades asistenciales con los que no estés familiarizado para tener más opciones de recibir sus turnos disponibles.

                        </div>
                   </div>
                </div>

                <div className='bg-gray-00 -gray r-10 p-3 p-md-5 m-1 mx-md-5 mt-3'>
                   <div className='d-flex  align-items-center'>
                        <div className='pe-3 pe-md-5'>
                            <div className='nu-icon nu-icon--big'>
                                <FaRegCalendarPlus size={30} color={darkBlue}></FaRegCalendarPlus>
                            </div>
                        </div>
                        <div className='f-16'>
                        Para poder asignarte turnos deberás <span className='bold'>acceder al área privada y subir la documentación necesaria para completar tu validación.</span>
                        </div>
                   </div>
                </div>

                <div className='d-flex mt-4'>
                    <div className='col'></div>
                    <div className='nu_btn nu_btn--overlay' onClick={()=>{
                        navigate('/profesional')
                    }}>
                        <div className='nu_btn-text f-18'>Acceder</div>
                        <div className='nu_btn-overlay'></div>
                    </div>
                    <div className='col'></div>
                </div>

                <div className='d-block d-md-none my-5'></div>
                



                </div>

            </div>
        </div>
    </div>
    </>
  )
}
