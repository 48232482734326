import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { MdArrowBack, MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { FaStar } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { AdminSideMenu, ClinicSideMenu, ProfesionalSideMenu } from '../../components/nu_sidemenu';
import { AuthContext } from '../../context/auth_context';
import { Service } from '../../services/api';
import { GlobalContext } from '../../context/global_context';
import { AdminContext } from '../../context/admin_context';
import NuSelect from '../../components/nu_select';
import { useNavigate, useParams } from 'react-router-dom';
import { darkBlue } from '../../styles/colors';
export default function ValoracionesAdminView() {
    const {user, user_data} = useContext(AuthContext);
    const {getCenter, center} = useContext(AdminContext);
    const {idUser, type} = useParams()
    const navigate = useNavigate()
    const [state, setState] = useState({
 
    
        page:1,
        count:99999,
        valoraciones:[],
        valoracionesCount:0
    })



    useEffect(()=>{
        const init = async() => {

            // let _centers = center.filter((center) =>  center.idOrganization === user_data.id)
            // console.log(_centers) 
            // let _valoraciones
            // _centers.map(async(center)=>{
            //     _valoraciones = await Service('GET', 'rating/getAll?idCenter='+center.id);
            //     console.log(_valoraciones);
            //     // setState({...state, valoraciones:_valoraciones.data.list})

            // })
           
            let _valoraciones = await loadValoraciones({page:1, count:state.count, status:state.filters})
            console.log(_valoraciones.data)

            let valoraciones = _valoraciones.data.list.filter((item)=>{
                if(item.type === type){
                    return true
                }
                return false
            })

            if(type === '0'){
                console.log("VALORACIONES DE PROFESIONAL")
                valoraciones = valoraciones.filter((item)=>item.idUser === parseInt(idUser))
            }
            if(type === '1'){
                console.log("VALORACIONES DE CENTROS DE idUser")
                let _organization = await Service('GET', `organizationData/getByIdUser/${idUser}`)
                let idOrganization = _organization.data.organizationData.id

                let _centers = center.filter((item)=> item.idOrganization === idOrganization);

                valoraciones = valoraciones.filter((val)=>{
                    
                    let center_exist = _centers.filter((center)=>{
                        if(center.id === val.idCenter){
                            return true
                        }
                    })
                    if(center_exist.length > 0){
                        return true
                    }
                    
                })

                console.log(center);
                // valoraciones = valoraciones.filter((item)=>item.idUser === parseInt(idUser))
            }
            // console.log(valoraciones)
           
         

       
            setState({
              ...state, 
              loading:false,
              valoraciones:valoraciones, 
            //   valoracionesCount:_valoraciones.data.total,
            })
           
        };
        init();
    },[])

    const renderPagination = () => {


      let pages = Math.ceil(state.valoracionesCount / state.count);
      let items = []
      for (let i = 1; i <= pages; i++) {
          items.push(i);
      }

      return (
          <div>
             <span className='bold'>{state.page}</span> de <span className='bold'>{items.length}</span>
          </div>
      )
    }

    const loadPage = async (page) => {
        setState({...state, loading:true})
        let _valoraciones = await loadValoraciones({page:state.page + page, count:state.count, status:state.filters})

        setState({...state, 
            page:state.page + page,
            loading:false, 
            valoraciones:_valoraciones.data.list, 
            valoracionesCount:_valoraciones.data.total,
    
        })
    }

    const loadValoraciones = async ({page, count, status = ''}) => {

      setState({...state, loading:true})
      let _valoraciones;
      _valoraciones = await Service('GET', `rating/getAll?page=${page}&pageCount=${count}&type=1`)      
      return _valoraciones;

  
  }

  return (
    <div className='container-fluid overflow-hidden d-flex h-100'>


        <AdminSideMenu page='valoraciones' />
   


        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-3 px-3'>
     

                <div className='nu-snack'>
                <div>
                        <div className='f-22 text-white bold'>Hola, {user?.name}</div>
                        
                    
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid' style={{backgroundImage: 'url(/assets/avatar.png)'}}></div>
                    <div className='nu-snack-overlay'></div>
                </div>
                       
            </div>

            <div className='container p-3'>

                <div className='d-flex align-items-center cursor-pointer mb-2' onClick={()=>{
                    navigate(-1)
                }}>
                        <div className='nu-icon nu-icon--mid'>
                            <MdArrowBack size={17} color={darkBlue}></MdArrowBack>
                        </div>
                        <div className='f-14 bold text-darkblue'>Atrás</div>
                </div>


                <div className='f-22 bold'>Valoraciones</div>
                {/* <div className='d-flex align-items-center mt-3'>
                    <div className='f-16 bold'>Filtrar por: </div>

                    <div className='mx-2'>
                       <NuSelect value={state.filters} onChange={(evt)=>{
                      
                                  
                                state.filters = evt.currentTarget.value
                                setState({...state})
                      
                       }}>
                            <option value="">Centro</option>
                            {
                              center.map((center, i) => {
                       
                                return(
                                  <option key={i} value={center.id}>{center.name}</option>
                                )
                              })
                            }
                       
                           
                       </NuSelect>
                    </div>


                    <div className='col'></div>

                </div> */}
                {state.valoraciones.map((item, i)=>{
                    return (
                        <div key={i} className='bg-gray r-10 p-4 mt-2'>

                            <div>
                                <div className='d-flex'>
                                    <div className='f-16'><span className='bold'>Centro:</span> {getCenter(item.idCenter).name}</div>
                                    <div className='col'></div>
                                    <div className='d-flex align-items-center'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <FaStar size={16} color='#FFB054'></FaStar>
                                        </div>
                                        <div className='f-16 bolder'>{item.stars}</div>
                                    </div>
                                </div>
                                {/* <div className='f-16'><span className='bold'>Turno de:</span> {item.type === '0' ? 'Acogida':'Cobertura'}</div> */}
                              
                                <div className='f-16'>
                                {item.comment}
                                </div>
                            </div>
        
                        </div>
                    )
                })}

             

              
        
                

               

              
          
            </div>

            {/* <div className='d-flex justify-content-center my-5'>
                    <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                          loadPage(-1)
                    }}>
                        <SlArrowLeft></SlArrowLeft>
                    </div>
                    <div className='d-flex flex-wrap px-3'>
                        {renderPagination()}
                    </div>
                    <div className='nu-icon nu-icon--mid cursor-pointer' onClick={async ()=>{

                        loadPage(1)

                        
                        // setState({...state, page:state.page + 1})
                    }}>
                        <SlArrowRight></SlArrowRight>
                    </div>
                </div> */}

       
      
        </div>

   
   
  
     
    </div>
  )
}
