import React from 'react'
import { MdKeyboardArrowDown } from "react-icons/md";

export default function NuSelect({className, children, onChange, disabled, value=''}) {
  return (
    <div className={`nu-select ${className} `}>
        <div className='nu-icon nu-icon--big'>
            <MdKeyboardArrowDown color='#CCC' size={30}></MdKeyboardArrowDown>
        </div>
        <select multiple={false} disabled={disabled} value={value} onChange={(evt)=>onChange(evt)}>
            {
                children
            }
        </select>
    </div>
  )
}
