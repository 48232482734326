import React, {useContext, useEffect, useState} from 'react'
import CheckBox from '../../components/nu_checkbox'
import { profile_data } from '../../utils/forms'
import NuComboBox from '../../components/nu_combobox'
import Renderif from '../../utils/renderif';
import { useNavigate } from 'react-router-dom';
import { Service } from '../../services/api';
import { AuthContext } from '../../context/auth_context';
import NuLoading from '../../components/nu_loading';
import { NotificationContext } from '../../context/notification_context';

export default function RegisterEnfermeria() {
    const navigate = useNavigate();

    var forms = profile_data;

    const {user, user_data} = useContext(AuthContext)
    const {notify} = useContext(NotificationContext);

    const [state, setState] = useState({
        errors: {},
        loading:false,
        rol_selected:0,
        form_step:1,
        provincias: [
            {
                provincia: 'Andalucía',
                colegios: ['Almería', 'Cádiz', 'Córdoba', 'Granada', 'Huelva', 'Jaén', 'Málaga', 'Sevilla']
            },
            {
                provincia: 'Aragón',
                colegios: ['Zaragoza', 'Teruel', 'Huesca']
            },
            {
                provincia: 'Principado de Asturias',
                colegios: ['Asturias']
            },
            {
                provincia: 'Illes Balears',
                colegios: ['Illes Balears']
            },
            {
                provincia: 'Canarias',
                colegios: ['Las Palmas', 'Santa Cruz de Tenerife']
            },
            {
                provincia: 'Cantabria',
                colegios: ['Cantabria']
            },
            {
                provincia: 'Castilla y León',
                colegios: ['Ávila', 'Burgos', 'León', 'Palencia', 'Salamanca', 'Segovia', 'Soria', 'Valladolid', 'Zamora']
            },
            {
                provincia: 'Castilla y la Mancha',
                colegios: ['Albacete', 'Ciudad Real', 'Cuenca', 'Guadalajara y Toledo']
            },
            {
                provincia: 'Cataluña',
                colegios: ['Barcelona', 'Tarragona', 'Girona', 'Lleida']
            },
            {
                provincia: 'Comunidad Valenciana',
                colegios: ['Alicante', 'Valencia', 'Castellón']
            },
            {
                provincia: 'Extremadura',
                colegios: ['Cáceres','Badajoz']
            },
            {
                provincia: 'Galicia',
                colegios: ['A Coruña','Lugo', 'Ourense', 'Pontevedra']
            },
            {
                provincia: 'Murcia',
                colegios: ['Murcia']
            },
            {
                provincia: 'Madrid',
                colegios: ['Madrid']
            },
            {
                provincia: 'Navarra',
                colegios: ['Navarra']
            },
            {
                provincia: 'País Vasco',
                colegios: ['Bizcaia','Gipuzkoa','Araba']
            },
            {
                provincia: 'La Rioja',
                colegios: ['La Rioja']
            },
            {
                provincia: 'Ceuta',
                colegios: ['Ceuta']
            }
            ,
            {
                provincia: 'Melilla',
                colegios: ['Melilla']
            }
            
        ],
        provinciaSelected:null,
        colegios:[],
        nurse_data:{
            idUser: user?.id,
            collegiateNumber:'',
            collegeProvince: '',
            college: '',
            preferredRadio: 0,
            yearsOfExperience: '0',
            //Propiedad yearsOfExperience es un enum, solo acepta los valores "0", "1", "2" y "3"
            //"0" - Sin experiencia previa
            //"1" - 1-5 años
            //"2" - 6-10 años
            //"3" - > 10 años
            idSpecialization: 1
        },
        centerType:[],
        assistanceUnit:[],
        professionalField:[],


        preferredCenter:[
            {
                idUser:user?.id,
                idCenterType:1
            }
        ],
        preferredAssistanceUnit:[
            {
                idUser:user?.id,
                idAssistanceUnit:1
            }
        ],
        preferredProfessionalField:[
            {
                idUser:user?.id,
                idProfessionalField:1
            }
        ],
        
       
        assistanceUnitExperience:[],
        professionalFieldExperience:[],

        educationAssistanceUnitList:[],
        educationProfessionalFieldList:[]
    })

    const yearsExperienceOptions = [
        {
            id:0,
            desctiprion:'No tengo experiencia'
        },
        {
            id:1,
            desctiprion:'Menos de un año'
        },
        {
            id:2,
            desctiprion:'Mas de 1 año'
        },

    ]

    useEffect(()=>{
       
        const init = async () => {
       
            let _centeType = await Service('GET', `centerType/getAll`);
            let _assistanceUnit = await Service('GET', `assistanceUnit/getAll`);
            let _professionalField = await Service('GET', `professionalField/getAll`);
       
            setState({
                ...state, 
                centerType:_centeType.data.list,
                assistanceUnit:_assistanceUnit.data.list.filter((item) => item.id !== 16 && item.id !== 14),
                professionalField:_professionalField.data.list
            })
        };
        init()
        return () => {}
    },[])

    const onUpdateNurseData = async () => {
        setState({...state, loading:true})
        if(2==2){
        // if(!user_data?.nurseData){
          
            
            let _create;
            let NurseDataBody = {
                idUser:user.id,
                collegiateNumber:state.nurse_data.collegiateNumber,
                collegeProvince:state.nurse_data.collegeProvince,
                college: state.nurse_data.college,
                preferredRadio: state.nurse_data.preferredRadio,
                yearsOfExperience: state.nurse_data.yearsOfExperience.toString(),
                idSpecialization: state.nurse_data.idSpecialization
            }
            _create = await Service('POST', 'nurseData/create', NurseDataBody);
         


            let PreferredCenterBody = {
                preferredCenterList: state.preferredCenter
            }
            _create = await Service('POST', 'preferredCenter/create', PreferredCenterBody);
           




            let PreferredAssistanceUnitBody = {
                preferredAssistanceUnitList: state.preferredAssistanceUnit
            }
            _create = await Service('POST', 'preferredAssistanceUnit/create', PreferredAssistanceUnitBody);
          



            let PreferredProfessionalFieldBody = {
                preferredProfessionalFieldList: state.preferredProfessionalField
            }
            _create = await Service('POST', 'preferredProfessionalField/create', PreferredProfessionalFieldBody);
    



            let assistanceUnitExperienceBody = {
                assistanceUnitExperienceList: state.assistanceUnitExperience
            }
            _create = await Service('POST', 'assistanceUnitExperience/create', assistanceUnitExperienceBody);
       



            let professionalFieldExperienceBody = {
                professionalFieldExperienceList: state.professionalFieldExperience
            }
            _create = await Service('POST', 'professionalFieldExperience/create', professionalFieldExperienceBody);
         



            let educationAssistanceUnitBody = {
                educationAssistanceUnitList: state.educationAssistanceUnitList
            }
            _create = await Service('POST', 'educationAssistanceUnit/create', educationAssistanceUnitBody);
     



            let educationProfessionalFieldBody = {
                educationProfessionalFieldList: state.educationProfessionalFieldList
            }
            _create = await Service('POST', 'educationProfessionalField/create', educationProfessionalFieldBody);
  


            notify({title:'¡Bienvenido!', message:user.name, type:'success'})
            navigate('/profesional/confirmacion')
       

        }else{
          
            // let _upate = await Service("PUT", 'nurseData/update')
        }
    }

    const validator = () => {
       
        state.errors = {}
        if(state.form_step === 1){
            if(state.nurse_data.collegiateNumber === ''){
                state.errors.collegiateNumber = 'Este campo es obligatorio'
            }
            if(state.nurse_data.collegeProvince === ''){
                state.errors.collegeProvince = 'Este campo es obligatorio'
            }
            if(state.nurse_data.college === ''){
                state.errors.college = 'Este campo es obligatorio'
            }
        }

        if(state.form_step === 2){
            if(state.nurse_data.yearsOfExperience === ''){
                state.errors.yearsOfExperience = 'Este campo es obligatorio'
            }
            if(state.nurse_data.idSpecialization === null){
                state.errors.idSpecialization = 'Este campo es obligatorio'
            }
        }
       
        setState({...state})
        if(Object.keys(state.errors).length > 0){
            
            return false;
        }
       return true;
        

    }

    const getEducations = (data) => {
        let _items = data.filter((item) => true);
        _items.shift()
        


        return _items;
    }

    const assistanceUnitOptionChecked = (value, option) =>{

        let _aux = state.assistanceUnitExperience.filter((item)=>{
            if(item.idAssistanceUnit === option.id && item.yearsOfExperience === value){
                return true;
            }
            return false;
        })

        if(_aux.length > 0){
            return true
        }
        return false

    }
    const optionChecked = (value, option) =>{

        let _aux = state.professionalFieldExperience.filter((item)=>{
            if(item.idProfessionalField === option.id && item.yearsOfExperience === value){
                return true;
            }
            return false;
        })

        if(_aux.length > 0){
            return true
        }
        return false

    }
    
    
    


  return (
    <>
    <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
    </Renderif>
    <div className='container-fluid h-100'>

        <div className='d-flex h-100'>

            <div className='col-12 col-md-3 d-none d-md-block'>
                <div className='register_bg bg-cover'></div>
            </div>
            
            <div className='col-12 col-md-9 d-flex flex-column overflow-auto hide-scroll' id="view">

          
                <div className='container'>
                    <div className='d-flex justify-content-center py-5 mx-1 mx-md-5 mt-1 mt-md-3 rt-10 bg-darkblue'>
                       <div className='f-34 text-white bold'>Enfermería</div>
                    </div>
                </div>
         

                <div className='container d-flex flex-column px-1 px-md-5'>
                    <div className='sticky-top'>
                        <div className='d-flex bg-white py-4'>

                            <div className='col pe-1 pe-md-5'>
                                <div className={`bar bar--${state.form_step >= 1 ? 'active':''}`}></div>
                                <div className='f-16 bolder line-1'>Paso 1</div>
                                <div className='f-14 text-gray line-1'>General</div>
                            </div>

                            <div className='col pe-1 pe-md-5'>
                                <div className={`bar bar--${state.form_step >= 2 ? 'active':''}`}></div>
                                <div className='f-16 bolder line-1'>Paso 2</div>
                                <div className='f-14 text-gray line-1'>Profesión</div>
                            </div>

                            <div className='col pe-1 pe-md-5'>
                                <div className={`bar bar--${state.form_step >= 3 ? 'active':''}`}></div>
                                <div className='f-16 bolder line-1'>Paso 3</div>
                                <div className='f-14 text-gray line-1'>Preferencias</div>
                            </div>

                            <div className='col pe-1 pe-md-5'>
                                <div className={`bar bar--${state.form_step >= 4 ? 'active':''}`}></div>
                                <div className='f-16 bolder line-1'>Paso 4</div>
                                <div className='f-14 text-gray line-1'>Experiencia</div>
                            </div>

                            <div className='col'>
                               <div className={`bar bar--${state.form_step >= 5 ? 'active':''}`}></div>
                                <div className='f-16 bolder line-1'>Paso 5</div>
                                <div className='f-14 text-gray line-1'>Formación</div>
                            </div>

                        
                        </div>
                    </div>

                    <Renderif isTrue={state.form_step === 1}>
                        <div className='bg-gray-00 -gray r-10 p-4 p-md-5'>
                            <div className='f-32 bolder mb-3'>Bienvenido (a)</div>
                            <div className='f-22 bold'>Define tu perfil profesional y tus preferencias</div>
                            <div className='f-16'>Sigue con el registro y verás publicados turnos de acuerdo con tu <span className='bolder'>criterio de elección</span>.</div>
                        </div>

                        <div className='bg-gray-00 -gray r-10 p-3 p-md-5 mt-3'>
                            <div className='f-22 bold'>Número de colegiado(a)</div>
                            <div className='f-16'>Más adelante te pediremos la foto de tu carnet de colegiado/a.</div>
                            <div className='mt-3'>
                                <input type="text" placeholder='Número de colegiado' value={state.nurse_data.collegiateNumber} onChange={(evt)=>{
                                    state.nurse_data.collegiateNumber = evt.currentTarget.value;
                                    setState({...state})

                                }} className='nu-input nu-input--light'></input>
                                 <div className='text-danger f-12'>{state.errors?.collegiateNumber}</div>
                            </div>

                            <div className='mt-3'>
                                <div className='f-22 bold'>Comunidad Autónoma </div>
                                <div className='f-16 opacity-0'>empty</div>
                                <select defaultValue="" onChange={(evt)=>{
                                     
                                        state.nurse_data.collegeProvince = evt.currentTarget.value;
                                        state.provinciaSelected = evt.currentTarget.value;
                                        state.nurse_data.college = ""
                                        setState({...state })


                                    }}  className='nu-select nu-input--light'>
                                    <option value="" disabled>Seleccionar</option>
                                    {
                                        state.provincias.map((item, i)=>{
                                            return(
                                                <option key={i} value={item.provincia}>{item.provincia}</option>
                                            )
                                        })
                                    }
                             
                                </select>
                                <div className='text-danger f-12'>{state.errors?.collegeProvince}</div>
                             
                            </div>

                            <div className='mt-3'>
                                <div className='f-22 bold'>Provincia</div>
                                <div className='f-16 opacity-0'>empty</div>
                                {/* <input placeholder='Lorem ipsum dolor sit amet' className='nu-input nu-input--light'></input> */}
                                <select value={state.nurse_data.college} className='nu-select nu-input--light' onChange={(evt)=>{
                                        state.nurse_data.college = evt.currentTarget.value;
                                        setState({...state })


                                    }}>
                                    <option value="">Seleccionar</option>
                                    {
                                        state.provincias.filter((provincia)=> provincia.provincia === state.provinciaSelected)[0]?.colegios?.map((item, i)=>{

                                            
                                            return(
                                                <option key={i} value={item}>{item}</option>
                                            )
                                        })
                                    }
                             
                                </select>
                                <div className='text-danger f-12'>{state.errors?.college}</div>
                            </div>
                        </div>

                        {/* <div className='bg-gray-00 -gray r-10 p-5 mt-3'>
                     
                            <div className='f-22 bold'>¿Hasta qué distancia de tu código postal <br></br> quieres que te mostremos turnos?</div>
                            <div className='mt-3 mx-5 px-5 '>

                                <div className='d-flex'>
                                    <div className='col-1 d-flex align-items-end'>
                                        <div className='f-12 bold'>0-5km</div>
                                    </div>
                                  
                                    <div className='col-4 justify-content-center d-flex align-items-end '>
                                        <div className='f-12  bold ps-3'>5-10km</div>
                                    </div>


                                    <div className='col-2 justify-content-center  d-flex align-items-end text-center '>
                                        <div className='f-12  bold'>10-15km</div>
                                    </div>
                               
                                    <div className='col-4 justify-content-center  d-flex align-items-end '>
                                        <div className='f-12 bold pe-3'>15-20km</div>
                                    </div>
                               
                        
                             
                                    <div className='col-1  d-flex align-items-end '>
                                        <div className='f-12 bold'>Cualquier distancia</div>
                                    </div>
                              
                                </div>
                                <input className='' value={state.nurse_data.preferredRadio}  step={1}  max={4} type='range' onChange={(evt)=>{
                                  
                                    state.nurse_data.preferredRadio = evt.currentTarget.value;
                                    setState({...state})
                                }}></input>
                            </div>
                           
                        </div> */}
                    </Renderif>

                    <Renderif isTrue={state.form_step === 2}>

                            <div  className='bg-gray-00 -gray r-10 p-4 p-md-5'>
                                <div className='d-flex'>
                                    <div className='col'><div className='f-22 bold'>Años de experiencia en la profesión</div></div>
                                    <div className='col'></div>
                                </div>
                                {/* <div className='f-16'>Te pediremos que subas el título de enfermería en tu área privada una vez registrada</div> */}
                                <div className='my-4'></div>
                                <div className='col'>

                                    <CheckBox checked={state.nurse_data.yearsOfExperience === '0'} text="Sin experiencia" value="0" onChange={(evt)=> {
                                       
                                        state.nurse_data.yearsOfExperience = !evt.value ? '' : '0';
                                        setState({...state})
                                    }} />
                                    <CheckBox checked={state.nurse_data.yearsOfExperience === '1'} text="1-5 años" value="1" onChange={(evt)=> {
                                          state.nurse_data.yearsOfExperience = !evt.value ? '' : '1';
                                          setState({...state})
                                    }} />
                                    <CheckBox checked={state.nurse_data.yearsOfExperience === '2'} text="6-10 años" value="2" onChange={(evt)=> {
                                          state.nurse_data.yearsOfExperience = !evt.value ? '' : '2';
                                          setState({...state})
                                    }} />
                                    <CheckBox checked={state.nurse_data.yearsOfExperience === '3'} text="> 10 años" value="3" onChange={(evt)=> {
                                           state.nurse_data.yearsOfExperience = !evt.value ? '' : '3';
                                          setState({...state})
                                    }} />


                                    <div className='text-danger f-12'>{state.errors?.yearsOfExperience}</div>
                                </div>
                            </div>

                            <div  className='bg-gray-00 -gray r-10 p-4 p-md-5'>
                                <div className='d-flex'>
                                    <div className='col-12 col-md-6'><div className='f-22 bold'>Especialización EIR</div></div>
                                 
                                </div>
                                {/* <div className='f-16'>Te pediremos que subas el título de enfermería en tu área privada una vez registrada</div> */}
                                <div className='my-4'></div>
                                <div className='d-flex flex-wrap'>

                              

                                    {
                                        forms[1].checks.map((item, i)=>{
                                            return (
                                                <div key={i} className='col-6'>
                                                    <CheckBox checked={state.nurse_data.idSpecialization === (i+1)} text={item} value={i + 1} onChange={(evt)=> {
                                                        state.nurse_data.idSpecialization = !evt.value ? null : (i+1)
                                                        setState({...state})
                                                        
                                                        }} />
                                                </div>
          
                                            )
                                        })
                                    }
                                    
                                        {/* <NuComboBox className="col-6" multiple={false} checks={forms[1].checks} onChange={(evt)=>{
                                          
                                            state.nurse_data.idSpecialization = forms[1].checks.findIndex((e) => e === evt[0].key) + 1
                                         
                                          
                                        }}></NuComboBox> */}


                                         <div className='text-danger f-12'>{state.errors?.idSpecialization}</div>


                                   

                                    {/* <div className='col'>

                                        <NuComboBox  multiple={false} checks={forms[1].checks.slice(Math.round(forms[1].checks.length/2))} onChange={(evt)=>{
                                            //  state.nurse_data.idSpecialization = forms[1].checks.findIndex((e) => e === evt[0].key)
                                    
                                        }}></NuComboBox>
                                    </div> */}
                                </div>
                            </div>



                            
                        
                        {
                            forms.slice(2,2).map((item, i)=>{

                           

                                return (
                                    <div key={i} className='bg-gray-00 -gray r-10 p-5 mt-3'>

                                        <div className='d-flex'>
                                            <div className='col'><div className='f-22 bold'>{item.title}</div></div>
                                            <div className='col'></div>
                                        </div>

                                        <div className='f-16'>{item.description}</div>
                                        <div className='my-4'></div>

                                    
                                           <Renderif isTrue={!item.multiple}>
                                             <div className='col border'>
                                                <NuComboBox key={i} multiple={item.multiple} checks={item.checks} onChange={(value)=>{
                                                  
                                                }}></NuComboBox>
                                            </div>
                                           </Renderif>

                                           <Renderif isTrue={item.multiple}>
                                                <div className='d-flex'>
                                                    <div className='col'>
                                                        <NuComboBox key={i} multiple={item.multiple} checks={item.checks.slice(0,Math.round(item.checks.length/2))} onChange={(value)=>{
                                                           
                                                        }}></NuComboBox>
                                                    </div>

                                                    <div className='col'>

                                                        <NuComboBox key={i} multiple={item.multiple} checks={item.checks.slice(Math.round(item.checks.length/2))} onChange={(value)=>{
                                                          
                                                        }}></NuComboBox>
                                                    </div>
                                                </div>
                                           </Renderif>

                            
                                    </div>
                                 
                                )
                            })
                        }
                    </Renderif>

                    <Renderif isTrue={state.form_step === 3}>
                            <div className='bg-gray-00 -gray r-10 p-4 p-md-5'>
                                <div className='d-flex'>
                                    <div className='col-12 col-md-6'><div className='f-22 bold'>Escoge los Centros donde tienes interés en realizar turnos</div></div>
                                    {/* <div className='col-6'></div> */}
                                </div>
                                <div className='f-16'></div>
                                <div className='my-4'></div>
                                <div className='d-flex flex-wrap'>
                                    {
                                        state.centerType.map((item,i)=>{
                                            let _check = state.preferredCenter.some((center)=>center.idCenterType === item.id);
                                            return (
                                                <div key={i} className='col-6'>
                                                      <CheckBox checked={_check} text={item.description} value={item.id} onChange={(evt)=> {

                                                            if(evt.value){
                                                                let _item = {
                                                                    idUser:user.id,
                                                                    idCenterType:item.id
                                                                }
                                                                if(item.id === 1){
                                                                    state.preferredCenter =  []
                                                                }
                                                                if(item.id !== 1){
                                                                    state.preferredCenter =  state.preferredCenter.filter((center) => center.idCenterType !== 1) 
                                                                }
                                                                state.preferredCenter.push(_item)
                                                                setState({...state})
                                                                return
                                                            }
                                                            state.preferredCenter = state.preferredCenter.filter((center) => center.idCenterType !== item.id)
                                                            setState({...state})

                                                        }} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>

                            <div className='bg-gray-00 -gray r-10 p-4 p-md-5'>
                                <div className='d-flex'>
                                    <div className='col-12 col-md-6'><div className='f-22 bold'>Escoge las Unidades Asistenciales donde tienes interés en realizar turnos</div></div>
                                 
                                </div>
                                <div className='f-16'></div>
                                <div className='my-4'></div>
                                <div className='d-flex flex-wrap'>

                                    {
                                        state.assistanceUnit.map((item,i)=>{
                                            let _check = state.preferredAssistanceUnit.some((center)=>center.idAssistanceUnit === item.id);
                                            return (
                                                <div key={i} className='col-6'>
                                                      <CheckBox checked={_check} text={item.description} value={item.id} onChange={(evt)=> {

                                                            if(evt.value){
                                                                let _item = {
                                                                    idUser:user.id,
                                                                    idAssistanceUnit:item.id
                                                                }
                                                                if(item.id === 1){
                                                                    state.preferredAssistanceUnit =  []
                                                                }
                                                                if(item.id !== 1){
                                                                    state.preferredAssistanceUnit =  state.preferredAssistanceUnit.filter((center) => center.idAssistanceUnit !== 1) 
                                                                }
                                                                state.preferredAssistanceUnit.push(_item)
                                                                setState({...state})
                                                                return
                                                            }
                                                            state.preferredAssistanceUnit = state.preferredAssistanceUnit.filter((center) => center.idAssistanceUnit !== item.id)
                                                            setState({...state})

                                                        }} />
                                                </div>
                                            )
                                        })
                                    }
       
                                </div>
                            </div>

                            <div className='bg-gray-00 -gray r-10 p-4 p-md-5'>
                                <div className='d-flex'>
                                    <div className='col-12 col-md-6'><div className='f-22 bold'>En qué ámbitos profesionales quieres realizar turnos</div></div>
                                   
                                </div>
                                <div className='f-16'></div>
                                <div className='my-4'></div>
                                <div className='d-flex flex-wrap'>
                                    {
                                        state.professionalField.filter((item) => item.id !== 7).map((item,i)=>{
                                            let _check = state.preferredProfessionalField.some((center)=>center.idProfessionalField === item.id);
                                            return (
                                                <div key={i} className='col-6'>
                                                      <CheckBox checked={_check} text={item.description} value={item.id} onChange={(evt)=> {

                                                            if(evt.value){
                                                                let _item = {
                                                                    idUser:user.id,
                                                                    idProfessionalField:item.id
                                                                }
                                                                if(item.id === 1){
                                                                    state.preferredProfessionalField =  []
                                                                }
                                                                if(item.id !== 1){
                                                                    state.preferredProfessionalField =  state.preferredProfessionalField.filter((center) => center.idProfessionalField !== 1) 
                                                                }
                                                                state.preferredProfessionalField.push(_item)
                                                                setState({...state})
                                                                return
                                                            }
                                                            state.preferredProfessionalField = state.preferredProfessionalField.filter((center) => center.idProfessionalField !== item.id)
                                                            setState({...state})

                                                        }} />
                                                </div>
                                            )
                                        })
                                    }
                                    {/* <NuComboBox 
                                        first={false} 
                                        className="col-6"  
                                        multiple={true} 
                                        checks={state.professionalField.filter((item) => item.id !== 7)} 
                                        labelKey='description'
                                        onChange={(value)=>{
                                         
                                            state.preferredProfessionalField = []

                                            value.forEach((item)=>{
                                        
                                                let _item = {
                                                    idUser:user.id,
                                                    idProfessionalField:item.key.id
                                                }
                                                state.preferredProfessionalField.push(_item)
                                            })
                                            
                                       
                                        }}>
                                    </NuComboBox> */}
                                </div>
                            </div>
                
                    </Renderif>

                    <Renderif isTrue={state.form_step === 4}>
                            <div className='bg-gray-00 -gray r-10 p-4 p-md-5'>

                                <div className='d-flex'>
                                    <div className='col-12 col-md-6'><div className='f-22 bold'>En qué Unidades Asistenciales tienes experiencia</div></div>
                                   
                                </div>
                                <div className='f-16'></div>
                                <div className='my-4'></div>
                                <div className='col'>
                                    <CheckBox onChange={(evt)=>{
                                    
                                        if(evt.value){
                                            state.assistanceUnitExperience = []
                                        }
                                        setState({...state})
                                    }} checked={state.assistanceUnitExperience.length === 0 ? true: false} text="No tengo experiencia en ninguna Unidad Asistencial" ></CheckBox>
                                </div>
                                <div className='d-flex my-3'>
                                    <div className='col'></div>
                                    <div className='col d-flex justify-content-around '>
                                    <div className='col '>
                                        <div className="f-12 bold text-center line-1 ps-3 ">No tengo <br></br>experiencia</div>
                                    </div>
                                    <div className="f-12 bold text-center line-1">Menos <br></br>de 1 año</div>
                                    <div className='col '>
                                        <div className="f-12 bold text-center line-1 pe-2">Más de <br></br> 1 año</div>
                                    </div>
                                    </div>
                                </div>


                                
                        
                                { state.assistanceUnit.map((option, i)=>{

                                    if(i === 0){
                                        return;
                                    }
                                    return ( 
                                    
                                        <div key={i} className={`d-flex py-1 ${i%2 == 0  ? 'bg-white' : ''}`}>
                                            <div className='col'>
                                                <div className='f-14 text-dark bold ps-4'>{option.description}</div>
                                            </div>
                                    
                                            <div className='col d-flex justify-content-evenly '>
                                                
                                            <CheckBox text="" value="0" checked={assistanceUnitOptionChecked("0", option)}  onChange={(evt)=>{
                                               
                                                 let unit = {
                                                    idUser:user.id,
                                                    idAssistanceUnit:option.id,
                                                    yearsOfExperience:evt.key,

                                                }
                                             
                                                if(evt.value){
                                                    state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                    state.assistanceUnitExperience.push(unit);  
                                                }else{
                                                    state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                    
                                                }
                                             
                                                setState({...state})
                                       

                                            }}></CheckBox>
                                            <CheckBox text="" value="1" checked={assistanceUnitOptionChecked("1", option)}  onChange={(evt) => {
                                                 
                                                 let unit = {
                                                    idUser:user.id,
                                                    idAssistanceUnit:option.id,
                                                    yearsOfExperience:evt.key,

                                                }
                                                if(evt.value){
                                                    state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                    state.assistanceUnitExperience.push(unit);  
                                                }else{
                                                    state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                    
                                                }
                                             
                                                setState({...state})
                                            }} ></CheckBox>
                                            <CheckBox text="" value="2" checked={assistanceUnitOptionChecked("2", option)}  onChange={(evt) => {
                                                 
                                                 let unit = {
                                                    idUser:user.id,
                                                    idAssistanceUnit:option.id,
                                                    yearsOfExperience:evt.key,

                                                }
                                                if(evt.value){
                                                    state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                    state.assistanceUnitExperience.push(unit);  
                                                }else{
                                                    state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                    
                                                }
                                               
                                                setState({...state})
                                            }} ></CheckBox>

                                            <div className='d-none'>
                                                <NuComboBox 
                                                showkey={false} 
                                                key={i} 
                                                multiple={false} 
                                                checks={yearsExperienceOptions} 
                                                onChange={(evt)=>{

                                                

                                                    let unit = {
                                                        idUser:user.id,
                                                        idAssistanceUnit:option.id,
                                                        yearsOfExperience:evt[0].key.id.toString(),

                                                    }
                                                    if(evt[0].value){
                                                        state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                        state.assistanceUnitExperience.push(unit);  
                                                    }else{
                                                        state.assistanceUnitExperience = state.assistanceUnitExperience.filter((item) => item.idAssistanceUnit !== option.id)
                                                        
                                                    }
                                                    
                                               
                                                    setState({...state})
                                                        
                                                
                                                }}>
                                                </NuComboBox>
                                            </div>
                                            </div>

                                        </div>
                                    
                                
                                    
                                    )


                                })
                                
                                }
                            </div>

                            <div className='bg-gray-00 -gray r-10 p-4 p-md-5'>

                                <div className='d-flex'>
                                    <div className='col-12 col-md-6'><div className='f-22 bold'>En qué  ámbitos profesionales tienes experiencia</div></div>
                                   
                                </div>
                                <div className='f-16'></div>
                                <div className='my-4'></div>
                                <div className='col'>
                                    <CheckBox onChange={(evt)=>{
                                    
                                        if(evt.value){
                                            state.professionalFieldExperience = []
                                        }
                                        setState({...state})
                                    }} checked={state.professionalFieldExperience.length === 0 ? true: false} text="No tengo experiencia en ningún ámbito profesional"></CheckBox>
                                </div>
                                <div className='d-flex my-3'>
                                    <div className='col'></div>
                                    <div className='col d-flex justify-content-around '>
                                    <div className='col '>
                                        <div className="f-12 bold text-center line-1 ps-3 ">No tengo <br></br>experiencia</div>
                                    </div>
                                    <div className="f-12 bold text-center line-1">Menos <br></br>de 1 año</div>
                                    <div className='col '>
                                        <div className="f-12 bold text-center line-1 pe-2">Más de <br></br> 1 año</div>
                                    </div>
                                    </div>
                                </div>
                        
                                { state.professionalField.filter((item)=> item.id !== 7).map((option, i)=>{
                                      if(i === 0){
                                        return;
                                    }
                                    return ( 
                                    
                                        <div key={i} className={`d-flex py-1 ${i%2 == 0  ? 'bg-white' : ''}`}>
                                            <div className='col'>
                                                <div className='f-14 text-dark bold ps-4'>{option.description}</div>
                                            </div>
                                    
                                            <div className='col d-flex justify-content-evenly '>
                                            <CheckBox text="" value="0" checked={optionChecked("0", option)}  onChange={(evt)=>{
                                            
                                                 let unit = {
                                                    idUser:user.id,
                                                    idProfessionalField:option.id,
                                                    yearsOfExperience:evt.key,

                                                }
                                             
                                                if(evt.value){
                                                    state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                    state.professionalFieldExperience.push(unit);  
                                                }else{
                                                    state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                    
                                                }
                                       
                                                setState({...state})
                                       

                                            }}></CheckBox>
                                            <CheckBox text="" value="1" checked={optionChecked("1", option)}  onChange={(evt) => {
                                               
                                                 let unit = {
                                                    idUser:user.id,
                                                    idProfessionalField:option.id,
                                                    yearsOfExperience:evt.key,

                                                }
                                                if(evt.value){
                                                    state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                    state.professionalFieldExperience.push(unit);  
                                                }else{
                                                    state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                    
                                                }
                                           
                                                setState({...state})
                                            }} ></CheckBox>
                                            <CheckBox text="" value="2" checked={optionChecked("2", option)}  onChange={(evt) => {
                                               
                                                 let unit = {
                                                    idUser:user.id,
                                                    idProfessionalField:option.id,
                                                    yearsOfExperience:evt.key,

                                                }
                                                if(evt.value){
                                                    state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                    state.professionalFieldExperience.push(unit);  
                                                }else{
                                                    state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                    
                                                }
                                               
                                                setState({...state})
                                            }} ></CheckBox>

                                                <div className='d-none'>
                                                <NuComboBox 
                                                    showkey={false} 
                                                    key={i} 
                                                    multiple={false} 
                                                    checks={yearsExperienceOptions} 
                                                    onChange={(value)=>{
                                                    

                                                        let unit = {
                                                            idUser:user.id,
                                                            idProfessionalField:option.id,
                                                            yearsOfExperience:value[0].key.id.toString(),

                                                        }
                                                        if(value[0].value){
                                                            state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                            state.professionalFieldExperience.push(unit);  
                                                        }else{
                                                            state.professionalFieldExperience = state.professionalFieldExperience.filter((item) => item.idProfessionalField !== option.id)
                                                        }
                                                        
                                                      
                                                            
                                                    
                                                    }}>
                                                </NuComboBox>
                                                </div>
                                            </div>

                                        </div>
                                    
                                
                                    
                                    )


                                })
                                
                                }
                            </div>
      
                    </Renderif>

                    <Renderif isTrue={state.form_step === 5}>

                            <div className='bg-gray-00 -gray r-10 p-4 p-md-5'>
                                <div className='d-flex'>
                                    <div className='col'><div className='f-22 bold'>¿Tienes formación tipo máster o posgrado relacionados con las siguientes Unidades Asistenciales?</div></div>
                            
                                </div>
                                <div className='f-16'></div>
                                <div className='my-4'></div>
                                <CheckBox checked={state.educationAssistanceUnitList.length === 0} onChange={(evt)=>{
                                    if(evt.value){
                                        state.educationAssistanceUnitList = []
                                        setState({...state});
                                    }
                                }} text="No tengo formación tipo máster o posgrado" />
                                <div className='d-flex flex-wrap'>


                                {
                                        getEducations(state.assistanceUnit).map((item,i)=>{
                                            let _check = state.educationAssistanceUnitList.some((center)=>center.idAssistanceUnit === item.id);
                                            return (
                                                <div className='col-6'>
                                                      <CheckBox checked={_check} text={item.description} value={item.id} onChange={(evt)=> {

                                                            if(evt.value){
                                                                let _item = {
                                                                    idUser:user.id,
                                                                    idAssistanceUnit:item.id
                                                                }
                                                                if(item.id === 1){
                                                                    state.educationAssistanceUnitList =  []
                                                                }
                                                                if(item.id !== 1){
                                                                    state.educationAssistanceUnitList =  state.educationAssistanceUnitList.filter((center) => center.idAssistanceUnit !== 1) 
                                                                }
                                                                state.educationAssistanceUnitList.push(_item)
                                                                setState({...state})
                                                                return
                                                            }
                                                            state.educationAssistanceUnitList = state.educationAssistanceUnitList.filter((center) => center.idAssistanceUnit !== item.id)
                                                            setState({...state})

                                                        }} />
                                                </div>
                                            )
                                        })
                                    }
                                  
                                 
                                    {/* <NuComboBox 
                                        first={false} 
                                        className="col-6"  
                                        multiple={true} 
                                        checks={getEducations(state.assistanceUnit)} 
                                        labelKey='description'
                                        onChange={(value)=>{
                                            
                               
                                            state.educationAssistanceUnitList = []

                                            value.forEach((item)=>{
                                        
                                                let _item = {
                                                    idUser:user.id,
                                                    idAssistanceUnit:item.key.id
                                                }
                                                state.educationAssistanceUnitList.push(_item)
                                            })
                                            setState({...state})
                                          
                                        }}>
                                    </NuComboBox> */}
                                </div>
                            </div>

                            <div className='bg-gray-00 -gray r-10 p-4 p-md-5'>
                                <div className='d-flex'>
                                    <div className='col'><div className='f-22 bold'>¿Tienes formación tipo máster o posgrado relacionados con los siguientes ámbitos profesionales?</div></div>
                                </div>
                              
                                <div className='f-16'></div>
                                <div className='my-4'></div>
                                <CheckBox checked={state.educationProfessionalFieldList.length === 0} onChange={(evt)=>{
                                    if(evt.value){
                                        state.educationProfessionalFieldList = [];
                                        setState({...state})
                                      
                                    }
                                }} text="No tengo formación tipo máster o posgrado"></CheckBox>
                                <div className='d-flex flex-wrap'>

                                    {
                                        getEducations(state.professionalField.filter((item)=>item.id !== 7)).map((item,i)=>{
                                            let _check = state.educationProfessionalFieldList.some((center)=>center.idProfessionalField === item.id);
                                            return (
                                                <div className='col-6'>
                                                      <CheckBox checked={_check} text={item.description} value={item.id} onChange={(evt)=> {

                                                            if(evt.value){
                                                                let _item = {
                                                                    idUser:user.id,
                                                                    idProfessionalField:item.id
                                                                }
                                                                if(item.id === 1){
                                                                    state.educationProfessionalFieldList =  []
                                                                }
                                                                if(item.id !== 1){
                                                                    state.educationProfessionalFieldList =  state.educationProfessionalFieldList.filter((center) => center.idProfessionalField !== 1) 
                                                                }
                                                                state.educationProfessionalFieldList.push(_item)
                                                                setState({...state})
                                                              
                                                                return
                                                            }
                                                            state.educationProfessionalFieldList = state.educationProfessionalFieldList.filter((center) => center.idProfessionalField !== item.id)
                                                            setState({...state})
                                                       

                                                        }} />
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                       
                    </Renderif>

                    <div className='mt-3 mb-5'>
                        <div className='d-flex'>
                            <div className='nu_btn nu_btn--overlay' onClick={()=>{
                                   document.getElementById('view').scroll(0,0)
                                    if(state.form_step > 1){
                                        setState({...state, form_step:state.form_step - 1})
                                    }

                                }}>
                                <div className='nu_btn-text f-18 px-4'>Atrás</div>
                                <div className='nu_btn-overlay'></div>
                            </div>
                            <div className='col'></div>
                            <div className='nu_btn nu_btn--overlay' onClick={()=>{

                                    document.getElementById('view').scroll(0,0)
                                        if(!validator()){
                                            return;
                                        }
                                   

                                    
                                    if(state.form_step === 5){
                                        // navigate('/profesional')
                                        onUpdateNurseData();
                                        
                                    }
                                    if(state.form_step <= 4){
                                        setState({...state, form_step:state.form_step + 1})
                                    }
                                }}>
                                <div className='nu_btn-text f-18 px-1'>Siguiente</div>
                                <div className='nu_btn-overlay'></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col'></div>
                

                {/* <div className='d-flex justify-content-evenly my-3 mx-2'>
                    <div className='f-12 bold text-decoration-underline cursor-pointer'>Terminos y condiciones</div>
                    <div className='f-12 bold text-decoration-underline cursor-pointer'>Aviso de privacidad</div>
                </div> */}

            </div>
        </div>
    </div>
    </>
  )
}

