import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Login, Service } from '../../services/api';
import { AuthContext } from '../../context/auth_context';
import NuLoading from '../../components/nu_loading';
import Renderif from '../../utils/renderif';
import RememberPassword from './remember_password_view';
import RecuperacionPassword from './recuperacion_password_view';

export default function LoginView() {

    const navigate = useNavigate();

    const location = useLocation()

    var status = location.state?.status


    const {setUser, user, user_data, login, init, sebas, expired} = useContext(AuthContext)
    
    

    const intro_layout = [
        'col-12 col-md-7 col-lg-8 col-xl-8',
        'col-12 col-md-12 col-lg-12 col-xl-12',
       
    ]
    const [state, setState] = useState({
        intro:true,
        email:'',
        password:'',

        // email:'clinica@nursea.es',
        // password:'Password1!',
        showRemember:false,

        loading:false
    })

    useEffect(()=>{

        const init = async () => {

            

            if(user_data !== null && user_data !== undefined){

                console.log(user_data?.idUserType)


               
                switch (user_data?.idUserType) {
                    case 1:
                        navigate('/profesional')
                        break;
                        case 2:
                            navigate('/profesional')
                            break;
                            case 3:
                                navigate('/profesional')
                                break;
                                case 4:
                                    navigate('/clinica')
                                    break;
                                    case 5:
                                        navigate('/admin')
                                        break;
                
                    default:
                        break;
                }

            }

          
            setTimeout(()=>{
                setState({...state, intro:false})
            },2000)
        };
        init()

    return ()=> {}
        
    },[user, user_data])


    useEffect(()=>{
         const _init = async () =>{
            console.log(user)
            console.log(user_data)
            console.log(expired)
            


            if(expired){
                console.log('RENOVAR')
                let refreshToken = localStorage.getItem('refreshToken');
                let body = {
                    idAWS:user?.idAWS,
                    refreshToken
                }
                let _refresh = await Service('POST', `authentication/refresh-token`,body)
                console.log(_refresh)
                if(_refresh.status === 200){
                    localStorage.setItem('token',_refresh.data.token)
                    status = undefined
                    init()
                }

                
            }
         } 
         _init();
         return () => {}
           
    },[expired])
    
  return (
    <>
    <Renderif isTrue={state.showRemember}>
        <RememberPassword 
        onClose={()=>{
            setState({...state, showRemember:false})
        }}
        onAccept={(email)=>{
            setState({...state, showRemember:false, email, showRecovery:true})

        }}></RememberPassword>
    </Renderif>

    <Renderif isTrue={state.showRecovery}>
        <RecuperacionPassword 
        email={state.email}
        onClose={()=>{
            setState({...state, showRecovery:false})
        }}
        ></RecuperacionPassword>
    </Renderif>
   
   
    <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
    </Renderif>
    <div className='container-fluid h-100'>

        <div className='d-flex h-100'>

            <div className={`${!state.intro ? intro_layout[0] : intro_layout[1]} d-none d-md-block transition position-relative`}>

                <div className={`nu-intro ${!state.intro ? 'bg-transparent': ''}`}>
                    <img className={`nu-intro-logo ${!state.intro ? 'opacity-0': ''}`} width={300}  src='/assets/logo_white.png'></img>
                    <div className='nu-intro-overlay'></div>
                </div>

              

                <div className='login_bg'>
                    {/* <div className='login_bg_overlay'></div> */}
                </div>
            
            </div>
            
            <div className='col-12 col-md-5 col-lg-4 col-xl-4 d-flex flex-column'>

                <div className='col d-flex justify-content-center align-items-center'>
                    <img className='mt-5 pt-5'  src='/assets/logo.png'></img>
                </div>

   

           
                <div className='col d-flex flex-column px-5 mx-lg-5'>
       
                    <div className='f-28 bold my-3'>Bienvenido(a)</div>
                    <input type='text' value={state.email} className='nu_input mt-2' placeholder='Correo electrónico' onChange={(evt)=>{
                        setState({...state, email:evt.currentTarget.value})
                    }}></input>
                    <input type='password' value={state.password} className='nu_input mt-3' placeholder='Contraseña' onChange={(evt)=>{
                        setState({...state, password:evt.currentTarget.value})
                    }}></input>



                    <div className='mt-3 mx-auto'>
                        <div className='nu_btn nu_btn--overlay' onClick={async ()=>{

                            
                          
                            setState({...state, loading:true})
                            await login({email:state.email, password:state.password})
                            setState({...state, loading:false})
                          
                            
                        }}>
                            <div className='nu_btn-text f-18'>Iniciar sesión</div>
                            <div className='nu_btn-overlay'></div>
                        </div>
                    </div>

                    <div className='mt-5 mx-auto'>
                        <div className='f-14 text-center text-lightpurple'>¿Aún no estás registrado?</div>
                        <div className='f-14 text-center text-lightpurple'> Regístrate desde 
                            <span 
                                className='bolder text-decoration-underline cursor-pointer' 
                                onClick={() => {
                                window.open('https://nursea.es/', '_blank');
                                }}> nursea.es
                            </span>
                        </div>
                        <div className='f-14 text-center text-lightpurple'>¿Te recordamos tu <span className='bolder text-decoration-underline cursor-pointer' onClick={()=>{
                            // navigate('/register')
                            setState({...state, showRemember:true})
                        }}>contraseña</span>?</div>
                    </div>
                </div>


 
                <div className='col'></div>

                <div className='d-flex justify-content-evenly my-3 mx-2'>
                    <div className='f-12 bold text-decoration-underline cursor-pointer' onClick={()=>{
                        window.open('https://nursea.es/terminos-y-condiciones','_blank')
                    }}>Política de Privacidad</div>
                    {/* <div className='f-12 bold text-decoration-underline cursor-pointer'>Aviso de privacidad</div> */}
                </div>

                
                
            </div>

        </div>

    </div>
    </>
  )
}
