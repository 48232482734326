import React, { useContext, useEffect, useState } from 'react'
import { createContext } from "react";
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Login, Service } from '../services/api';
import Renderif from '../utils/renderif';
import { NotificationContext } from './notification_context';
// import { AlertContext } from './alert_context';
export const AuthContext = createContext();

export default function AuthContextProvider({children}) {

  const navigate = useNavigate();
  const {notify} = useContext(NotificationContext);
  const location = useLocation()

  

    const [state, setState] = useState({
        user:null,
        user_data:null,
        loading:true,
        expired:false
    })

    useEffect(()=>{

     
      init()

      return () => {};
      
    },[])
    const sebas = () => {
      console.log("SEBAS")
    }
    const init = async () => {
      console.log("INIT")
      let token = localStorage.getItem('token');
      let refreshToken = localStorage.getItem('refreshToken');
      let user = localStorage.getItem('user');



      if(user !== null && token !== null){
        console.log("USER Y TOKEN")

        

        state.user = JSON.parse(user)
        // let _userpicture = await Service('GET', `document/view/${state.user.id}/10}`);
        // // if(_userpicture.status ===)
        // console.log(_userpicture)

      
        if(state.user.idUserType < 4 ){
          
            try {
              let _userdata = await Service('GET', `user/getById/${state.user.id}`);
              console.log(state.user)
              
             
              if(_userdata.status === 401){
                logout();
                return;
              }
              if(_userdata?.status === 463){
                state.expired = true;
                setState({...state})
                navigate('/')
                return;
              }

              state.user_data = _userdata?.data?.user;
              state.loading = false
              state.expired = false;
              setState({...state})

            } catch (error) {
                console.log(error)
            }
        }

        if(state.user.idUserType === 4 ){
          try {
            let _userdata = await Service('GET', `organizationData/getByIdUser/${ state.user.id}`);    
            console.log(_userdata) 
            
            if(_userdata.status === 401){
              logout();
              return;
            }
            if(_userdata?.status === 463){
              state.expired = true;
              setState({...state})
              navigate('/')
              return;
            }

            state.user_data = _userdata?.data?.organizationData;
            state.loading = false
            state.expired = false;
            setState({...state})
          
          } catch (error) {
            console.log(error)
          }
        }


        //setState({...state, loading:false})

      
      }
    } 

    const setUser = ({user, user_data}) => {

        state.user = user;

       if(user_data !== undefined){
        state.user_data = user_data
       }

        setState({...state, loading:false})
    }

    const setUserData =  async () => {
     
      if(state.user?.id !== undefined){

      
          let _userdata = await Service('GET', `user/getById/${state.user.id}`);
          state.user_data = _userdata.data.user;
          setState({...state})
      }
       
    }

    const updateUserPictue = async (file) => {
        let body = new FormData();
        body.append("", file);
        return await Service('POSTMEDIA', `document/upload/10`, body )
    }



    const setUserPicture = async () => {
      let _picture = await Service('GET', `document/view/${state.user.id}/10`);
      if(_picture.status !== 201){
          state.user.picture = '../../assets/avatar.png'
      }else{
        // state.user.picture = '../../assets/nursea_icon.png'
          state.user.picture = _picture.data.url
      }
      setState({...state})
    }

    const login = async ({email, password}) => {
      let user = await Login({email, password})
  

      if(user.status == 200){
        localStorage.setItem('user',JSON.stringify(user.data.user))
        localStorage.setItem('token',user.data.token)
        localStorage.setItem('refreshToken',user.data.refreshToken)

        state.user = user.data.user;

        let _picture = await Service('GET', `document/view/${state.user.id}/10`);
   
        if(_picture.status !== 201){
            state.user.picture = '../../assets/avatar.png'
        }else{
            state.user.picture = _picture.data.url
            // state.user.picture = '../../assets/nursea_icon.png'
        }

        
        
        // let _userdata = await Service('GET', `user/getById/${user.data.user.id}`);
   
        // state.user_data = _userdata.data.user;

        if(user.data.user.idUserType < 4 ){
          let _userdata = await Service('GET', `user/getById/${user.data.user.id}`);
         
          console.log(_userdata)
        
          state.user_data = _userdata.data.user;
          if(_userdata.data.user.idUserType === 1){
            if(_userdata.data.user?.nurseData === undefined){
              navigate('/register/enfermeria')
              return;
            }
              

          }
          if(_userdata.data.user.idUserType === 3){
            if(_userdata.data.user?.tcaeData === undefined){
              navigate('/register/tcae')
              return;
            }
              
          }

        }

        if(user.data.user.idUserType === 4 ){
          let _userdata = await Service('GET', `organizationData/getByIdUser/${user.data.user.id}`);
          console.log(_userdata)
          state.user_data = _userdata.data.organizationData;
        }



        notify({title:'¡Bienvenido!', message:user.data.user.name, type:'success'})
        switch (user.data.user.idUserType) {
          case 1:
            navigate('/profesional')
            // navigate('/register/enfermeria')
            break;
          case 2:
            navigate('/profesional')
            break;
          case 3:
            navigate('/profesional')
            break;
          case 4:
            navigate('/clinic')
              // navigate('/register/clinica/center')
              break
          case 5:
              navigate('/admin')
              break
          default:
              break;
        }
        
    
      }else{
       
        notify({title:'Error', message:user?.response?.data?.error, type:'error'})
        localStorage.removeItem('token');
  
      }
    }

 



    const logout = () => {
      state.user = null
      state.user_data = null
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('token');
      localStorage.removeItem('user');

    }

    const getUserDateNurse = async () => {
     
        let _userdata = await Service('GET', `user/getById/${state.user?.id}`);
        console.log(_userdata)
        

        state.user_data = _userdata.data.user;
        state.user.name = state.user_data?.name
        setState({...state});

    }

  return (
    <AuthContext.Provider value={
      {
        ...state,
        sebas,
        init,
        logout,
        login,
        setUser,
        setUserData,
        getUserDateNurse,
        setUserPicture,
        updateUserPictue
        
        // changeUser
      }
    }>
      
       
          {children}
 

       

    </AuthContext.Provider>
  )
}
