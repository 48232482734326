export const weekday=new Array(7);
weekday[0]="Domingo";
weekday[1]="Lunes";
weekday[2]="Tuesday";
weekday[3]="Miercoles";
weekday[4]="Jueves";
weekday[5]="Viernes";
weekday[6]="Sabado";

export const months=new Array(7);
months[0]="Enero";
months[1]="Febrero";
months[2]="Marzo";
months[3]="Abril";
months[4]="Mayo";
months[5]="Junio";
months[6]="Julio";
months[7]="Agosto";
months[8]="Septiembre";
months[9]="Octubre";
months[10]="Noviembre";
months[11]="Diciembre";

export const DdMY = (date) => {

    return ( weekday[new Date(date).getDay()] +" "+new Date(date).getDate()+" "+ months[new Date(date).getMonth()] + " " + new Date(date).getFullYear());

}
export const dMY = (date) => {

    return (new Date(date).getDate()+" "+ months[new Date(date).getMonth()] + " " + new Date(date).getFullYear());

}
export const dM = (date) => {

    return (new Date(date).getDate()+" "+ months[new Date(date).getMonth()]);

}

export const timeOfDay = (id) => {

    switch (id) {
        case '0':
            return 'Mañana';
          
        case '2':
            return 'Tarde';
        case '3':
            return 'Noche';
        case '1':
            return 'Día 12h.';
        case '4':
            return 'Guardia 24h';
    
        default:
            return '';
    }
}
export const formacion = (id) => {
    if(id === null){
        return 'No se requiere';
    }
    switch (id) {
        case '0':
            return 'Se requiere';
        case '1':
            return 'No se requiere';
        default:
            return '';
    }
}
export const expUnidadAsistencial = (id) => {
    switch (id) {
        case '0':
            return 'Postgrado o Máster relacionado con UCI.';
        case '1':
            return 'Postgrado o Máster relacionado en Cardiología.';
        default:
            return '';
    }
}
export const experiencia = (id) => {
    switch (id) {
        case '0':
            return 'Sin experiencia.';
        case '1':
         return '1 - 12 meses';
        case '2':
            return '> 1 año';
        default:
            return '';
    }
}
export const profesionalExperiencia = (id) => {
    switch (id) {
        case '0':
            return 'Sin experiencia.';
        case '1':
            return '1 - 5 años';
        case '2':
            return '6 - 10 años';
        case '3':
            return '> 10 años';
        default:
            return '';
    }
}

export const needsReception = (id) => {

    switch (id) {
        case '0':
            return 'No necesita una  acogida previa.';
        case '1':
            return 'Necesita turno de acogida en el centro';
        case '2':
            return 'Necesita turno de acogida en centro y la unidad asistencial solicitada';
        default:
            return '';
    }
}
export const status = (id) => {
    
    switch (id) {
        case '0':
            return 'Sin asignar';
        case '1':
            return 'Asignado';
        case '2':
            return 'Completado';
        default:
            return '';
    }
}

export const alphaOrder = (data, key) => {

    return data.sort(function (a, b) {
        if (a[key] < b[key]) {
          return -1;
        }
        if (a[key] > b[key]) {
          return 1;
        }
        return 0;
      });
}
export const UTCDateParser = (date, es = false) => {
    let _day_es = {
        "Mon,":"Lunes",
        "Tue,":"Martes",
        "Wed,":"Miércoles",
        "Thu,":"Jueves",
        "Fri,":"Viernes",
        "Sat,":"Sábado",
        "Sun,":"Domingo"
    }
    let _month_es = {
        "Jan": "Ene.",
        "Feb": "Feb.",
        "Mar": "Mar.",
        "Apr": "Abr.",
        "May": "May.",
        "Jun": "Jun.",
        "Jul": "Jul.",
        "Aug": "Ago.",
        "Sep": "Sep.",
        "Oct": "Oct.",
        "Nov": "Nov.",
        "Dec": "Dic."
    }
    let _date = date.split(' ');
    if(!es){
        return _date[0] + ' ' +_date[1] + ' ' +  _date[2] + ' ' + _date[3]
    }
    else{
        
        return _day_es[_date[0]] + ' ' +_date[1] + ' ' + _month_es[_date[2]] + ' ' + _date[3]
    }
}

export const UTCDateObj = (date) => {
    let _date = date.split(' ');

 

    let months = {
        'Jan':0,
        'Feb':1,
        'Mar':2,
        'Apr':3,
        'May':4,
        'Jun':5,
        'Jul':6,
        'Aug':7,
        'Sep':8,
        'Oct':9,
        'Nov':10,
        'Dec':11,
    }



    return {
        day:_date[0],
        date:parseInt(_date[1]),
        month:months[_date[2]],
        year:parseInt(_date[3]),
    }
}

export const documentsNurse = [
    {
        type:0,
        label:'DNI anverso'
    },
    {
        type:1,
        label:'DNI reverso'
    },

    {
        type:6,
        label:'Número de cotización a la seguridad social '
    },
    {
        type:7,
        label:'Número de cuenta bancaria '
    },
    {
        type:8,
        label:'Certificado de Delitos de Naturaleza Sexual'
    },
    {
        type:4,
        label:'Certificado de Antecedentes Penales'
    },
    {
        type:9,
        label:'Curriculum vitae'
    },
    {
        type:2,
        label:'Título oficial'
    },
    // {
    //     type:3,
    //     label:'Título master/postgrado (Opcional)'
    // },
]

export const documentsTCAE = [
    {
        type:0,
        label:'DNI anverso'
    },
    {
        type:1,
        label:'DNI reverso'
    },
    {
        type:6,
        label:'Número de cotización a la seguridad social '
    },
    {
        type:7,
        label:'Número de cuenta bancaria '
    },
    {
        type:8,
        label:'Certificado de Delitos de Naturaleza Sexual'
    },
    {
        type:4,
        label:'Certificado de Antecedentes Penales'
    },
    {
        type:9,
        label:'Curriculum vitae'
    },
    {
        type:2,
        label:'Título oficial'
    },
]

export const restrictionReasons = [
    {
        type:0,
        label:'No se presenta al turno sin preaviso'
    },
    {
        type:1,
        label:'Ya ha cancelado varias veces un turno'
    },
    {
        type:2,
        label:'No cumple los estánderes exigidos para el desempeño de la actividad para la cuál ha sido asignada'
    },
    {
        type:3,
        label:'No se corresponde el currículum, con la experiencia y formación acreditada en la realidad'
    },
    {
        type:4,
        label:'No aporta la documentación requerida'
    },
    {
        type:5,
        label:'Es personal interno'
    },
    {
        type:6,
        label:'No es posible adaptar el lugar de trabajo a sus necesidades psíquicas, físicas, sensoriales u otras'
    },
]
