import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { FaStar } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { ClinicSideMenu, ProfesionalSideMenu } from '../../components/nu_sidemenu';
import { AuthContext } from '../../context/auth_context';
import { Bs0CircleFill, BsCheck, BsCheckCircle, BsCheckCircleFill, BsInfo, BsInfoCircleFill, BsThreeDotsVertical, BsUpload } from 'react-icons/bs';
import { useFilePicker } from 'use-file-picker';
import {
    FileSizeValidator,
    FileTypeValidator
  } from 'use-file-picker/validators';
import { Service } from '../../services/api';
import NuLoading from '../../components/nu_loading';
import Renderif from '../../utils/renderif';
import { NotificationContext } from '../../context/notification_context';
import { GlobalContext } from '../../context/global_context';
import { FiInfo } from 'react-icons/fi';
import { documentsNurse, documentsTCAE } from '../../utils';
export default function DocumentacionProfesionalView() {

    const {user} = useContext(AuthContext);
    const {documents, upLoadDocument, loadDocuments, getRating} = useContext(GlobalContext);
  

    const { openFilePicker, filesContent, loading, plainFiles, errors } = useFilePicker({
        accept: ['.png', '.pdf', '.jpg', 'jpeg'],
        // accept: ['.png'],
        // accept: "image/*",
       
       validators:[
        // new FileTypeValidator(['jpg', 'png']),
        new FileSizeValidator({ maxFileSize: 10 * 1024 * 1024 /* 50 MB */ })
       ]
    });
    

      const {notify} = useContext(NotificationContext);


    const [state, setState] = useState({
        show_menu:false,
        loading:false,
        active_file_type:null,
        documents: []
    })

    
    let _documents = []

   

    if(user?.idUserType == 1){
        _documents = documentsNurse;
    }else{
        _documents = documentsTCAE;
    }

  
    useEffect(()=>{
        const init = async () => {
            if(user !== null && user !== undefined){
                let _docs = await loadDocuments()
            }
            
            // _docs.map((item)=>{
                
            // })

            // for (let i = 0; i < documents.length; i++) {
            //     let _doc = await Service('GET', `document/view/${user.id}/${documents[i].type}`);
            //     if(_doc.status === 201){

            //         let document = {
            //             type:documents[i].type,
            //             file:{path:_doc.data.url},
            //             label:''
            //         }

            //         state.documents.push(document)
            //         setState({...state})
            //     }
                
            // }

        };

        init();
        return () => {}
    },[user])

 


      
    useEffect( ()=>{


        const init = async () => {
            console.log(errors)
            if(errors.length > 0){
                notify({title:'¡Error!', message:'Debes subir un archivo no mayor a 10MB', type:'error'})
            }else{

                if(filesContent.length > 0 && state.active_file_type !== null){     
            
                    setState({...state, loading:true})
                    await upLoadDocument(plainFiles[0], state.active_file_type)
                    await loadDocuments();
                    state.active_file_type = null
                    setState({...state, loading:false})
                    
                }
            }

        }
        init();
        

        return () => {}

     
    },[filesContent, errors])





    // if (errors.length) {
    //     console.log(errors)
    //     return <div>Error...</div>;
    //   }


   
    const getDocument = (type) => {

        let _doc = documents.filter((item)=>item.type === type);

        if(_doc.length === 0){
            return  {
                file: {path:"Seleccionar documento"}
            }
        }

        return _doc[0];
    }

    const validationProgress = (documents) => {
        return (
            <div className='d-flex flex-column justify-content-center bg-dark r-10 py-3 h-100'>
                                
                <Renderif isTrue={documents.length >= 8}>
                    <div className='f-16 text-white text-center bold mb-2'>Completada</div>
                </Renderif>
                <Renderif isTrue={documents.length < 8}>
                    <div className='f-16 text-white text-center bold mb-2'>Pendiente</div>
                </Renderif>

                <div className='position-relative d-flex justify-content-center align-items-center py-4'>
                    <img className='position-absolute' src='/assets/circle.png'></img>
                    <div className='text-white text-center bold'>{documents.length}/8</div>
                </div>

                <Renderif isTrue={documents.length >= 8}>
                    <div className='f-14 text-white text-center mt-2'>¡Todo listo!</div>
                </Renderif>

            </div>
        )
    }

    
   

  return (
    <>
        <Renderif isTrue={state.loading}>
            <NuLoading></NuLoading>
        </Renderif>
        <div className='container-fluid overflow-hidden d-flex h-100'>


            <ProfesionalSideMenu onClose={()=>{setState({...state, show_menu:false})}} show={state.show_menu} page='documentacion' />
    


            <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

                <div className='bg-white sticky-top pt-1 pt-md-3 px-1 px-md-3'>
        

                    <div className='nu-snack'>
                    <div>
                            <div className='f-22 text-white bold'>Hola, {user?.name}</div>
                            <div className='d-flex align-items-center'>
                                <div className='nu-icon nu-icon--mid'>
                                    <IoMdStar color='#FFB054' size={24}></IoMdStar>
                                </div>

                                <div className='f-16 text-white'>{getRating().rate} ( {getRating().valoraciones} reseñas)</div>

                            </div>
                        
                    </div>
                    <div className='col'></div>
                        <div className='avatar avatar--mid' style={{backgroundImage: `url(${user?.picture})`}}></div>
                        <div className='nu-snack-overlay'></div>
                        <div className='ms-2 d-block d-sm-none'>
                        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                            setState({...state, show_menu:true})
                        }}>
                            <BsThreeDotsVertical size={50} color='white'></BsThreeDotsVertical>
                        </div>
                    </div>
                    </div>
                        
                </div>

                <div className='container-fluid container-xl mx-auto mt-1 mt-md-2 p-1 p-md-3'>
                    <div className='d-flex flex-wrap '>
                        
                        <div className='col-12 col-md-8 col-xl-10 pe-md-3'>
                            
                            <div className='d-flex flex-column justify-content-center bg-darkblue r-10 p-3 h-100'>
                                <div className='f-22 text-white bolder'>Subir documentación</div>
                                <div className='f-16 text-white my-3'>Para poder validar tu perfil, asignarte turnos y que podamos tramitar tu contrato con el Centro Sanitario, necesitaremos los siguientes documentos. Puedes subir imágenes en formato PNG, JPG o PDFs con un tamaño máximo de 10 MB.</div>
                            </div>

                        </div>

                        <div className='col-12 col-md-4 col-xl-2 mt-2 mt-md-0'>

                            {validationProgress(documents)}

                        </div>
                    

                    


                    </div>
                </div>

                <div className='container-fluid container-xl mx-auto px-1 px-md-3 '>
                    <div className='f-22 bold ps-1'>Mis documentos</div>

                    <div className='d-flex flex-wrap'>

                        {
                        _documents.map((item, i)=>{
                        
                                return (

                                    <div key={i} className='col-12 col-md-6 px-md-2 py-2 mt-0 mt-md-2'>
                                        <div className='bg-light r-10 p-3'>
                                            
                                            <div className='d-flex align-items-center pb-2 ps-1'>
                                                <div className='d-flex align-items-center'>
                                                 <div className='f-16 bold'>{item.label}</div>
                                                  
                                                 <Renderif isTrue={getDocument(item.type).file?.path !== 'Seleccionar documento'}>
                                                    <div className='f-12 bold text-decoration-underline text-success cursor-pointer mt-1 ps-2' onClick={()=>{
                                                        window.open(`${getDocument(item.type).file?.path}`,'_blank')
                                                    }}>Ver archivo</div>
                                                 </Renderif>

                                                </div>
                                                <div className='col'></div>
                                                <Renderif isTrue={getDocument(item.type).file?.path !== 'Seleccionar documento'}>
                                                    <div className='nu-icon nu-icon--mid'>
                                                        <BsCheckCircle color='green' size={30}></BsCheckCircle>
                                                    </div>
                                                </Renderif>
                                                <Renderif isTrue={getDocument(item.type).file?.path === 'Seleccionar documento'}>
                                                    <div className='nu-icon nu-icon--mid'>
                                                        <FiInfo color='red' size={30}></FiInfo>
                                                    </div>
                                                </Renderif>
                                            </div>
                
                                            <div className='d-flex  align-items-center'>
                
                                                <div className='col pe-2'>
                                                    <input disabled placeholder={getDocument(item.type).file?.path} className='nu-input--light nu-input--disabled'></input>
                                                    
                                                </div>
                
                                                <div className='nu_btn nu_btn--primary py-2' onClick={()=>{ 
                                                    state.active_file_type = item.type;
                                                    openFilePicker()
                                                    }}>
                                                    <div className='nu-icon nu-icon--mid me-2'>
                                                        <BsUpload size={18}></BsUpload>
                                                    </div>
                                                    <div className='nu_btn-text'>Subir archivo</div>
                                                </div>
                
                                            </div>
                                        </div>
                                    </div>

                                )
                            })
                        }

                    

                    

                    </div>
                </div>


                <div className='my-5 py-5'></div>



        
        
            </div>

    
    
    
        
        </div>
    </>
  )
}
