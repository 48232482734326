import React, { useEffect, useState } from 'react'
import {BsCheckSquareFill,BsCheckSquare} from 'react-icons/bs';
import Renderif from '../utils/renderif';


export default function CheckBox({showkey = true, onChange = () =>{}, ...props}) {

    const [state, setState] = useState({
        active:props.checked !== undefined ? props.checked : false
    })
    const onClick = () => {
        if(!props.disabled){
            setState({...state, active:!state.active})
        }
        onChange({
            key:props.value,
            value:!state.active
        })
      
    }

    useEffect(()=>{
        if(!props.disabled){
            setState({...state, active:props.checked !== undefined ? props.checked : false})
        }
    },[props.checked])

  return (
        <div className={props.className}>
            <div className='d-flex align-items-center cursor-pointer fit-content' onClick={()=>{onClick()}}>
                <div className='nu-icon my-1 '>
                    <Renderif isTrue={state.active}>
                        <BsCheckSquareFill color='#0972CE' />
                    </Renderif>

                    <Renderif isTrue={!state.active}>
                        <BsCheckSquare color='#CCC' />
                    </Renderif>
                </div>
                <div className={`f-14 text-dark bold ms-2 ${showkey === false ? 'd-none':''}`}>{props.text}</div>
            </div>
        </div>
  )
}
