import React, { useContext, useEffect, useState } from 'react'
import { createContext } from "react";
import { useNavigate } from 'react-router-dom';
import NuLoading from '../components/nu_loading';
import { Service } from '../services/api';
import Renderif from '../utils/renderif';
import { AuthContext } from './auth_context';
import { NotificationContext } from './notification_context';

export const AdminContext = createContext();

export default function AdminContextProvider({children}) {

  const navigate = useNavigate();
  const {notify} = useContext(NotificationContext);
  const {user} = useContext(AuthContext)

    const [state, setState] = useState({
        loading:false,
        center:[],
    
        assistanceUnit:[],
        specialization:[],
        professionalField:[],
        centerType:[],
        documents:[],
        professionals:[],
        professionalsCount:0,
        organizations:[],
        organizationsCount:0
        
       
        
    })

    let documents = []
    const documentsNurse = [
        {
            type:0,
            label:'DNI anverso'
        },
        {
            type:1,
            label:'DNI reverso'
        },
        {
            type:2,
            label:'Copia Título oficial'
        },
        {
            type:3,
            label:'Copia Título master/postgrado'
        },
        // {
        //     type:4,
        //     label:'Certificado 2º enfermaría con todos los módulos aprobados'
        // },
        // {
        //     type:5,
        //     label:'Número de colegiado/a'
        // },
        {
            type:6,
            label:'Número de cotización a la seguridad social '
        },
        {
            type:7,
            label:'Número de cuenta bancaria '
        },
        {
            type:8,
            label:'Certificado de penales y naturaleza sexual '
        },
        {
            type:9,
            label:'Curriculum vitae'
        },
    ]
    const documentsTCAE = [
        {
            type:0,
            label:'DNI anverso'
        },
        {
            type:1,
            label:'DNI reverso'
        },
        // {
        //     type:2,
        //     label:'Copia Título oficial'
        // },
        // {
        //     type:3,
        //     label:'Copia Título master/postgrado'
        // },
        {
            type:4,
            label:'Certificado 2º enfermaría con todos los módulos aprobados'
        },
        {
            type:5,
            label:'Número de colegiado/a'
        },
        {
            type:6,
            label:'Número de cotización a la seguridad social '
        },
        {
            type:7,
            label:'Número de cuenta bancaria '
        },
        {
            type:8,
            label:'Certificado de penales y naturaleza sexual '
        },
        {
            type:9,
            label:'Curriculum vitae'
        },
    ]



    useEffect(()=>{

      
        const init = async() => {
      
            setState({...state, loading:true})
            // loadProfesionals({page:1});
            // state.documents = []
            
            let _center = await Service('GET', 'center/getAll');
            let _centerType = await Service('GET', 'centerType/getAll');
            let _assistanceUnit = await Service('GET', `assistanceUnit/getAll`);
            let _specialization = await Service('GET', 'specialization/getAll')
            let _professionalField = await Service('GET', `professionalField/getAll`);

            // if(user?.idUserType < 4){

            //     if(user?.idUserType === 1){
            //         documents = documentsNurse;
            //     }else{
            //         documents = documentsTCAE;
            //     }
  
            //     for (let i = 0; i < documents.length; i++) {
            //         let _doc = await Service('GET', `document/view/${user.id}/${documents[i].type}`);
            //         if(_doc.status === 201){
    
            //             let document = {
            //                 type:documents[i].type,
            //                 file:{path:_doc.data.url},
            //                 label:''
            //             }

            //             if(!state.documents.some((item)=>item.type === documents[i].type)){
            //                 state.documents.push(document)
            //             }
    
                        
                       
            //         }
                    
            //     }
            // }

            setState({
                ...state,
                loading:false,
                center:_center.data.list,
                assistanceUnit:_assistanceUnit.data.list,
                specialization:_specialization.data.list,
                professionalField:_professionalField.data.list,
                centerType:_centerType.data.list
            })
        }
        init();
        return () =>{}
      
    },[])

    const sort_by_id =() => {
        return function (elem1, elem2) {
          if (elem1.id < elem2.id) {
            return -1;
          } else if (elem1.id > elem2.id) {
            return 1;
          } else {
            return 0;
          }
        };
      }

    const loadProfessionals = async ({page, count, status = '', search = '', type = ''}) => {

        let _profesionals;
        let query  = ``;
        if(status !== ''){
            switch (status) {
                case '2':
                    query = `user/getProfessionals?page=${page}&pageCount=${count}&status=0&documentationCompleted=1`
                    if(search !== '') query = query+'&term='+search
                    if(type !== '') query = query+'&userType='+type
                    _profesionals = await Service('GET', query)
                    break;
                case '3':
                    query = `user/getProfessionals?page=${page}&pageCount=${count}&blocked=1`
                    
                    if(search !== '') query = query+'&term='+search
                    if(type !== '') query = query+'&userType='+type
                    _profesionals = await Service('GET',query)
                    break;
            
                default:
                    query = `user/getProfessionals?page=${page}&pageCount=${count}&status=${status}`
                    if(search !== '') query = query+'&term='+search
                    if(type !== '') query = query+'&userType='+type
                    _profesionals = await Service('GET', query)
            }
            // if(status === '2'){
            //     _profesionals = await Service('GET', `user/getProfessionals?page=${page}&pageCount=${count}&status=0&documentationCompleted=1`)
            // }else{
            //     _profesionals = await Service('GET', `user/getProfessionals?page=${page}&pageCount=${count}&status=${status}`)
            // }
        }else{
                query = `user/getProfessionals?page=${page}&pageCount=${count}`
                if(search !== '') query = query+'&term='+search
                if(type !== '') query = query+'&userType='+type
             _profesionals = await Service('GET', query)
             console.log(_profesionals)
        }


        
       
    
        
        return _profesionals;

        let professionals = [..._profesionals.data.list ]
        professionals.sort(sort_by_id());
        
        setState({
            ...state,
            loading:false,
            professionals:professionals.reverse(),
            professionalsCount: professionals.length
        })
    }

    const loadOrganizations = ({page, count, search = ''}) => {
        let query   =     `user/getOrganizations?page=${page}&pageCount=${count}`
        if(search !== '') query = query + '&term='+search;
        let _organization =  Service('GET', query)
        return _organization;

    }

    const loadCenters = async () => {
        let _center = await Service('GET', 'center/getAll')
        console.log(_center)
  
        setState({
            ...state,
            center:_center.data.list,
        })
    }

    const upLoadFile = async (file, type) => {

        let body = new FormData();
        body.append("", file);

        return await Service('POSTMEDIA', `document/upload/${type}`, body )
    
    }
    const upLoadDocument = async (file, type) => {


            

        let _upload = await upLoadFile(file, type )
      
        if(_upload.status === 201){

            let _doc = await Service('GET', `document/view/${user.id}/${type}`);

            let document = {
                type,
                file:{path:_doc.data.url},
                label:''
            }
            if(state.documents.some((item) => item.type === state.active_file_type)){
                state.documents = state.documents.filter((item)=>item.type !== state.active_file_type)
            }
            state.documents.push(document);
            notify({title:'Listo!', message:'Documento cargado exitosamente.' , type:'success'})

        }else{
            notify({title:'Error!', message:'Eror al cargar documento.' , type:'error'})
        }
        return _upload;


    }

    const renderTitle = (center, turnoType) => {
        if(center === '') return '';
        if(turnoType === '') return '';

        let _centerSelected = state.center.filter((item)=>item.id === parseInt(center))[0]?.name
        
        let _turnoTyoe = turnoType == '0' ? 'Acogida' : 'Cobertura' 
        let title = 'Turno de '+ _turnoTyoe + ' en '+_centerSelected;
        return title;
    }
    const getCenter = (center) => {
      
        return state.center.filter((item)=>item.id === parseInt(center))[0];
    }
    const getAssistanceUnit = (id) => {
        return state.assistanceUnit.filter((item)=>item.id === parseInt(id))[0]?.description 
    } 
    const getProfessionalField = (id) => {
        if(id === 1){
            return "No aplica"
        }
        return state.professionalField.filter((item)=>item.id === parseInt(id))[0]?.description 
    }
    const getSpecialization = (id) => {
        if(id === 1){
            return "No requiere especialidad"
        }
        return state.specialization.filter((item)=>item.id === parseInt(id))[0]?.description 
    }
    const getCenterType = (id) => {
        return state.centerType.filter((item)=>item.id === parseInt(id))[0]?.description 
    }
    const getProfesion = (id) => {
        switch (id) {
            case '0':
                
                return 'Enfermera/o'
            case '1':

                return 'TCAE'
           
        
            default:
                return ''
        }
    }

    const getProfesionByUserType = (id) => {
        switch (id) {
            case 1:                
                return 'Enfermera/o'
            case 3:
                return 'TCAE'                  
            default:
                return ''
        }
    }

  return (
    <AdminContext.Provider value={{
        ...state,
        loadProfessionals,
        loadOrganizations,
        getProfesion,
        getProfesionByUserType,
        renderTitle,
        getCenter,
        getAssistanceUnit,
        getProfessionalField,
        getSpecialization,
        getCenterType,
        getProfesion,
        loadCenters,
        upLoadDocument
      }}>

        <Renderif isTrue={state.loading}>
            <NuLoading></NuLoading>
        </Renderif>



        {children}

    </AdminContext.Provider>
  )
}
