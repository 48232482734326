import React, { useContext, useState, useEffect } from 'react'
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { IoMdClose, IoMdCloseCircle } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/auth_context';
import { NotificationContext } from '../../context/notification_context';
import { Service } from '../../services/api';
import { darkBlue } from '../../styles/colors';
import { months } from '../../utils';
import Renderif from '../../utils/renderif';

export default function RecuperacionPassword({onAccept, onClose, email}) {

    const navigate = useNavigate()
    const {notify} = useContext(NotificationContext);
    const [state, setState] = useState({
        code:'',
        email,
        password:'',
        confirmPassword:'',
        showPassword:false,
        errors:{}

    });
    const [countdown, setCountdown] = useState(60); // Countdown para el reenviar codigo

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         setCountdown((currentCountdown) => {
    //             if (currentCountdown <= 1) {
    //                 clearInterval(interval);
    //                 return 0;
    //             }
    //             return currentCountdown - 1;
    //         });
    //     }, 1000);
    //     return () => clearInterval(interval);
    // }, []);

    const resendCode = async () => { 
        setCountdown(60); 
    };

    const checkPassword = (password) => {
        var re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        return re.test(password);
    }
    const checkEmail = (email) => {
        var re = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
        return re.test(email);
    }
    const validator = () => {
        state.errors = {}   
        if(state.code === ''){
            state.errors.code = 'Código electrónico es obligatorio'
        }
       
        if(!checkPassword(state.password)){
            state.errors.password = 'Debe tener mínimo 8 caracteres, 1 letra Mayúscula, 1 letra minúscula, 1 número y 1 carácter especial (!&%#*?-^/)'
        }
        if(state.password === ''){
            state.errors.password = 'Contraseña es obligatorio'
        }
        if(state.confirmPassword !== state.password){
            state.errors.confirmPassword = 'Las contraseñas no coinciden'
        }
       
        setState({...state})
        if(Object.keys(state.errors).length > 0){
            
            return false;
        }
       return true;
        

    }

    const onResetPaassword = async () => {
     
        if(!validator()){
            return;
        }

        let body = {
            email:state.email,
            password:state.password,
            verificationCode:state.code
        }
        setState({...state, loading:true})
        let _recover = await Service('PUT', 'authentication/reset-password', body);
        if(_recover.status === 200 ){
            notify({title:'Recuperar contraseña!', message:`Su constraseña ha sido actualizada`, type:'success'})
            setState({...state, loading:false})
            onClose()
            return

        }
        if(_recover.status === 201 ){
            notify({title:'Recuperar contraseña!', message:`Su constraseña ha sido actualizada`, type:'success'})
            setState({...state, loading:false})
            onClose()
            return
        }
        notify({title:'Recuperar contraseña', message:`Lo sentimos, no ha sido posible cambiar su contraseña.`, type:'error'})
        setState({...state, loading:false})
    
    }
   


let today = new Date();
  return (
    <div className='overlay'>


        <div className='letter hide-scroll absolute-center'>
            <div className='d-flex p-3'>
                <div className='col'></div>
                <div className='bv-icon bv-icon--mid cursor-pointer' onClick={()=>{
                    onClose()
                }}>
                    <IoMdCloseCircle size={30} color={darkBlue}></IoMdCloseCircle>
                </div>
            </div>
            <div className='col d-flex flex-column px-5 mx-lg-5'>
        
        <div className='f-28 text-center bold my-3'>Recuperar contraseña</div>
        <div className='f-16 text-center'>Ingresa el código que <br></br>llegó a <span className='bold'>{state.email}</span></div>

        <div className='mt-3'></div>
        <input type='text' value={state.code} className='nu_input mt-2' placeholder='Code' onChange={(evt)=>{
               setState({...state, code:evt.currentTarget.value})
        }}></input>
            <div className='f-12 text-danger'>{state.errors?.email}</div>

            <div className='f-14 bold mt-3'>Correo eléctronico</div>
            <input disabled type='text' value={state.email} className='nu_input' placeholder='correo@correo.com' onChange={(evt)=>{
                    setState({...state, email:evt.currentTarget.value})
                }}></input>
                <div className='text-danger f-12'>{state.errors?.email}</div>

            

                <div className='f-14 bold mt-3'>Nueva contraseña</div>
                <div className='d-flex align-items-center'>

                <input type={state.showPassword ? 'text':'password'} value={state.password} onChange={(evt)=>{
                    setState({...state, password: evt.currentTarget.value})
                }}  className='nu_input' placeholder='Contraseña'></input>
            

                <div className='nu-icon nu-icon--mid cursor-pointer ms-2' onClick={()=>{
                    setState({...state, showPassword:!state.showPassword})
                }}>
                    <Renderif isTrue={state.showPassword}>
                        <BsEye size={30}></BsEye>
                    </Renderif>
                    <Renderif isTrue={!state.showPassword}>
                        <BsEyeSlash size={30}></BsEyeSlash>
                    </Renderif>
                </div>
                

                </div>
                <div className='text-danger f-12'>{state.errors?.password}</div>

            <div className='f-14 bold mt-3'>Confirmar contraseña</div>
            <input type={state.showPassword ? 'text': 'password'} value={state.confirmPassword} className='nu_input' placeholder='Contraseña' onChange={(evt)=>{
                    setState({...state, confirmPassword:evt.currentTarget.value})
                }}></input>
                <div className='text-danger f-12'>{state.errors?.confirmPassword}</div>
                
                <div className='mt-3'>
                    <div className='nu_btn nu_btn--overlay w-100' onClick={async ()=>{
                        //    await login({email:state.email, password:state.password})
                         onResetPaassword()
                         }}>
                        <div className='nu_btn-text f-18'>Confirmar</div>
                        <div className='nu_btn-overlay'></div>
                    </div>
                </div>

                {/* <div className='f-14 text-center mt-3 line-1' id="request-new-code-container">
                    Solicitar nuevo código en <span className='bold' id="countdown-timer">{countdown}</span>,<br></br>
                    <span
                        className={`bold ${countdown === 0 ? 'link-enabled' : 'link-disabled'}`}
                        onClick={countdown === 0 ? resendCode : null}
                        id="resend-code-link">
                        Reenviar código
                    </span>
                </div>         */}
            </div>
        </div>

    </div>
  )
}
