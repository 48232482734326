import React, { useCallback, useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { FaStar } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { AdminSideMenu, ClinicSideMenu, ProfesionalSideMenu } from '../../components/nu_sidemenu';
import NuSelect from '../../components/nu_select';
import { Service, downloadPDF } from '../../services/api';
import { AdminContext } from '../../context/admin_context';
import Renderif from '../../utils/renderif';
import NuLoading from '../../components/nu_loading';
import { UTCDateParser } from '../../utils';
export default function FacturaciónAdminView() {
    const [state, setState] = useState({
        loading: false,
        show_turno_detail:false,
        invoice: {},
        tickets: [],
        organizations:[],
        organizationsCount:0,
        selectedMonth: "",
        selectedYear: "",
        selectedOrganization: "",
        limit: 7,
        currentPage: 1,
        currentItems: [],
    })
    const { getCenter, loadOrganizations } = useContext(AdminContext);

    useEffect(() => {
        const init = async() => {
            setState(prevState => ({...prevState, loading: true}))
            try {
                let _organizations = await loadOrganizations({page:1, count:1000})
                setState(prevState => (
                    {...prevState, 
                        organizations: _organizations.data.list, 
                        organizationsCount: _organizations.data.total
                    }))
            } catch (error) {
                console.error("Error fetching data:", error)
            } finally {
                setState(prevState => ({...prevState, loading: false}))
            }
        }
        init()
    }, [])

    useEffect(() => {
        const search = async() => {
            if (state.selectedYear !== "" && state.selectedMonth !== "" && state.selectedOrganization !== "") {
                setState(prevState => ({...prevState, loading: true, invoice: {}, tickets: []}))
                try {
                    const { data: { organizationData } } = await Service('GET', `organizationData/getByIdUser/${state.selectedOrganization}`)
                    const _invoicesResponse = await Service('GET', 
                        `invoice/getAll?idOrganization=${organizationData.id}&year=${state.selectedYear}&month=${state.selectedMonth}`
                    )
                    const invoices = _invoicesResponse?.data?.list
                    if (invoices && invoices.length > 0){
                        const firstInvoice = invoices[0]
                        setState(prevState => ({...prevState, invoice: firstInvoice}))
                        const _ticketsResponse = await Service('GET', `shift/getInvoiced/${firstInvoice.id}`)
                        const tickets = _ticketsResponse?.data?.list
                        setState(prevState => ({...prevState, tickets: tickets}))
                        setTimeout(() => {}, 1500);
                    }
                } catch (error) {
                    console.error("Error fetching data:", error)
                } finally {
                    setState(prevState => ({...prevState, loading: false}))
                }
            }
        }
        search()
    }, [state.selectedMonth, state.selectedYear, state.selectedOrganization])

    const handleDownload = useCallback(() => {
        downloadPDF(`invoice/getFile/${state.invoice?.id}`, state.invoice?.idDocument);
    }, [state.invoice]);

    const generateYears = (startYear) => {
        const currentYear = new Date().getFullYear();
        let years = [];
        for (let year = startYear; year <= currentYear; year++) {
            years.push(year);
        }
        return years;
    };

    const handleChangeSelect = (newValue, valueOnState) => {
        if (valueOnState === "Month") setState({...state, selectedMonth: newValue})
        if (valueOnState === "Year") setState({...state, selectedYear: newValue})
        if (valueOnState === "Organization") setState({...state, selectedOrganization: newValue})
    };

    const totalCost = () => {
        let total = 0
        state.tickets.map((ticket) => total = total + parseFloat(ticket.remuneration)*0.20.toFixed(2))
        return total
    }

    const totalTurnosAcogida = () => {
        const totalAcogida = state.tickets.filter((ticket) => ticket.type === "0")
        return totalAcogida.length
    }

    const totalTurnosCobertura = () => {
        const totalCobertura = state.tickets.filter((ticket) => ticket.type === "1")
        return totalCobertura.length
    }

    useEffect(() => {
        const startIndex = (state.currentPage - 1) * state.limit;
        const endIndex = startIndex + state.limit;
        setState(prevState => ({...prevState, currentItems: state.tickets.slice(startIndex, endIndex)}));
    }, [state.currentPage, state.tickets])

    const handleChangePage = (newValue) => {
        setState(prevState => ({...prevState, currentPage: newValue }))
    };

    const nextPage = () => {
        const pages = Math.ceil(state.tickets.length / state.limit);
        if (state.currentPage === pages) {
            return
        }
        setState(prevState => ({...prevState, currentPage: state.currentPage + 1 }))
      };
    
      const prevPage = () => {
        if (state.currentPage === 1) {
            return
        }
        setState(prevState => ({...prevState, currentPage: state.currentPage - 1 }))
      };

    const renderPagination = () => {
        const pages = Math.ceil(state.tickets.length / state.limit);
        let items = []
        for (let i = 1; i <= pages; i++) {
          items.push(i);
        }
      
        return items.map((item) => {
            return (
                <div
                key={item}
                style={{width:32}}
                className={`f-16 bold text-dark px-2 cursor-pointer mb-1 r-10 text-center ${state.currentPage === item ? 'bg-info-subtle':'' }`}
                onClick={() => handleChangePage(item)}>{item}</div>
            )
        })
    }

  return (
    <>
    <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
    </Renderif>
    <div className='container-fluid overflow-hidden d-flex h-100'>


        <AdminSideMenu page='facturacion' />
   


        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-3 px-3'>
     

                <div className='nu-snack'>
                <div>
                        <div className='f-22 text-white bold'>Hola, Admin</div>
                        <div className='d-flex align-items-center'>
                            <div className='nu-icon nu-icon--mid'>
                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                            </div>

                            <div className='f-16 text-white'>5.0 (0 reseñas)</div>

                        </div>
                    
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid' style={{backgroundImage: 'url(/assets/avatar.png)'}}></div>
                    <div className='nu-snack-overlay'></div>
                </div>
                       
            </div>

            <div className='container p-3'>

                <div className='f-24 bold'>Facturación</div>


                <div className='d-flex mt-4 '>

                    <div className='col-12 d-flex align-items-center'>

                        <div className='f-16 bold'>Filtrar por</div>

                        <div className='col ms-3'>
                            <NuSelect value={state.selectedOrganization} onChange={(event) => handleChangeSelect(event.target.value, "Organization")}>
                                <option value={""}>Organización</option>
                                {state.organizations.map((organization) => (
                                    <option key={organization.id} value={organization.id}>
                                        {organization.name}
                                    </option>
                                ))}
                            </NuSelect>
                        </div>

                        <div className='col ms-2'>
                            <NuSelect value={state.selectedYear} onChange={(event) => handleChangeSelect(event.target.value, "Year")}>
                                <option value={""}>Año</option>
                                {generateYears(2024).map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </NuSelect>
                        </div>
                        <div className='col ms-2'>
                            <NuSelect value={state.selectedMonth} onChange={(event) => handleChangeSelect(event.target.value, "Month")}>
                                <option value={""}>Mes</option>
                                <option value={"1"}>Enero</option>
                                <option value={"2"}>Febrero</option>
                                <option value={"3"}>Marzo</option>
                                <option value={"4"}>Abril</option>
                                <option value={"5"}>Mayo</option>
                                <option value={"6"}>Junio</option>
                                <option value={"7"}>Julio</option>
                                <option value={"8"}>Agosto</option>
                                <option value={"9"}>Septiembre</option>
                                <option value={"10"}>Octubre</option>
                                <option value={"11"}>Noviembre</option>
                                <option value={"12"}>Diciembre</option>
                            </NuSelect>
                        </div>
                        <div className='col'></div>
                     
                        <div className='nu_btn nu_btn--overlay' onClick={handleDownload}>
                            <div className='nu_btn-text f-18'>Descargar</div>
                            <div className='nu_btn-overlay'></div>
                        </div>
                
                       

                    </div>
                </div>


                <div className='d-flex mt-4 '>

                    <div className='col-3'>

                        <div className='d-flex justify-content-center align-items-center shadow-sm r-10 py-4 me-2'>
                            <div className='f-16 bold'>Total de turnos <br></br> asignados</div>
                            <div className='nu-sumary'>
                                <div className='f-24 bold'>{state.tickets.length > 0 ? state.tickets.length : 0}</div>
                            </div>
                        </div>
                        
                    </div>
                    <div className='col-3'>

                         <div className='d-flex justify-content-center align-items-center shadow-sm r-10 py-4 mx-2'>
                            <div className='f-16 bold'>Total de turnos <br></br> de cobertura</div>
                            <div className='nu-sumary'>
                                <div className='f-24 bold'>{totalTurnosCobertura()}</div>
                            </div>
                        </div>

                    </div>
                    <div className='col-3'>

                        <div className='d-flex justify-content-center align-items-center shadow-sm r-10 py-4 mx-2'>
                            <div className='f-16 bold'>Total de turnos <br></br> de acogida</div>
                            <div className='nu-sumary'>
                                <div className='f-24 bold'>{totalTurnosAcogida()}</div>
                            </div>
                        </div>


                    </div>
                    <div className='col-3'>

                         <div className='d-flex justify-content-center align-items-center shadow-sm r-10 py-4 ms-2 h-100'>

                            <div className='bg-darkblue r-10 p-3'>
                                <img width={40} src='/assets/euro_symbol.svg'></img>
                            </div>

                            <div className='ms-3 mt-2'>
                                <div className='f-16 bold line-1'>Coste total por <br></br> asignaciónes</div>
                                <div className='f-26 bold' >{state.tickets.length > 0 ? totalCost() : 0.00}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='d-flex flex-column mt-4 '>

                    {state.currentItems.length > 0 ? state.currentItems.map((ticket, index)=>{
                        return (
                            <div key={index} className='row mt-2 py-3 border-bottom'>
                                <div className='col d-flex align-items-center'>
                                    <img width={16} src='/assets/calendar_solid.svg' alt='Calendario' />
                                    <div className='f-16 bold ms-2'>{UTCDateParser(new Date(ticket.date).toUTCString())}</div>
                                </div>

                                <div className='col'>
                                    <div className='f-16 bold'>{getCenter(ticket.idCenter).name}</div>
                                </div>

                                <div className='col'>
                                    <div className='f-16 bold'>{ticket.type === "0" ? "Turno de acogida" : "Turno de cobertura"}</div>
                                </div>

                                <div className='col'>
                                    <div className='f-16 bold'>Remuneración: € {ticket.remuneration}</div>
                                </div>

                                <div className='col'>
                                    <div className='f-16 bold'>Coste por asignación: € {(parseFloat(ticket.remuneration) * 0.20).toFixed(2)}</div>
                                </div>
                            </div>
                        )
                    }) : (
                        <div className='d-flex justify-content-center align-items-center'>
                            <p>No hay factura para este periodo</p>
                        </div>
                    )}
                </div>

                <div className='d-flex justify-content-center my-5'>
                    <div className='nu-icon nu-icon--mid cursor-pointer'>
                        <SlArrowLeft onClick={prevPage}/>
                    </div>
                    <div className='d-flex px-3'>
                        {renderPagination()}
                    </div>
                    <div className='nu-icon nu-icon--mid cursor-pointer'>
                        <SlArrowRight onClick={nextPage}/>
                    </div>
                </div>


            </div>

       
      
        </div>

   
   
  
     
    </div>
    </>
  )
}
