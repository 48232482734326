import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { FaStar } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { ClinicSideMenu, ProfesionalSideMenu } from '../../components/nu_sidemenu';
import { MdOutlinePersonOff } from "react-icons/md";
import { Service } from '../../services/api';
import Renderif from '../../utils/renderif';
import { GlobalContext } from '../../context/global_context';
import { useNavigate } from 'react-router-dom';
import { NotificationContext } from '../../context/notification_context';
import { AuthContext } from '../../context/auth_context';
import NuLoading from '../../components/nu_loading';
export default function UsuariosBloqueadosClinicaView() {

    const {user_data, user} = useContext(AuthContext)
    const {getProfesion, center} = useContext(GlobalContext)
    const {notify} = useContext(NotificationContext)
    const navigate = useNavigate()
    const [state, setState] = useState({
        filters:'',
        page:1,
        count:7,
        professionals:[],
        professionalsCount:0, 
        bloqueos:[],
        loading:false
    })


    useEffect(()=>{

        init();
        return () => {}
    },[])

    const init = async () => {
      
        setState({...state, loading:true})
        let _profesionals = await loadProfessionals({page:1, count:state.count, status:state.filters})
        state.professionals = _profesionals.data.list
        state.professionalsCount = _profesionals.data.total
        setState({...state, loading:false})
    }

    const loadProfessionals = async ({page, count, status}) => {

        let _profesionals = await Service('GET', `user/getMyProfessionals?page=${page}&pageCount=${count}`)
        let _bloqueos = await Service('GET', 'restriction/getAll');

        let my_centers = center.filter((item)=>item.idOrganization === user_data?.id);

        let bloqueos = []

        for (let i = 0; i < my_centers.length; i++) {
            console.log(my_centers[i])
            _bloqueos.data.list.map((bloqueo)=>{
                if(bloqueo.idCenter === my_centers[i].id){
                    bloqueos.push(bloqueo)
                }
            })
        }
        

        console.log(bloqueos)
        state.bloqueos = bloqueos;


        // console.log(_bloqueos);
        // console.log(_profesionals);
        return _profesionals;

    }

    const onBlockProfesional = async  (idUser) => {

    }

    const onUnblockProfesional = async  (user) => {
        let _bloqueo = state.bloqueos.filter((bloqueo) => bloqueo.idUser === user.id)[0]
        let _unblock = await Service('DELETE', 'restriction/delete/'+_bloqueo.id);
        init();
        console.log(_unblock);
     
    }

    const renderPagination = () => {


        let pages = Math.ceil(state.professionalsCount / state.count);
        let items = []
        for (let i = 1; i <= pages; i++) {
            items.push(i);
        }

        // items.reverse()

        return items.map((item, i) => {
           
            return (
                <div key={i} style={{width:32}} className={`f-16 bold text-dark px-2 cursor-pointer mb-1 r-10 text-center ${state.page === item ? 'bg-light':'' }`} onClick={async ()=>{
                    
                    setState({...state, loading:true})
                    let _profesionals = await loadProfessionals({page:item, count:state.count, status:state.filters})

          
        
                    
                    setState({...state, 
                        page:item,
                        loading:false, 
                        professionals:_profesionals.data.list, 
                        professionalsCount:_profesionals.data.total,
                
                    })
                }}>{item}</div>
            )
        })
        
        


     

    }

    const userBlocked = (user) => {

        let blocked = false;
        state.bloqueos.map((bloqueo)=>{
        
            if(bloqueo.idUser === user.id){
           
                blocked = true;
       
            }
        })
        console.log(blocked)
        return blocked;
    }


    
   

    


  



  return (
    <>
    <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
    </Renderif>
    <div className='container-fluid overflow-hidden d-flex h-100'>


        <ClinicSideMenu page='' />
   


        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-3 px-3'>
     

            <div className='nu-snack'>
                <div>
                        <div className='f-22 text-white bold'>Hola, {user?.name}</div>
                        <div className='d-flex align-items-center'>
                            <div className='nu-icon nu-icon--mid'>
                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                            </div>

                            {/* <div className='f-16 text-white'>5.0 (0 reseñas)</div> */}
                            <div className='f-16 text-white'>5.0 (0 reseñas)</div>

                        </div>
                    
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid' style={{backgroundImage: `url(${user?.picture})`}}></div>
                    <div className='nu-snack-overlay'></div>
                </div>
                       
            </div>

            <div className='container p-3'>


          

                <div className='f-24  bold mt-4'>Usuarios</div>

                <div>
                    { 
                        // professionals?.map((item, i)=>{
                        state.professionals?.map((item, i)=>{

                            if(userBlocked(item) === false) return;
                            
                      
                            return(
                                <div key={i} className='d-flex align-items-center border-bottom py-3'>
                            
                                    <div className='col'>
                                        
                                        <div className='d-flex  align-items-center'>
                                            <div className='avatar me-2'></div>
                                            <div className=''>
                                                <Renderif isTrue={item?.name === undefined}>
                                                    <div className='f-14 bold'>Sin nombre</div>
                                                </Renderif>
                                                <div className='f-14 bold'>{item?.name}</div>
                                                <div className='f-12'>{getProfesion(item?.idUserType)}</div>
                                            </div>
                                        </div>

                        
                                    </div>
                                    {/* <div className='col'>
                                        
                                        <div className='f-14 bold'>{
                                            item?.status === "0" ? 'Pendiente de validar' : 'Perfil Validado'
                                        }</div>
                                        <Renderif isTrue={item.documentationCompleted === true && item.status === '0'}>
                                            <div className='f-12 bold text-success'>En revisión</div>
                                        </Renderif>

                                    </div> */}
                                    <div className='col'>
                                        <div className='f-14 bold'>{item?.email}</div>
                                    </div>
                                    <div className='col'>

                                        <div className='d-flex align-items-center'>
                                            <div className='nu-icon nu-icon--mid'>
                                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                                            </div>
                                            <div className='f-12 bolder'>{item.stars === null ? 'Sin Valoraciones' : parseFloat(item.stars).toFixed(1)}</div>
                                       
                                            
                                        </div>
                                        
                                    </div>
                                    {/* <div className='pe-1'>
                                        <div className='nu_btn nu_btn--primary' onClick={()=>{
                                                setState({...state, show_turno_detail:true})
                                            }}>

                                            <div className='nu-icon nu-icon--mid'>
                                                <IoIosSearch size={20}></IoIosSearch>
                                            </div>

                                            <div className='nu_btn-text '>Ver perfil</div>
                                        </div>
                                    </div> */}
                                    <div className='px-1'>

                                        <div className='nu_btn nu_btn--primary' onClick={()=>{
                                                navigate('/clinic/documentacion/'+item.id)
                                            }}>

                                            <div className='nu-icon nu-icon--mid'>
                                                <IoIosSearch size={20}></IoIosSearch>
                                            </div>

                                            <div className='nu_btn-text '>Ver documentación</div>
                                        </div>
                                    </div>
                                    <div className='ps-1'>
                                         
                                        <Renderif isTrue={userBlocked(item) === false}>
                                            <div className='nu_btn nu_btn--danger' onClick={()=>{
                                                    onBlockProfesional(item.id)
                                                }}>

                                                <div className='nu-icon nu-icon--mid'>
                                                    <MdOutlinePersonOff size={20}></MdOutlinePersonOff>
                                                </div>

                                                <div className='nu_btn-text ps-2'>Bloquear</div>
                                            </div>
                                        </Renderif>

                                        <Renderif isTrue={userBlocked(item) === true}>
                                            <div className='nu_btn nu_btn--light' onClick={()=>{
                                              onUnblockProfesional(item)
                                                }}>

                                                <div className='nu-icon nu-icon--mid'>
                                                    <MdOutlinePersonOff size={20}></MdOutlinePersonOff>
                                                </div>

                                                <div className='nu_btn-text ps-2'>Desbloquear</div>
                                            </div>
                                        </Renderif>

                                      

                                    </div>


                                </div>
                            )})

                    }

                </div>

                <div className='d-flex justify-content-center my-5'>
                    <div className='nu-icon nu-icon--mid cursor-pointer'>
                        <SlArrowLeft></SlArrowLeft>
                    </div>
                    <div className='d-flex flex-wrap px-3'>
                        {renderPagination()}
                    </div>
                    <div className='nu-icon nu-icon--mid cursor-pointer'>
                        <SlArrowRight></SlArrowRight>
                    </div>
                </div>
             
             
                

               

              
          
            </div>

       
      
        </div>

   
   
  
     
    </div>
    </>
  )
}
