import React, { useContext, useState } from 'react'
import { IoMdClose, IoMdCloseCircle } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/auth_context';
import { NotificationContext } from '../../context/notification_context';
import { Service } from '../../services/api';
import { darkBlue } from '../../styles/colors';
import { months } from '../../utils';
import Renderif from '../../utils/renderif';

export default function RememberPassword({onAccept, onClose}) {

    const navigate = useNavigate()
    const {notify} = useContext(NotificationContext)
    const [state, setState] = useState({
        email:'',
        errors:{},
        loading:false
    });
    const isValidEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
      };
    const validator = () => {
        state.errors = {}
        
        if(state.email === ''){
            state.errors.email = 'Correo electrónico es obligatorio'
        }
        setState({...state})
        if(Object.keys(state.errors).length > 0){
            
            return false;
        }
       return true;
    
    }
    const onRequestCode = async () => {
        //Validar el correo antes de continuar
        if (!isValidEmail(state.email)) {
            setState(prevState => ({
                ...prevState,
                errors: { ...prevState.errors, email: 'Por favor, ingresa un correo electrónico válido.' }
            }));
            return;
        }

        if(!validator()){
            return;
        }

        let body = {
            email:state.email
        }
        setState({...state, loading:true})
        let request = await Service('POST', 'authentication/forgot-password', body);
        if(request.status === 200 ){
            notify({title:'Recuperar contraseña!', message:`Se ha enviado un código de verificacion a ·${body.email}`, type:'success'})
            setState({...state, loading:false})
            onAccept(state.email)
            return

        }
        if(request.status === 201 ){
            notify({title:'Recuperar contraseña!', message:`Se ha enviado un código de verificacion a ·${body.email}`, type:'success'})
            setState({...state, loading:false})
            onAccept(state.email)
            return
        }
        notify({title:'Recuperar contraseña', message:`Lo sentimos, no ha sido posible enviar un código de verficción a ${body.email}`, type:'error'})
        setState({...state, loading:false})
        
      
    }
    const onCode = async () => {
        //Validar el correo antes de continuar
        if (!isValidEmail(state.email)) {
            setState(prevState => ({
                ...prevState,
                errors: { ...prevState.errors, email: 'Por favor, ingresa un correo electrónico válido.' }
            }));
            return;
        }

        if(!validator()){
            return;
        }

      
      
        
        setState({...state, loading:false})
        onAccept(state.email)
        return
       

     
        
      
    }
   
let today = new Date();
  return (
    <div className='overlay'>
        <div className='letter hide-scroll absolute-center'>
            <div className='d-flex p-3'>
                <div className='col'></div>
                    <div className='bv-icon bv-icon--mid cursor-pointer' onClick={()=>{
                        onClose()
                    }}>
                        <IoMdCloseCircle size={30} color={darkBlue}></IoMdCloseCircle>
                </div>
            </div>

            <div className='col d-flex flex-column px-5 mx-lg-5'>

       
        
            <div className='f-28 text-center bold my-5'>Recuperar contraseña</div>

                

            <div className='f-12 text-center'>Ingresa tu correo electrónico, <br></br>te enviaremos un código de verificación.</div>
            <input type='text' value={state.email} className='nu_input mt-2' placeholder='Correo electrónico' onChange={(evt) => {
                const updatedEmail = evt.currentTarget.value;
                    if (!isValidEmail(updatedEmail) && updatedEmail.length > 0) {
                        setState(prevState => ({
                            ...prevState,
                            email: updatedEmail,
                            errors: { ...prevState.errors, email: 'Por favor, ingresa un correo electrónico válido.' }
                        }));
                    } else {
                        setState(prevState => ({
                            ...prevState,
                            email: updatedEmail,
                            errors: { ...prevState.errors, email: '' }
                        }));
                    }
                }}>
            </input>
            <div className='text-danger f-12'>{state.errors?.email}</div>

                <div className='mt-3'>
                    <div className={`nu_btn nu_btn--overlay w-100 ${state.loading}`} onClick={async ()=>{
                            onRequestCode()
                            // onAccept(true)              
                        //    await login({email:state.email, password:state.password})
                    }}>
                        
                        <Renderif isTrue={state.loading}>
                                <div className='col'></div>
                                <div className="spinner-border spinner--mid text-light"></div>
                        </Renderif>
                        <Renderif isTrue={!state.loading}>
                                <div className='nu_btn-text f-18'>Solicitar código</div>
                        </Renderif>
                        
                        <div className='nu_btn-overlay'></div>
                    </div>
                </div>
                <div className={`nu_btn nu_btn--overlay w-100 mt-3`} onClick={async ()=>{
                    onCode()
                        //onRequestCode()
                        // onAccept(true)              
                    //    await login({email:state.email, password:state.password})
                }}>
                    
                    
                            <div className='nu_btn-text f-18'>Ya tengo código</div>
                
                    
                    <div className='nu_btn-overlay'></div>
                </div>
            </div>
        </div>
    </div>
  )
}
