import { set } from 'date-fns'
import React, { useContext, useEffect, useState } from 'react'
import { IoAddCircleOutline } from 'react-icons/io5'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { AuthContext } from '../context/auth_context'
import { GlobalContext } from '../context/global_context'
import { Service } from '../services/api'
import Renderif from '../utils/renderif'
import CheckBox from './nu_checkbox'

export function NuPickerAssitanceUnit({onChangePreferences = ()=>{}}) {
  const {user, user_data, getUserDateNurse, updateUserPictue, setUserPicture} = useContext(AuthContext)
  const {specialization, centerType, professionalField, assistanceUnit, getRating} = useContext(GlobalContext)

  const [state, setState] = useState({
    open:false,
    preferredAssistanceUnit:[],
  })

  useEffect(()=> {
    if(user_data?.preferredAssistanceUnit !== undefined){
      setState({...state, preferredAssistanceUnit:user_data?.preferredAssistanceUnit})
    }
   
  },[user_data])

  const updatePreferredAssistanceUnit=  async () => {
    let body = {
        preferredAssistanceUnitList:[]
    }
    state.preferredAssistanceUnit.map((item, i)=>{
       
        body.preferredAssistanceUnitList.push({
            idUser:item.idUser,
            idAssistanceUnit:item.idAssistanceUnit
        })
    })
    let _update = await Service('POST', 'preferredAssistanceUnit/create', body);
    // if(_update.status === 201){
    //     notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
    // }else{
    //     notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
    // }
    getUserDateNurse();
    onChangePreferences()

}

  return (
    <div className=''>

      <div className='nu-picker' onClick={()=>{
        setState({...state, open:!state.open})
      }}>
        <div className='col'>
          <div className='f-14 bold text-gray line-1'>Unidad Asistencial</div>
        </div>
      

        <Renderif isTrue={state.preferredAssistanceUnit.length === 1}>

          <Renderif isTrue={state.preferredAssistanceUnit[0]?.idAssistanceUnit !== 1}>
            <div className='nu-badge'>{state.preferredAssistanceUnit.length}</div>
          </Renderif>

        </Renderif>

        <Renderif isTrue={state.preferredAssistanceUnit.length !== 1}>
          <div className='nu-badge'>{state.preferredAssistanceUnit.length}</div>
        </Renderif>


        <div className='nu-icon nu-icon--mid'>
          <MdKeyboardArrowDown size={30}></MdKeyboardArrowDown>
        </div>
      </div>

      <Renderif isTrue={state.open}>
        <div className='nu-picker-options hide-scroll'>

        {assistanceUnit?.filter((item) => item.id !== 16 && item.id !== 14).map((unit, l)=>{
         
         

      return (
        <div key={l} className='nu-picker-option'>
          <CheckBox checked={state.preferredAssistanceUnit.some((item) => item.idAssistanceUnit === unit.id)}  text={unit.description} onChange={(evt)=>{

            if(evt.value === true){
            
               if(unit.id === 1){
                        state.preferredAssistanceUnit = [];
                    }else{
                        state.preferredAssistanceUnit = state.preferredAssistanceUnit.filter((item)=> item.idAssistanceUnit !== 1)
                    }
                    

                    state.preferredAssistanceUnit.push({
                        id:0,
                        idAssistanceUnit:unit.id,
                        idUser:user_data.id
                    }) 

            }else{
           
              state.preferredAssistanceUnit = state.preferredAssistanceUnit.filter((item)=> item.idAssistanceUnit !== unit.id)
            }
            setState({...state})
            updatePreferredAssistanceUnit()
           
            
          }}></CheckBox>
        </div>
      )                           
                                                  
        return (
            <div key={l} className={`nu-tag cursor-pointer ${state.preferredAssistanceUnit.some((item) => item.idAssistanceUnit === unit.id) ? 'nu-tag--active':''}`} onClick={()=>{
                
                if(state.preferredAssistanceUnit.some((item) => item.idAssistanceUnit === unit.id)){
                    state.preferredAssistanceUnit = state.preferredAssistanceUnit.filter((item)=> item.idAssistanceUnit !== unit.id)
                }else{


                    if(unit.id === 1){
                        state.preferredAssistanceUnit = [];
                    }else{
                        state.preferredAssistanceUnit = state.preferredAssistanceUnit.filter((item)=> item.idAssistanceUnit !== 1)
                    }
                    

                    state.preferredAssistanceUnit.push({
                        id:0,
                        idAssistanceUnit:unit.id,
                        idUser:user_data.id
                    }) 
                }
                setState({...state})
          
            
            }}>
                <div>{unit.description}</div>
                <div className='nu-icon nu-icon--mid ms-2'>
                    <IoAddCircleOutline size={24}></IoAddCircleOutline>
                </div>
            </div>
        )
        })}
          {/* <div className='nu-picker-option'>
            <CheckBox text="asd"></CheckBox>
          </div>
          <div className='nu-picker-option'>
            <CheckBox text="asd"></CheckBox>
          </div>
          <div className='nu-picker-option'>
            <CheckBox text="asd"></CheckBox>
          </div> */}
        </div>
      </Renderif>

   


    </div>
   
  )
}

export function NuPickerProfessionalField({onChangePreferences = ()=>{}}) {
  const {user, user_data, getUserDateNurse, updateUserPictue, setUserPicture} = useContext(AuthContext)
  const {specialization, centerType, professionalField, assistanceUnit, getRating} = useContext(GlobalContext)

  const [state, setState] = useState({
    open:false,
    preferredProfessionalField: [],
  })

  useEffect(()=> {
    if(user_data?.preferredProfessionalField !== undefined){
      setState({...state, preferredProfessionalField:user_data?.preferredProfessionalField})
    }
   
  },[user_data])

  const updatePreferredProfessionalField=  async () => {
    let body = {
      preferredProfessionalFieldList:[]
  }
  state.preferredProfessionalField.map((item, i)=>{
     
      body.preferredProfessionalFieldList.push({
          idUser:item.idUser,
          idProfessionalField:item.idProfessionalField
      })
  })
  let _update = await Service('POST', 'preferredProfessionalField/create', body);
    // if(_update.status === 201){
    //     notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
    // }else{
    //     notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
    // }
    getUserDateNurse();
    onChangePreferences()

}

  return (
    <div className=''>

      <div className='nu-picker' onClick={()=>{
        setState({...state, open:!state.open})
      }}>
        <div className='col'>
          <div className='f-14 bold text-gray line-1'>Ámbito Profesional</div>
        </div>
       
        {/* <div className='nu-badge'>{state.preferredProfessionalField.length}</div> */}
        <Renderif isTrue={state.preferredProfessionalField.length === 1}>

          <Renderif isTrue={state.preferredProfessionalField[0]?.idProfessionalField !== 1}>
            <div className='nu-badge'>{state.preferredProfessionalField.length}</div>
          </Renderif>

        </Renderif>

        <Renderif isTrue={state.preferredProfessionalField.length !== 1}>
          <div className='nu-badge'>{state.preferredProfessionalField.length}</div>
        </Renderif>
        
        <div className='nu-icon nu-icon--mid'>
          <MdKeyboardArrowDown size={30}></MdKeyboardArrowDown>
        </div>
      </div>

      <Renderif isTrue={state.open}>
        <div className='nu-picker-options hide-scroll'>

        {professionalField?.filter((item) => item.id !== 7).map((unit, l)=>{
     

      return (
        <div key={l} className='nu-picker-option'>
          <CheckBox checked={state.preferredProfessionalField.some((item) => item.idProfessionalField === unit.id)}  text={unit.description} onChange={(evt)=>{

            if(evt.value === true){
         
               if(unit.id === 1){
                        state.preferredProfessionalField = [];
                    }else{
                        state.preferredProfessionalField = state.preferredProfessionalField.filter((item)=> item.idProfessionalField !== 1)
                    }
                    

                    state.preferredProfessionalField.push({
                        id:0,
                        idProfessionalField:unit.id,
                        idUser:user_data.id
                    }) 

            }else{
           
              state.preferredProfessionalField = state.preferredProfessionalField.filter((item)=> item.idProfessionalField !== unit.id)
            }
            setState({...state})
            updatePreferredProfessionalField()
           
            
          }}></CheckBox>
        </div>
      )                           
                                                  
        return (
            <div key={l} className={`nu-tag cursor-pointer ${state.preferredAssistanceUnit.some((item) => item.idAssistanceUnit === unit.id) ? 'nu-tag--active':''}`} onClick={()=>{
                
                if(state.preferredAssistanceUnit.some((item) => item.idAssistanceUnit === unit.id)){
                    state.preferredAssistanceUnit = state.preferredAssistanceUnit.filter((item)=> item.idAssistanceUnit !== unit.id)
                }else{


                    if(unit.id === 1){
                        state.preferredAssistanceUnit = [];
                    }else{
                        state.preferredAssistanceUnit = state.preferredAssistanceUnit.filter((item)=> item.idAssistanceUnit !== 1)
                    }
                    

                    state.preferredAssistanceUnit.push({
                        id:0,
                        idAssistanceUnit:unit.id,
                        idUser:user_data.id
                    }) 
                }
                setState({...state})
          
            
            }}>
                <div>{unit.description}</div>
                <div className='nu-icon nu-icon--mid ms-2'>
                    <IoAddCircleOutline size={24}></IoAddCircleOutline>
                </div>
            </div>
        )
        })}
          {/* <div className='nu-picker-option'>
            <CheckBox text="asd"></CheckBox>
          </div>
          <div className='nu-picker-option'>
            <CheckBox text="asd"></CheckBox>
          </div>
          <div className='nu-picker-option'>
            <CheckBox text="asd"></CheckBox>
          </div> */}
        </div>
      </Renderif>

   


    </div>
   
  )
}

export function NuPickerCenterType({onChangePreferences = ()=>{}}) {
  const {user, user_data, getUserDateNurse, updateUserPictue, setUserPicture} = useContext(AuthContext)
  const {specialization, centerType, professionalField, assistanceUnit, getRating} = useContext(GlobalContext)

  const [state, setState] = useState({
    open:false,
    preferredCenter:[],
  })

  useEffect(()=> {
    if(user_data?.preferredCenter !== undefined){
      setState({...state, preferredCenter:user_data?.preferredCenter})
    }
   
  },[user_data])

  const updatePreferredCenter=  async () => {
    let body = {
      preferredCenterList:[]
  }
  state.preferredCenter.map((center, i)=>{
      body.preferredCenterList.push({
          idUser:center.idUser,
          idCenterType:center.idCenterType
      })
  })
  let _update = await Service('POST', 'preferredCenter/create', body);
    // if(_update.status === 201){
    //     notify({title:'Perfil', message:'Tu perfil ha sido actualizado', type:'success'})
    // }else{
    //     notify({title:'Perfil', message:'Algo salio mal. Intenta de nuevo más tarde', type:'error'})
    // }
    getUserDateNurse();
    onChangePreferences()

}
  // console.log(state?.preferredCenter[0])
  // if(2===2){
  //   return (
  //     <></>
  //   )
  // }

  return (
    <div className=''>

      <div className='nu-picker' onClick={()=>{
        setState({...state, open:!state.open})
      }}>
        <div className='col'>
          <div className='f-14 bold text-gray line-1'>Tipo de centro</div>
        </div>
       
        {/* <div className='nu-badge'>{state.preferredCenter.length}</div> */}
          <Renderif isTrue={state.preferredCenter?.length === 1}>
              
            <Renderif isTrue={state.preferredCenter[0]?.idCenterType !== 1}>
              <div className='nu-badge'>{state.preferredCenter.length}</div>
            </Renderif>

          </Renderif>

        <Renderif isTrue={state.preferredCenter?.length !== 1}>
          <div className='nu-badge'>{state.preferredCenter?.length}</div>
        </Renderif>
        <div className='nu-icon nu-icon--mid'>
          <MdKeyboardArrowDown size={30}></MdKeyboardArrowDown>
        </div>
      </div>

      <Renderif isTrue={state.open}>
        <div className='nu-picker-options hide-scroll'>

        {centerType?.map((unit, l)=>{
     

      return (
        <div key={l} className='nu-picker-option'>
          <CheckBox checked={state.preferredCenter.some((item) => item.idCenterType === unit.id)}  text={unit.description} onChange={(evt)=>{

            if(evt.value === true){
           
               if(unit.id === 1){
                        state.preferredCenter = [];
                    }else{
                        state.preferredCenter = state.preferredCenter.filter((item)=> item.idCenterType !== 1)
                    }
                    

                    state.preferredCenter.push({
                        id:0,
                        idCenterType:unit.id,
                        idUser:user_data.id
                    }) 

            }else{
           
              state.preferredCenter = state.preferredCenter.filter((item)=> item.idCenterType !== unit.id)
            }
            setState({...state})
            updatePreferredCenter()
           
            
          }}></CheckBox>
        </div>
      )                           
                                                  
        return (
            <div key={l} className={`nu-tag cursor-pointer ${state.preferredAssistanceUnit.some((item) => item.idAssistanceUnit === unit.id) ? 'nu-tag--active':''}`} onClick={()=>{
                
                if(state.preferredAssistanceUnit.some((item) => item.idAssistanceUnit === unit.id)){
                    state.preferredAssistanceUnit = state.preferredAssistanceUnit.filter((item)=> item.idAssistanceUnit !== unit.id)
                }else{


                    if(unit.id === 1){
                        state.preferredAssistanceUnit = [];
                    }else{
                        state.preferredAssistanceUnit = state.preferredAssistanceUnit.filter((item)=> item.idAssistanceUnit !== 1)
                    }
                    

                    state.preferredAssistanceUnit.push({
                        id:0,
                        idAssistanceUnit:unit.id,
                        idUser:user_data.id
                    }) 
                }
                setState({...state})
          
            
            }}>
                <div>{unit.description}</div>
                <div className='nu-icon nu-icon--mid ms-2'>
                    <IoAddCircleOutline size={24}></IoAddCircleOutline>
                </div>
            </div>
        )
        })}
          {/* <div className='nu-picker-option'>
            <CheckBox text="asd"></CheckBox>
          </div>
          <div className='nu-picker-option'>
            <CheckBox text="asd"></CheckBox>
          </div>
          <div className='nu-picker-option'>
            <CheckBox text="asd"></CheckBox>
          </div> */}
        </div>
      </Renderif>

   


    </div>
   
  )
}

export function NuPickerHorario({onChangeHorario = ()=>{}}) {
  // const {user, user_data, getUserDateNurse, updateUserPictue, setUserPicture} = useContext(AuthContext)
  // const {specialization, centerType, professionalField, assistanceUnit, getRating} = useContext(GlobalContext)

  const [state, setState] = useState({
    open:false,
    value:'Todos',
    active:-1
  })



  return (
    <div className=''>

      <div className='nu-picker' onClick={()=>{
        setState({...state, open:!state.open})
      }}>
        <div className='col'>
          <div className='f-14 bold text-gray line-1'>{`Horario - ${state.value}`}</div>
        </div>
       
        
        <div className='nu-icon nu-icon--mid'>
          <MdKeyboardArrowDown size={30}></MdKeyboardArrowDown>
        </div>
      </div>

      <Renderif isTrue={state.open}>
        <div className='nu-picker-options hide-scroll'>

         <div  className={`nu-picker-option  cursor-pointer ${state.active === -1 ? 'nu-picker-option--active':''}`} onClick={()=>{
          setState({...state, open:false, active:-1, value:'Todos'})
          onChangeHorario(-1)
         }}>
            <div className='f-14 bold'>Todos</div>
         </div>
      
         <div  className={`nu-picker-option cursor-pointer ${state.active === 0 ? 'nu-picker-option--active':''}`} onClick={()=>{
          setState({...state, open:false, active:0, value:'Mañana'})
          onChangeHorario(0)
         }}>
            <div className='f-14 bold'>Mañana</div>
         </div>

         <div  className={`nu-picker-option cursor-pointer ${state.active === 2 ? 'nu-picker-option--active':''}`} onClick={()=>{
          setState({...state, open:false, active:2, value:'Tarde'})
          onChangeHorario(2)
         }}>
            <div className='f-14 bold'>Tarde</div>
         </div>

         <div  className={`nu-picker-option cursor-pointer ${state.active === 3 ? 'nu-picker-option--active':''}`} onClick={()=>{
          setState({...state, open:false, active:3, value:'Noche'})
          onChangeHorario(3)
         }}>
            <div className='f-14 bold'>Noche</div>
         </div>


         <div  className={`nu-picker-option cursor-pointer ${state.active === 1 ? 'nu-picker-option--active':''}`} onClick={()=>{
          setState({...state, open:false, active:1, value:'Día 12h'})
          onChangeHorario(1)
         }}>
            <div className='f-14 bold'>Día 12h</div>
         </div>


         <div  className={`nu-picker-option cursor-pointer ${state.active === 1 ? 'nu-picker-option--active':''}`} onClick={()=>{
          setState({...state, open:false, active:1, value:'Guardia 24h'})
          onChangeHorario(4)
         }}>
            <div className='f-14 bold'>Guardia 24h</div>
         </div>
      
   

        </div>
      </Renderif>

   


    </div>
   
  )
}
export function NuPickerProvincia({onChangeProvincias = ()=>{}}) {
  // const {user, user_data, getUserDateNurse, updateUserPictue, setUserPicture} = useContext(AuthContext)
  // const {specialization, centerType, professionalField, assistanceUnit, getRating} = useContext(GlobalContext)

  const [state, setState] = useState({
    open:false,
    value:'Todos',
    active:-1,
    selected:[]
  })


  const provincias = [
    {
        provincia: 'Andalucía',
        colegios: ['Almería', 'Cádiz', 'Córdoba', 'Granada', 'Huelva', 'Jaén', 'Málaga', 'Sevilla']
    },
    {
        provincia: 'Aragón',
        colegios: ['Zaragoza', 'Teruel', 'Huesca']
    },
    {
        provincia: 'Principado de Asturias',
        colegios: ['Asturias']
    },
    {
        provincia: 'Illes Balears',
        colegios: ['Illes Balears']
    },
    {
        provincia: 'Canarias',
        colegios: ['Las Palmas', 'Santa Cruz de Tenerife']
    },
    {
        provincia: 'Cantabria',
        colegios: ['Cantabria']
    },
    {
        provincia: 'Castilla y León',
        colegios: ['Ávila', 'Burgos', 'León', 'Palencia', 'Salamanca', 'Segovia', 'Soria', 'Valladolid', 'Zamora']
    },
    {
        provincia: 'Castilla y la Mancha',
        colegios: ['Albacete', 'Ciudad Real', 'Cuenca', 'Guadalajara y Toledo']
    },
    {
        provincia: 'Cataluña',
        colegios: ['Barcelona', 'Tarragona', 'Girona', 'Lleida']
    },
    {
        provincia: 'Comunidad Valenciana',
        colegios: ['Alicante', 'Valencia', 'Castellón']
    },
    {
        provincia: 'Extremadura',
        colegios: ['Cáceres','Badajoz']
    },
    {
        provincia: 'Galicia',
        colegios: ['A Coruña','Lugo', 'Ourense', 'Pontevedra']
    },
    {
        provincia: 'Murcia',
        colegios: ['Murcia']
    },
    {
        provincia: 'Madrid',
        colegios: ['Madrid']
    },
    {
        provincia: 'Navarra',
        colegios: ['Navarra']
    },
    {
        provincia: 'País Vasco',
        colegios: ['Bizcaia','Gipuzkoa','Araba']
    },
    {
        provincia: 'La Rioja',
        colegios: ['La Rioja']
    },
    {
        provincia: 'Ceuta',
        colegios: ['Ceuta']
    }
    ,
    {
        provincia: 'Melilla',
        colegios: ['Melilla']
    }
    
];


  return (
    <div className=''>

      <div className='nu-picker' onClick={()=>{
        setState({...state, open:!state.open})
      }}>
        <div className='col'>
          <div className='f-14 bold text-gray line-1'>{`Comunidad Autónoma`}</div>
          
        </div>
        <Renderif isTrue={state.selected.length > 0}>
          <div className='nu-badge'>{state.selected.length}</div>
        </Renderif>
       
        
        <div className='nu-icon nu-icon--mid'>
          <MdKeyboardArrowDown size={30}></MdKeyboardArrowDown>
        </div>
      </div>

      <Renderif isTrue={state.open}>
        <div className='nu-picker-options hide-scroll'>

          {
            provincias.map((item, i)=>{
              return (
                <div key={i} className='nu-picker-option'>
                <CheckBox checked={state.selected.some((p) => p.provincia === item.provincia)}  text={item.provincia} onChange={(evt)=>{
      
                  if(evt.value === true){
                
                     
                          
      
                          state.selected.push(item) 
      
                    }else{
                  
                      state.selected = state.selected.filter((p)=> p.provincia !== item.provincia)
                    }
                  setState({...state})
                  onChangeProvincias(state.selected)
           
                 
                  
                }}></CheckBox>
              </div>
              )
            })
          }

       
      
     
   

        </div>
      </Renderif>

   


    </div>
   
  )
}
