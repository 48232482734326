export const profile_data = [
    {
        type:'combo',
        multiple:false,
        title:'Años de experiencia en la profesión',
        description:'Te pediremos que subas el título de enfermería en tu área privada una vez registrada',
        checks:[
            '1-5 años',
            '6-10 años',
            '>10 años',
        ]
    },
    {
        type:'combo',
        multiple:true,
        title:'Especialización EIR',
        description:'Te pediremos que subas el título de enfermería en tu área privada una vez registrada',
        checks:[
            'No tengo formación EIR',
            'Enf. Obstetrico Ginecologica (Matrona)',
            'Enf. de Salud Mental',
            'Enf. Geriartrica',
            'Enf. del Trabajo',
            'Enf. de Cuidados Médico Quirúrgicos',
            'Enf. Familiar y Comunitaria',
            'Enf. Pediátrica'
        ]
    },
    {
        type:'combo',
        multiple:true,
        title:'Escoge los Centros donde tienes interés en realizar turnos',
        description:'',
        checks:[
           'Muéstrame turnos en cualquier centro',
           'Hospital / Clínica / Centro médico',
           'Residencia',
           'Centro Sociosanitario',
           'Centro de día',
           'Laboratorio análisis clínicos',
           'CAP (Centro de atención primaria)',
           'CUAP (Centro de urgencias de atención primaria',
           'CSMIJ (Centro de Salud Mental',
           'Infanto-Juvenil)',
           'Ambulancias: Transporte sanitario urgente',
           'Ambulancias: Transporte sanitario no urgente',
           'Ambulancias: Cobertura sanitaria de eventos',
           'Ambulancias: Traslados privados',
           'Mutua Laboral',
           'Colegio y/o Escuela',
           'Universidad o centro formativo superior',
           'Centro de investigación',
           'Particular'
        ]
    },
    {
        type:'combo',
        multiple:true,
        title:'Escoge las Unidades Asistenciales donde tienes interés en realizar turnos',
        description:'',
        checks:[
           'Muéstrame turnos de cualquier Unidad Asistencial',
           'Hospitalización médica',
           'Hospitalización quirúrgica',
           'Hospitalización a Domicilio',
           'Sala de partos ',
           'UCI',
           'Semi críticos',
           'Urgencias',
           'Quirófano - REA',
           'Diálisis',
           'Unidad Corta Estancia (UCE)',
           'Unidad cirugía ambulatoria (UCA)',
           'Hospital de Día',
           'CCEE',
           'Extracciones',
           'Unidad asistencial no definida', 
        ]
    },
    {
        type:'combo',
        multiple:true,
        title:'En qué ámbitos profesionales quieres realizar turnos',
        description:'',
        checks:[
            'Muéstrame turnos en todos los ámbitos profesionales',
            'Alergología',
            'Cardiología',
            'Cirugía General',
            'Cirugía Vascular',
            'Curas, acompañamiento e inyectables a particulares',
            'Digestivo',
            'Endocrinología y Nutrición',
            'Escolar',
            'Geriatría',
            'Infecciones',
            'Laboral',
            'Nefrología',
            'Neonatos',
            'Neumología',
            'Neurología',
            'Obstetricia y',
            'Ginecología',
            'Oftalmología',
            'Oncología',
            'Ostomías',
            'Otorrinolaringología',
            'Paliativos',
            'Pediatría',
            'Personas con discapacidad',
            'Salud Mental adultos',
            'Salud Mental infantil',
            'Traumatología',
            'Urología'
        ],
        // options:[]
    },
    {
        type:'option',
        multiple:false,
        title:'En qué Unidades Asistenciales tienes experiencia',
        description:'',
        checks:[
            'No tengo experiencia en ninguna Unidad Asistencial'
        ],
        options: [
            {
                key: 'Hospitalización médica',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Hospitalización quirúrgica',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Hospitalización a Domicilio',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Sala de partos',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'UCI',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Semi críticos',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Urgencias',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Quirófano - REA',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Diálisis',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Unidad Corta Estancia (UCE)',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Unidad cirugía ambulatoria (UCA)',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Hospital de Día',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'CCEE',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Extracciones',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            }
        ]
        

    },
    {
        type:'option',
        multiple:false,
        title:'En qué  ámbitos profesionales tienes experiencia',
        description:'',
        checks:[
            'No tengo experiencia en ningún ámbito profesional'
        ],
        options:[
            {
                key: 'Medicina Interna',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Cardiología',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Oncología',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Geriatría',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Neurología',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Neumología',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Nefrología',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Infecciones',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Digestivo',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Endocrinología y Nutrición',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Oftalmología',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Otorrinolaringología',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Alergología',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Salud Mental adultos',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Salud Mental infantil',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Personas con discapacidad',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Paliativos',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Traumatología',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Cirugía Vascular',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Cirugía General',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Urología',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Pediatría',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Neonatos',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Obstetricia y Ginecología',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Ostomías',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Curas',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Curas, acompañamiento e inyectables a particulares.',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Laboral',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            },
            {
                key: 'Escolar',
                values: [
                    'No tengo experiencia',
                    'Menos de 1 año',
                    'Más de 1 año'
                ]
            }
        ]
    },
    {
        type:'combo',
        multiple:true,
        title:'¿Tienes formación tipo máster o posgrado relacionados con las siguientes Unidades Asistenciales?',
        description:'',
        checks:[
            'No tengo formación tipo  máster o posgrado',
            'Hospitalización médica',
            'Hospitalización quirúrgica',
            'Hospitalización a Domicilio',
            'Sala de partos',
            'UCI',
            'Semi críticos',
            'Urgencias',
            'Quirófano - REA',
            'Diálisis',
            'Unidad Corta Estancia (UCE)',
            'Unidad cirugía ambulatoria (UCA)',
            'Hospital de Día',
            'CCEE',
            'Extracciones'
        ]
    },
    {
        type:'combo',
        multiple:true,
        title:'¿Tienes formación tipo máster o posgrado relacionados con los siguientes ámbitos profesionales?',
        description:'Te pediremos una copia del título más adelante',
        checks:[
            'No tengo formación máster o postgrado',
            'Medicina Interna',
            'Cardiología',
            'Oncología',
            'Geriatría',
            'Neurología',
            'Neumología',
            'Nefrología',
            'Infecciones',
            'Digestivo',
            'Endocrinología y Nutrición',
            'Oftalmología',
            'Otorrinolaringología',
            'Alergología',
            'Salud Mental adultos',
            'Salud Mental infantil',
            'Personas con discapacidad',
            'Paliativos',
            'Traumatología',
            'Cirugía Vascular',
            'Cirugía General',
            'Urología',
            'Pediatría',
            'Neonatos',
            'Obstetricia y Ginecología',
            'Ostomías',
            'Curas',
            'Curas, acompañamiento e inyectables a particulares',
            'Laboral',
            'Escolar'
        ]
    },

    
]