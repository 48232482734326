import React, { createContext, useState } from 'react'


export const NotificationContext = createContext();

export default function NotificationProvider({children}) {

    const [state, setState] = useState({
    
        notifications:[]
    })

    const onClose = (notification) => {
        state.notifications.shift(notification);
        setState({...state})
    }

    const notify = ({title, message, type, onAccept = null}) => {

      
    
        let notification = {
            title,
            message,
            type,
            onAccept,
            onClose
         
        } 
        state.notifications.unshift(notification);
        setState({...state})

        if(type !== 'confirmation'){

            setTimeout(()=>{
                state.notifications.shift(notification);
                setState({...state})
            },4000)
        }
       
   
    }

    



  return (
    
    <NotificationContext.Provider value={{
        notifications:state.notifications,
        notify,
        onClose
    }}>
            {children}
    </NotificationContext.Provider>
  )
}
