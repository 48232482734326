import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdStar } from 'react-icons/io'
import NuLoading from '../../components/nu_loading'
import NuRating from '../../components/nu_rating'
import { AuthContext } from '../../context/auth_context'
import { GlobalContext } from '../../context/global_context'
import { NotificationContext } from '../../context/notification_context'
import { Service } from '../../services/api'
import { dMY, timeOfDay, UTCDateParser } from '../../utils'
import Renderif from '../../utils/renderif'
import TurnoConfirmationView from './turno_confirmation_view'

export default function TurnoDetailView({active, turno, onClose= ()=>{}, onAssing = ()=>{}}) {
    const {
        renderTitle, 
        getCenter, 
        getAssistanceUnit,
        getProfessionalField,
        getSpecialization,
        getCenterType
    } = useContext(GlobalContext)
    const {notify} = useContext(NotificationContext)

    const {user, user_data} = useContext(AuthContext)

    const [state, setState] = useState({
        loading:false,
        showConfirmationLetter:false,
        rated:false,
        rate:1, 
        rateComment:'',
        organization:null
    })
    
    
  
    useEffect(() => {

        const init = async () => {

            let _organization = await Service('GET', `organizationData/getById/${turno?.idOrganization}`)
            console.log(_organization)
            state.organization = _organization.data.organizationData;

         if(turno){
            console.log("AAA")
            // let _organization = await Service('GET', `organizationData/getById/${turno.idOrganization}`)
            let _valoraciones = await Service('GET', 'rating/getAll');
            console.log(_valoraciones);
            let  _rated = await Service('GET', `rating/checkIfExist?type=1&idCenter=${turno?.idCenter}&idUser=${turno?.idUser}&idShift=${turno?.id}`)
            console.log(_rated.data);
        
            

            setState({...state, rated:_rated.data.exist, organization:_organization.data.organizationData})
         }else{
            setState({...state})
         }
        }
        init();
        return () => {}
    },[turno])


    const onAssingTurno = async () => {
        

        setState({...state, loading:true})
      
        let _assign = await Service('PUT', `shift/take/${turno.id}/1`);
        if(_assign.status === 201){
            onAssing(true)
        }else{
            onAssing(false)
        }
        setState({...state, loading:false, showConfirmationLetter:false})
        

    }
    const checkCompleted = () => {
    
        let _today = new Date();
        var end_turno = parseInt(turno?.initialHour.split(':')[0])
        var today = new Date(Date.UTC(_today.getFullYear(), _today.getMonth(), _today.getDate(), 0,0,0)).getTime()
        let _date = new Date(turno?.date).getTime()

        if(today > _date){
            return true
        }
        if(today < _date){
            return false;
        }
        if(today === _date){
            if(_today.getHours() > end_turno){
                return true
            }
            return false;
        }
    }
    const onRateTurno = async() => {
  
        setState({...state, loading:true})

        let body = {
            type: '1',
            //Propiedad type es un enum, solo acepta los valores "0" y "1"
            //"0" - Valoración del centro al nurse/tcae/student
            //"1" - Valoración del nurse/tcae/student al centro
            idCenter: turno.idCenter,
            idUser: user.id,
            idShift: turno.id,
            stars: state.rate, //Min 0, Max 5
            comment: state.rateComment
        }

        let _rate = await Service('POST', 'rating/create', body)
        console.log(_rate)
       
        if(_rate.status === 201){
            notify({title:'¡Listo!', message:'Valoración enviada.', type:'success'})
            onClose();
        }else{
            notify({title:'Error!', message:_rate?.response?.data?.error, type:'error'})
            onClose();
        }
    
        setState({...state, loading:false, rated:true})
      

        

    }

  return (
    <>
     <Renderif isTrue={state.loading}>
        <NuLoading />
    </Renderif>
    <Renderif isTrue={state.showConfirmationLetter}>
        <TurnoConfirmationView 
            turno={turno}
            onAccept={(evt)=>{
                onAssingTurno();
            }} 
            onClose={(evt)=>{
                setState({...state, showConfirmationLetter:false})
            }} 
        />
    </Renderif>
    <div className={`nu-turno-detail hide-scroll ${active ? 'nu-turno-detail--active':''}`} onClick={()=>{
        // setState({...state, show_turno_detail:false })
        onClose()
    }}>
    
        <div className='nu-turno-content hide-scroll h-100' onClick={(evt)=>{evt.stopPropagation()}}>
            <div className='d-flex'>
                <div>
                    <div className='f-22 bold'>
                        {getCenter(turno?.idCenter)?.name}
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className='nu-icon nu-icon--mid'>
                            <IoMdStar color='#FFB054' size={24}></IoMdStar>
                        </div>
                        <div className='f-14 bold'>5.0 (0 Valoraciones)</div>
                    </div>
                </div>
                <div className='col'></div>
                
                <div>
                <div className='nu-icon nu-icon--mid r-100 bg-darkblue m-1 cursor-pointer' onClick={()=>{
                    // setState({...state, show_turno_detail:false})
                    onClose()
                }}>
                    <IoMdClose color='white' size={16}></IoMdClose>
                </div>
                </div>
            </div>

            <div className='mt-3'>
                <div className='nu-turno-detail-image' style={{backgroundImage:'url(../assets/img_clinic.png)'}}></div>
                {/* <img  width="100%" height={150} className='r-10' src='https://picsum.photos/300/120'></img> */}
            </div>
            
            <div className='mt-3'>
                <div className={`nu_btn nu_btn--${turno?.status == '1' || checkCompleted() ? 'disabled' : 'darkgreen'} w-100`} onClick={() => {

                    if(user_data.status !== "1"){
                        notify({title:'Lo sentimos!', message:'Tu perfil no ha sido validado.', type:"error"})
                        return;
                    }
                 
                    if(turno?.status === '0' && !checkCompleted()){
                        setState({...state, showConfirmationLetter:true})
                    }

                }}>
                    <div className='nu_btn-text'>Asignarme el turno</div>
                </div>
            </div>

            <div className='mt-3'>
                    <div className='f-22 bold'>
                   
                </div>
            </div>

                <div className='d-flex mt-3'>

                    <div className='me-3'>
                        <div className='d-flex mt-0'>
                            <div className='nu-icon nu-icon--mid'>
                                <img width="80%" src='/assets/calendar_solid.svg'></img>
                            </div>
                            <div className='ps-2'>
                                <div className='f-14 bold'>Fecha</div>
                                <div className='f-12'>{UTCDateParser(new Date(turno?.date).toUTCString(), true)}</div>
                            </div>
                        </div>

                        <div className='d-flex mt-3'>
                            <div className='nu-icon nu-icon--mid'>
                                <img width="80%" src='/assets/clock_solid.svg'></img>
                            </div>
                            <div className='ps-2'>
                                <div className='f-14 bold'>Hora de inicio</div>
                                <div className='f-12 '>{turno?.initialHour} hrs.</div>
                                
                            </div>
                        </div>
                    </div>

                    <div className='ms-3'>

                        <div className='d-flex mt-0'>
                            <div className='nu-icon nu-icon--mid'>
                                <img width="80%" src='/assets/calendar_clock_solid.svg'></img>
                            </div>
                            <div className='ps-2'>
                                <div className='f-14  bold'>Turno de</div>
                                <div className='f-12 '>{timeOfDay(turno?.timeOfDay)}</div>
                            </div>
                        </div>

                        <div className='d-flex mt-3'>
                            <div className='nu-icon nu-icon--mid'>
                                <img width="65%" src='/assets/euro_symbol_solid.svg'></img>
                            </div>
                            <div className='ps-2'>
                                <div className='f-14 bold'>{turno?.remuneration}</div>
                                <div className='f-12 opacity-0'>empty</div>
                            
                            </div>
                        </div>

                    </div>
                </div>

            <div className='mt-3'>

        
                <div className='mt-3'>
                    <div className='f-18 bold mb-2'> Detalle del  turno</div>
                    <div className='f-14'><span className='bold'>Organización</span>: {state.organization?.name}</div>
                    <div className='f-14'><span className='bold'>Nombre del centro</span>: {getCenter(turno?.idCenter)?.name}</div>
                    <div className='f-14'><span className='bold'>Tipo de centro</span>: {getCenterType(turno?.idCenterType)}</div>
                    <div className='f-14'><span className='bold'>Inicio del turno</span>: {turno?.initialHour} hrs.</div>
                    <div className='f-14'><span className='bold'>Fin del turno</span>: {turno?.finalHour} hrs.</div>
                    <Renderif isTrue={turno?.idAssistanceUnit !== null}>
                        <div className='f-14'><span className='bold'>Unidad Asistencial</span>: {getAssistanceUnit(turno?.idAssistanceUnit)}</div>
                    </Renderif>
                 
                    <div className='f-14'><span className='bold'>Ámbito Profesional</span>: {getProfessionalField(turno?.idProfessionalField)}</div>
                </div>

                {turno?.description && (
                    <div className='mt-3'>
                        <div className='f-18 bold mb-2'>Descripción del turno</div>
                        <div className='f-14'>{turno?.description}</div>
                    </div>
                )}

                <div className='mt-3'>
                    <div className='f-18 bold mb-2'>Información del centro</div>
                    {/* <div className='f-14'><span className='bold'>Parking</span>: Texto Libre</div>
                    <div className='f-14'><span className='bold'>Dietas</span>: Texto Libre</div>
                    <div className='f-14'><span className='bold'>Uniforme</span>: Texto Libre</div>
                    <div className='f-14'><span className='bold'>Punto de encuentro</span>: Texto Libre</div> */}
                     {/* <div className='f-14'><span className='bold'>Notas</span>: {getCenter(turno?.idCenter)?.description}</div> */}
                    <div className='f-14'><span className='bold'>Dirección</span>: {getCenter(turno?.idCenter)?.address}</div>
                    <div className='f-14'><span className='bold'>Código postal</span>: {getCenter(turno?.idCenter)?.postalCode}</div>
              
                    <div className='turno-info-center' dangerouslySetInnerHTML={{__html: getCenter(turno?.idCenter)?.description}}></div>
                </div>
                <Renderif isTrue={checkCompleted() && turno?.idUser === user.id && state.rated === true}>
                    <div className='mt-3'>
                        <div className='f-12 bold text-darkblue'>Valoración enviada.</div>
                    </div>
                </Renderif>
                {/* <Renderif isTrue={2==2}> */}
                <Renderif isTrue={checkCompleted() && turno?.idUser === user.id && !state.rated}>
                    
                    <div className='mt-3'>
                        <div className='f-18 bold'>Valorar turno</div>
                        <div className='mt-2'>
                                <NuRating value={0} onChange={(value)=> {
                                        setState({...state, rate:value})

                                }}/>

                                <textarea className='nu-input mt-3' onChange={(evt)=>{
                                    setState({...state, rateComment:evt.currentTarget.value})
                                }}></textarea>

                                <div className={`nu_btn nu_btn--primary mt-1`} onClick={(evt) => {
                                     onRateTurno()
                                }}>
                                    <div className='nu_btn-text'>Enviar</div>
                                </div>
                        </div>
                    </div>
                </Renderif>

            </div>
        </div>
    </div>
    </>
  )
}
