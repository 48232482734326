import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { IoIosSearch } from "react-icons/io";
import { FaRegStar } from "react-icons/fa";
import { ProfesionalSideMenu } from '../../components/nu_sidemenu';
import { AuthContext } from '../../context/auth_context';
import { Service } from '../../services/api';
import { NuTurnoItem } from '../../components/nu_turno_item';
import TurnoDetailView from '../commun/turno_detail_view';
import { months } from '../../utils';
import { GlobalContext } from '../../context/global_context';
import { BsThreeDotsVertical } from 'react-icons/bs';
export default function TurnosProfesionalView() {
   
    const {user, user_data} = useContext(AuthContext)
    const [state, setState] = useState({
        show_menu:false,
        show_turno_detail:false,
        turnos:[],
        turnoSelected:null,
        tab:-1
    })
    const {getRating} = useContext(GlobalContext)

    

    useEffect(()=>{
        const init = async () => {
            let _turnos = await Service('GET', `shift/getAll?idUser=${user_data?.id}`) 
   

            setState({
                ...state, 
                turnos: _turnos.data.list.sort(function(a,b){return a.date - b.date}).reverse()
                
            })
         
        };
        init();
        return () => {}
    },[])

    const getTotalRemuneration = () => {
        let remuneration = 0;
        state.turnos.map((item)=> {
            remuneration = remuneration + parseFloat(item.remuneration)
        })
        return remuneration.toFixed(2);
    }

    const filter = (data) => {

        if(state.tab === -1){
            return data;
        }

        switch (state.tab) {
            case 0:
                return data.filter((item)=> !checkCompleted(item))
            case 1:
                return data.filter((item)=> checkCompleted(item))
        
            default:
                return data;
        }
        

        return data.filter;

    }

    const checkCompleted = (item) => {
    
        let _today = new Date();
        var end_turno = parseInt(item.initialHour.split(':')[0])
        var today = new Date(Date.UTC(_today.getFullYear(), _today.getMonth(), _today.getDate(), 0,0,0)).getTime()
        let _date = new Date(item?.date).getTime()

        if(today > _date){
            return true
        }
        if(today < _date){
            return false;
        }
        if(today === _date){
            if(_today.getHours() > end_turno){
                return true
            }
            return false;
        }
    }


  return (
    <div className='container-fluid overflow-hidden d-flex h-100'>


        <ProfesionalSideMenu onClose={()=>{setState({...state, show_menu:false})}} show={state.show_menu} page='turnos'/>
   


        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-1 pt-md-3 px-1 px-md-3'>
     

                <div className='nu-snack'>
                    <div>
                            <div className='f-22 text-white bold'>Hola, {user?.name}</div>
                            <div className='d-flex align-items-center'>
                                <div className='nu-icon nu-icon--mid'>
                                    <IoMdStar color='#FFB054' size={24}></IoMdStar>
                                </div>

                                <div className='f-16 text-white'>{getRating().rate} ( {getRating().valoraciones} reseñas)</div>

                            </div>
                        
                    </div>
                    <div className='col'></div>
                    <div className='avatar avatar--mid' style={{backgroundImage: `url(${user?.picture})`}}></div>
                    <div className='nu-snack-overlay'></div>
                    <div className='ms-2 d-block d-sm-none'>
                        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                            setState({...state, show_menu:true})
                        }}>
                            <BsThreeDotsVertical size={50} color='white'></BsThreeDotsVertical>
                        </div>
                    </div>
                </div>
                       
            </div>

         

            <div className='container-fluid container-xl mx-auto d-flex p-1 p-md-3 bg-white '>

           
                
                <div className='col '>

                    <div className='bg-white sticky-top-header py-2 px-2 '>
                        
                        <div className='d-flex align-items-center'>

                           <div className=''>
                                <div className='f-22 bold'>{months[new Date().getMonth()]} {new Date().getFullYear()}</div>
                                
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className='nu-icon'>
                                        <img width="30px" src='/assets/calendar_clock_solid.svg'></img>
                                    </div>
                                    <div className='f-14 bold px-2'>Total de turnos asignados: {state.turnos.length}</div>
                                    <div className='nu-icon ms-3'>
                                        <img width="30px" src='/assets/euro_symbol_solid.svg'></img>
                                    </div>
                                    <div className='f-14 bold px-1'>Tus Ingresos:</div>
                                    <div className='f-18 bold px-2'>{getTotalRemuneration()}</div>

                                </div>

                           </div>
                    

                            <div className='col'></div>

                            <div className='d-none d-md-flex align-items-end'>

                                <div className='dot dot--lightblue'></div>
                                <div className='f-12 bold'>Completados</div>

                                {/* <div className='dot dot--orange'></div>
                                <div className='f-12 bold'>Acogida</div> */}

                                <div className='dot dot--darkgreen'></div>
                                <div className='f-12 bold'>Asignado</div>
                        
                            </div>

                            {/* <div className='d-flex'>
                                <div className='nu-icon nu-icon--big  cursor-pointer'><SlArrowLeft size={20} /></div>
                                <div className='nu-icon nu-icon--big  cursor-pointer'><SlArrowRight size={20}  /></div>
                            </div> */}
                        </div>

                        <div className='d-flex d-md-none align-items-end mt-2'>
                            <div className='dot dot--lightblue'></div>
                            <div className='f-12 bold'>Completados</div>

                            {/* <div className='dot dot--orange'></div>
                            <div className='f-12 bold'>Acogida</div> */}

                            <div className='dot dot--darkgreen'></div>
                            <div className='f-12 bold'>Asignado</div>
                        </div>

                        <div className='d-flex mt-4'>
                                <div className={`nu-tab nu-tab--${state.tab === -1 ? 'active':''} me-2`} onClick={()=>{setState({...state, tab:-1})}}>Todos</div>
                                <div className={`nu-tab nu-tab--${state.tab === 0 ? 'active':''} me-2`} onClick={()=>{setState({...state, tab:0})}}>Asignados</div>
                                <div className={`nu-tab nu-tab--${state.tab === 1 ? 'active':''} me-2`} onClick={()=>{setState({...state, tab:1})}}>Completados</div>
                           
                          
                       
                           
                        </div>

                    </div>

                    <div className=''>

                        {
                            filter(state.turnos).map((item,i)=>{
                                return (
                                    <NuTurnoItem item={item} key={i} onClick={()=>{
                                        setState({...state, show_turno_detail:true, turnoSelected:item})
                                    }}></NuTurnoItem>
                                )
                            })
                        }

                   
{/* 
                        <div className='nu-turno-item'>

                            <div className='f-20 bold'>Turno de acogida/cobertura en CUAP Sagrera</div>
                            <div className='d-flex align-items-end mt-3'>
                                <div className='d-flex align-items-center pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <img width="70%" src='/assets/calendar_solid.svg'></img>
                                        </div>
                                        <div className='f-12 bold ellipsis'>24 Febrero 2024</div>
                                </div>

                                <div className='d-flex align-items-center  pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <img width="70%" src='/assets/calendar_clock_solid.svg'></img>
                                        </div>
                                        <div className='f-12 bold ellipsis'>Mañana</div>
                                </div>

                                <div className='d-flex align-items-center pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <img width="70%" src='/assets/clock_solid.svg'></img>
                                        </div>
                                        <div className='f-12 bold ellipsis'>12:00 AM</div>
                                </div>

                                <div className='d-flex align-items-center  pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <img width="70%" src='/assets/euro_symbol_solid.svg'></img>
                                        </div>
                                        <div className='f-12 bold ellipsis'>200.00</div>
                                </div>

                                <div className='col'></div>
                                <div className='nu_btn nu_btn--orange ps-3'>

                                    <div className='nu-icon nu-icon--mid'>
                                        <FaRegStar size={20}></FaRegStar>
                                    </div>

                                    <div className='nu_btn-text ps-2'>Valorar</div>
                                </div>
                            </div>



                        </div>

                        <div className='nu-turno-item nu-turno-item--darkgreen'>

                            <div className='f-20 bold'>Turno de acogida/cobertura en CUAP Sagrera</div>
                            <div className='d-flex align-items-end  mt-3'>
                                <div className='d-flex align-items-center pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <img width="70%" src='/assets/calendar_solid.svg'></img>
                                        </div>
                                        <div className='f-12 bold ellipsis'>24 Febrero 2024</div>
                                </div>

                                <div className='d-flex align-items-center  pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <img width="70%" src='/assets/calendar_clock_solid.svg'></img>
                                        </div>
                                        <div className='f-12 bold ellipsis'>Mañana</div>
                                </div>

                                <div className='d-flex align-items-center pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <img width="70%" src='/assets/clock_solid.svg'></img>
                                        </div>
                                        <div className='f-12 bold ellipsis'>12:00 AM</div>
                                </div>

                                <div className='d-flex align-items-center  pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <img width="70%" src='/assets/euro_symbol_solid.svg'></img>
                                        </div>
                                        <div className='f-12 bold ellipsis'>200.00</div>
                                </div>

                                <div className='col'></div>
                                <div className='nu_btn nu_btn--orange ps-3'>

                                    <div className='nu-icon nu-icon--mid'>
                                        <FaRegStar size={20}></FaRegStar>
                                    </div>

                                    <div className='nu_btn-text ps-2'>Valorar</div>
                                </div>
                            </div>



                        </div>

                        <div className='nu-turno-item nu-turno-item--orange'>

                            <div className='f-20 bold'>Turno de acogida/cobertura en CUAP Sagrera</div>
                            <div className='d-flex align-items-end  mt-3'>
                                <div className='d-flex align-items-center pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <img width="70%" src='/assets/calendar_solid.svg'></img>
                                        </div>
                                        <div className='f-12 bold ellipsis'>24 Febrero 2024</div>
                                </div>

                                <div className='d-flex align-items-center  pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <img width="70%" src='/assets/calendar_clock_solid.svg'></img>
                                        </div>
                                        <div className='f-12 bold ellipsis'>Mañana</div>
                                </div>

                                <div className='d-flex align-items-center pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <img width="70%" src='/assets/clock_solid.svg'></img>
                                        </div>
                                        <div className='f-12 bold ellipsis'>12:00 AM</div>
                                </div>

                                <div className='d-flex align-items-center  pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <img width="70%" src='/assets/euro_symbol_solid.svg'></img>
                                        </div>
                                        <div className='f-12 bold ellipsis'>200.00</div>
                                </div>

                                <div className='col'></div>
                                <div className='nu_btn nu_btn--orange ps-3'>

                                    <div className='nu-icon nu-icon--mid'>
                                        <FaRegStar size={20}></FaRegStar>
                                    </div>

                                    <div className='nu_btn-text ps-2'>Valorar</div>
                                </div>
                            </div>



                        </div>

                        <div className='nu-turno-item nu-turno-item--lightblue'>

                            <div className='f-20 bold'>Turno de acogida/cobertura en CUAP Sagrera</div>

                            <div className='d-flex align-items-end  mt-3'>
                                <div className='d-flex align-items-center pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <img width="70%" src='/assets/calendar_solid.svg'></img>
                                        </div>
                                        <div className='f-12 bold ellipsis'>24 Febrero 2024</div>
                                </div>

                                <div className='d-flex align-items-center  pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <img width="70%" src='/assets/calendar_clock_solid.svg'></img>
                                        </div>
                                        <div className='f-12 bold ellipsis'>Mañana</div>
                                </div>

                                <div className='d-flex align-items-center pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <img width="70%" src='/assets/clock_solid.svg'></img>
                                        </div>
                                        <div className='f-12 bold ellipsis'>12:00 AM</div>
                                </div>

                                <div className='d-flex align-items-center  pe-3'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <img width="70%" src='/assets/euro_symbol_solid.svg'></img>
                                        </div>
                                        <div className='f-12 bold ellipsis'>200.00</div>
                                </div>

                                <div className='col'></div>
                                <div className='nu_btn nu_btn--orange ps-3'>

                                    <div className='nu-icon nu-icon--mid'>
                                        <FaRegStar size={20}></FaRegStar>
                                    </div>

                                    <div className='nu_btn-text ps-2'>Valorar</div>
                                </div>
                            </div>



                        </div> */}


                    </div>

                   
                </div>

            </div>
      
        </div>

   
        <TurnoDetailView active = {state.show_turno_detail} turno={state.turnoSelected} onClose={()=>{
            setState({...state, show_turno_detail:false})
        }}></TurnoDetailView>
  
     
    </div>
  )
}
