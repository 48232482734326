import React, { useState } from "react";

const Tooltip = ({ children, content }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="tooltip-wrapper">
      <div
        className="tooltip-target"
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        {children}
      </div>
      {show && <div className="tooltip-content">{content}</div>}
    </div>
  );
};

export default Tooltip;
