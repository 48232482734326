import React, { useState } from 'react'
import { GoStarFill } from "react-icons/go";
import { GoStar } from "react-icons/go";
import Renderif from '../utils/renderif';

export default function NuRating({value = 0, onChange = () =>{}}) {

    const [state, setState] = useState({
        value
    })
  return (
     <div className='d-flex'>

        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
            onChange(1)
            setState({...state, value:1})
        }} >

            <Renderif isTrue={state.value > 0}>
                <GoStarFill size={30} color='#FFA800'></GoStarFill>
            </Renderif>
            <Renderif isTrue={state.value < 1}>
                <GoStar size={30} color='#CCC'></GoStar>
            </Renderif>
           
        </div>

        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
               onChange(2)
            setState({...state, value:2})
        }}>
             <Renderif isTrue={state.value > 1}>
                <GoStarFill size={30} color='#FFA800'></GoStarFill>
            </Renderif>
            <Renderif isTrue={state.value < 2}>
                <GoStar size={30} color='#CCC'></GoStar>
            </Renderif>
        </div>

        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
               onChange(3)
            setState({...state, value:3})
        }}>
             <Renderif isTrue={state.value > 2}>
                <GoStarFill size={30} color='#FFA800'></GoStarFill>
            </Renderif>
            <Renderif isTrue={state.value < 3}>
                <GoStar size={30} color='#CCC'></GoStar>
            </Renderif>
        </div>

        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                onChange(4)
            setState({...state, value:4})
        }}>
            <Renderif isTrue={state.value > 3}>
                <GoStarFill size={30} color='#FFA800'></GoStarFill>
            </Renderif>
            <Renderif isTrue={state.value < 4}>
                <GoStar size={30} color='#CCC'></GoStar>
            </Renderif>
        </div>

        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
               onChange(5)
            setState({...state, value:5})
        }}>
            <Renderif isTrue={state.value > 4}>
                <GoStarFill size={30} color='#FFA800'></GoStarFill>
            </Renderif>
            <Renderif isTrue={state.value < 5}>
                <GoStar size={30} color='#CCC'></GoStar>
            </Renderif>
        </div>
    </div>
  )
}
