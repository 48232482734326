import React, { Children, useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { FaStar } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { AdminSideMenu, ClinicSideMenu, ProfesionalSideMenu } from '../../components/nu_sidemenu';
import { MdOutlinePersonOff } from "react-icons/md";
import NuSelect from '../../components/nu_select';
import { IoClose } from 'react-icons/io5';
import { AdminContext } from '../../context/admin_context';
import { useLocation, useNavigate } from 'react-router-dom';
import Renderif from '../../utils/renderif';
import NuLoading from '../../components/nu_loading';
import { Service } from '../../services/api';
import { NotificationContext } from '../../context/notification_context';
import { BiFilter, BiSearch, BiTrash } from 'react-icons/bi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { darkBlue } from '../../styles/colors';
export default function ProfesionalesAdminView() {
    const location = useLocation();
    let view = location?.state;
    console.log(view);

    const {professionals, loadProfessionals, getProfesionByUserType} = useContext(AdminContext);
    const {notify} = useContext(NotificationContext);
    const navigate = useNavigate();
    const [state, setState] = useState({
        filters:view !== null ? '3' : '',
        filterUserType: '',
        page:1,
        count:7,
        professionals:[],
        professionalsCount:0,
        search:''
    })

    const status = [
        'Pendientes de validar',
        'Perfil validado',
        'En revisión',
        'Bloqueados'
    ]

    const type = [
        '',
        'Enfermeria',
        '',
        'TCAE',
     
    ]
    



    // useEffect(()=>{
    //     let _aux_state = {
    //         filters: state.filters,
    //         filterUserType: state.filterUserType,
    //         page:state.page,
    //         search:state.search
    //     }
    //     localStorage.setItem('profesionals_state', JSON.stringify(_aux_state))

    // },[state])


    const save_state = () =>{
        let _aux_state = {
            filters: state.filters,
            filterUserType: state.filterUserType,
            page:state.page,
            search:state.search
        }
        localStorage.setItem('profesionals_state', JSON.stringify(_aux_state))
    }




    useEffect(()=>{
        
        let _save_state = JSON.parse(localStorage.getItem('profesionals_state'))

        console.log(_save_state)

        if(_save_state !== null){
            state.filters = _save_state.filters
            state.filterUserType = _save_state.filterUserType
            state.page = _save_state.page
            state.search = _save_state.search
        }

        // const init = async () => {
        //     console.log('INIT')
        //     //loadProfessionals({page:1}
          
        //     setState({...state, loading:true})
        //     let _profesionals = await loadProfessionals({page:state.page, count:state.count, status:state.filters})
        //     state.professionals = _profesionals.data.list
        //     state.professionalsCount = _profesionals.data.total
        //     setState({...state, loading:false})
        // }
        init();
        return () => {}
    },[])

    // useEffect(()=>{

       
    //     init()
    // },[state.filters ])

    const init = async () => {
            
       
        setState({...state, loading:true})
        let _profesionals = await loadProfessionals({page:state.page, count:state.count, status:state.filters, search:state.search, type:state.filterUserType })
        state.professionals = _profesionals.data.list
        state.professionalsCount = _profesionals.data.total
        setState({...state, loading:false, page:state.page})
       
     

    }

    const loadPage = async (page) => {
        setState({...state, loading:true})
        let _profesionals = await loadProfessionals({page:state.page + page, count:state.count, status:state.filters, search:state.search, type:state.filterUserType})
        state.page = state.page + page
        setState({...state, 
            // page:state.page + page,
            loading:false, 
            professionals:_profesionals.data.list, 
            professionalsCount:_profesionals.data.total,
    
        })
         save_state()
    }

    

    const renderPagination = () => {


        let pages = Math.ceil(state.professionalsCount / state.count);
        let items = []
        for (let i = 1; i <= pages; i++) {
            items.push(i);
        }

        return (
            <div>
               <span className='bold'>{state.page}</span> de <span className='bold'>{items.length}</span>
            </div>
        )

        // items.reverse()

        return items.map((item) => {
           
            return (
                <div style={{width:32}} className={`f-16 bold text-dark px-2 cursor-pointer mb-1 r-10 text-center ${state.page === item ? 'bg-light':'' }`} onClick={async ()=>{
                    
                    setState({...state, loading:true})
                    let _profesionals = await loadProfessionals({page:item, count:state.count, status:state.filters})

                    setState({...state, 
                        page:item,
                        loading:false, 
                        professionals:_profesionals.data.list, 
                        professionalsCount:_profesionals.data.total,
                
                    })
                }}>{item}</div>
            )
        })
        
        


     

    }

    const onBlockProfesional = async  (idUser) => {
        let body = {
            idUser
        }
        let _block = await Service ('PUT', `user/block/${idUser}`)
        if(_block.status === 201){
            notify({title:'¡Listo!', message:'Profesional bloqueado', type:'success'})

            setState({...state, loading:true})
            let _profesionals = await loadProfessionals({page:state.page, count:state.count, status:state.filters})
            state.professionals = _profesionals.data.list
            state.professionalsCount = _profesionals.data.total
            setState({...state, loading:false})


        }else{
            notify({title:'Erorr!', message:'No se ha podiado bloquear este profesional.', type:'error'})
        }
    }

    const onUnblockProfesional = async  (idUser) => {
        let body = {
            idUser
        }
        let _block = await Service ('PUT', `user/unblock/${idUser}`)
        if(_block.status === 201){
            notify({title:'¡Listo!', message:'Profesional desbloqueado', type:'success'})

            setState({...state, loading:true})
            let _profesionals = await loadProfessionals({page:state.page, count:state.count, status:state.filters})
            state.professionals = _profesionals.data.list
            state.professionalsCount = _profesionals.data.total
            setState({...state, loading:false})


        }else{
            notify({title:'Erorr!', message:'No se ha podiado desbloquear este profesional.', type:'error'})
        }
    }
    const onDeleteUser = (user) => {
        console.log(user)

        notify({title:'Eliminar usuario', message:'¿Deseas eliminar este usuario?', type:'confirmation', onAccept: async (value) => {
            if(value){
                setState({...state, loading:true})
                let _delete = await Service('DELETE', `user/${user.id}`);
                console.log(_delete);
                init()
                // init();
                // state.turnos = state.turnos.filter((turno) => turno.id !== item.id);
                // setState({...state, loading:false})
            }
        }})
    }
    const onSearch = async () => {
       
            setState({...state, loading:true})
            let _profesionals = await loadProfessionals({page:1, count:state.count, status:state.filters, search:state.search, type:state.filterUserType })
            state.professionals = _profesionals.data.list
            state.professionalsCount = _profesionals.data.total
            state.page = 1
            setState({...state, loading:false})
            save_state()
            

    }

  return (
    <>
    <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
    </Renderif>
    <div className='container-fluid overflow-hidden d-flex h-100'>

        <AdminSideMenu page='profesionales' />
   
        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-3 px-3'>
     

                <div className='nu-snack'>
                <div>
                    <div className='f-22 text-white bold'>Hola, Admin</div>
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid' style={{backgroundImage: 'url(/assets/avatar.png)'}}></div>
                    <div className='nu-snack-overlay'></div>
                </div>
                       
            </div>

            <div className='container-fluid container-xl mx-auto p-3'>


          

                <div className='f-24  bold mt-2'>Profesionales</div>

                <div className='d-flex align-items-end mt-3'>

                    {/* <div className='f-16 bold'>Filtrar por: </div> */}

                    <div className='me-2'>
                        <div className='f-14'>Status</div>
                       <NuSelect value={state.filters} onChange={(evt)=>{
                      
                                  
                                state.filters = evt.currentTarget.value
                                setState({...state})
                      
                       }}>
                            <option value="">Todos</option>
                            <option value="0">Pendiente de validar</option>
                            <option value="2">En revisión</option>
                            <option value="1">Perfil Validado</option>
                            <option value="3">Bloqueados</option>
                            {/* <option value={"Profesional bloqueado"}>Profesional bloqueado</option> */}
                       </NuSelect>
                    </div>

                    <div className='me-2'>
                        <div className='f-14'>Tipo</div>
                       <NuSelect value={state.filterUserType} onChange={(evt)=>{
                      
                                  
                                state.filterUserType = evt.currentTarget.value
                                setState({...state})
                      
                       }}>
                            <option value="">Todos</option>
                            <option value="1">Enfermeria</option>
                            <option value="3">TCAE</option>
                  
                       </NuSelect>
                    </div>

                  
                    <div className='col-5'>
                        <div className='f-14'>Nombre</div>
                       <input className="nu-input--light p-2" value={state.search} placeholder='Busqueda por nombre. Ej. Roberto' onChange={(evt)=>{
                        setState({...state, search:evt.currentTarget.value})
                       }}></input>
                    </div>

                    

                    <div className='col d-flex'>
                       
                            <div className='nu_btn nu_btn--primary ms-2 py-2' onClick={()=>{
                                    // onDeleteUser(item)
                                    onSearch()
                                    
                                }}>

                                <div className='nu-icon nu-icon--mid'>
                                    <BiSearch size={20}></BiSearch>
                                </div>

                                <div className='nu_btn-text '>Buscar</div>
                            </div>

                            <Renderif isTrue={state.search !== '' || state.filters !== '' || state.filterUserType !== ''}>
                                <div className='nu_btn ms-2 py-2' onClick={()=>{
                                        state.search = ''
                                        state.filters = ''
                                        state.filterUserType = ''
                                        // state.page = 1
                                        onSearch()
                                        
                                    }}>

                                    <div className='nu-icon nu-icon--mid'>
                                        <BiFilter size={20}></BiFilter>
                                    </div>

                                    <div className='nu_btn-text '>Limpiar Filtro</div>
                                </div>
                            </Renderif>
                            
                      
                    </div>
                


                    {/* <div className='col'></div> */}

                </div>
                
                {/* <div className='d-flex mt-2'>
                    <Renderif isTrue={state.filters !== ''}>
                        <div className='nu-tag'>
                            <div className='f-16'>{status[state.filters]}</div>
                            <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                                // setState({...state, filters:''})
                                state.filters = ''
                                onSearch();
                                }}>
                                <IoClose size={25}></IoClose>
                            </div>
                        </div>
                    </Renderif>
                    <Renderif isTrue={state.filterUserType !== ''}>
                        <div className='nu-tag'>
                            <div className='f-16'>{type[state.filterUserType]}</div>
                            <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                                state.filterUserType = ''
                                onSearch();
                            }}>
                                <IoClose size={25}></IoClose>
                            </div>
                        </div>
                    </Renderif>
                    <Renderif isTrue={state.search !== ''}>
                        <div className='nu-tag'>
                            <div className='f-16'>{state.search}</div>
                            <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                                state.search = ''
                                onSearch()
                            }}>
                                <IoClose size={25}></IoClose>
                            </div>
                        </div>
                    </Renderif>
                </div> */}

                {/* <div className='d-flex flex-wrap mt-3'>

                    {state.filters.map((item,i)=>{
                        return(
                            <div key={i} className='nu-tag'>
                                <div className='f-12 bold'>{item}</div>
                                <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                                    if(state.filters.includes(item)){

                                        
                                        state.filters = state.filters.filter((filter)=>filter !== item)
                          
                                        setState({...state})
                                    }
                                }}>
                                    <IoClose size={14}></IoClose>
                                </div>
                            </div>
                        )
                    })}


                </div> */}

                <div>
                    { 
                        // professionals?.map((item, i)=>{
                        state.professionals?.map((item, i)=>{


                   
                            
                            return(
                                <div key={i} className='d-flex align-items-center border-bottom py-3'>
                            
                                   
                                    <div className='col'>
                                        
                                        <div className='d-flex  align-items-start'>
                                            <div className='avatar me-2' style={{backgroundImage: 'url(/assets/avatar.png)'}}></div>
                                            <div className=''>
                                                <Renderif isTrue={item?.name === undefined}>
                                                    <div className='f-14 bold'>Sin nombre</div>
                                                </Renderif>
                                                <div className='f-14 bold'>{item?.name}</div>
                                                <div className='f-12'>{getProfesionByUserType(item?.idUserType)}</div>
                                            </div>
                                        </div>

                        
                                    </div>
                                    <div className='col'>
                                        
                                        <div className='f-14 bold'>{
                                            item?.status === "0" ? 'Pendiente de validar' : 'Perfil Validado'
                                        }</div>
                                        <Renderif isTrue={item.documentationCompleted === true && item.status === '0'}>
                                            <div className='f-12 bold text-success'>En revisión</div>
                                        </Renderif>

                                    </div>
                                    <div className='col'>
                                        <div className='f-14 bold'>{item?.name === "Usuario eliminado" ? "-" : item?.email}</div>
                                    </div>
                                    <div className='col'>

                                        <div className='d-flex align-items-center'>
                                            <div className='nu-icon nu-icon--mid text-decoration-underline cursor-pointer' onClick={()=>{
                                                navigate(`/admin/valoraciones/${item.id}/0`)
                                            }}>
                                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                                            </div>
                                            <div className='f-12 bolder text-decoration-underline cursor-pointer' onClick={()=>{
                                                navigate(`/admin/valoraciones/${item.id}/0`)
                                            }}>{item.stars === null ? 'Sin Valoraciones' : parseFloat(item.stars).toFixed(1)}</div>
                                       
                                            
                                        </div>
                                        
                                    </div>
                                    {/* <div className='pe-1'>
                                        <div className='nu_btn nu_btn--primary' onClick={()=>{
                                                
                                                navigate('/admin/profesional/'+item.id)
                                            }}>

                                            <div className='nu-icon nu-icon--mid'>
                                                <IoIosSearch size={20}></IoIosSearch>
                                            </div>

                                            <div className='nu_btn-text '>Ver perfil</div>
                                        </div>
                                    </div>
                                    <div className='px-1'>

                                        <div className='nu_btn nu_btn--primary' onClick={()=>{
                                                navigate('/admin/documentacion/'+item.id)
                                            }}>

                                            <div className='nu-icon nu-icon--mid'>
                                                <IoIosSearch size={20}></IoIosSearch>
                                            </div>

                                            <div className='nu_btn-text '>Ver documentación</div>
                                        </div>
                                    </div>
                                    <div className='ps-1'>

                                        <Renderif isTrue={item.blocked === false}>
                                            <div className='nu_btn nu_btn--danger' onClick={()=>{
                                                    onBlockProfesional(item.id)
                                                }}>

                                                <div className='nu-icon nu-icon--mid'>
                                                    <MdOutlinePersonOff size={20}></MdOutlinePersonOff>
                                                </div>

                                                <div className='nu_btn-text ps-2'>Bloquear</div>
                                            </div>
                                        </Renderif>

                                        <Renderif isTrue={item.blocked === true}>
                                            <div className='nu_btn nu_btn--light' onClick={()=>{
                                              onUnblockProfesional(item.id)
                                                }}>

                                                <div className='nu-icon nu-icon--mid'>
                                                    <MdOutlinePersonOff size={20}></MdOutlinePersonOff>
                                                </div>

                                                <div className='nu_btn-text ps-2'>Desbloquear</div>
                                            </div>
                                        </Renderif>

                                        

                                      

                                    </div>
                                    <div className='px-1'>

                                        <div className='nu_btn nu_btn--danger' onClick={()=>{
                                                onDeleteUser(item)
                                            }}>

                                            <div className='nu-icon nu-icon--mid'>
                                                <BiTrash size={20}></BiTrash>
                                            </div>

                                            <div className='nu_btn-text '>Eliminar</div>
                                        </div>
                                    </div> */}
                                    <div>
                                        
                                        <NuDropDown item={item} onSelect={(key)=>{
                                            switch (key) {
                                                    case 0:
                                                        navigate('/admin/profesional/'+item.id)
                                                        break;
                                                    case 1:
                                                        navigate('/admin/documentacion/'+item.id)
                                                        break;
                                                    case 2:
                                                        onBlockProfesional(item.id)
                                                        break;
                                                    case 3:
                                                        onUnblockProfesional(item.id)
                                                        break;
                                                    case 4:
                                                        onDeleteUser(item)
                                                        break;
                                                    case 5:
                                                        break;
                                            
                                                default:
                                                    break;
                                            }
                                        }}></NuDropDown>
                                    </div>


                                </div>
                            )})

                    }

                </div>

                <div className='d-flex justify-content-center my-5'>
                    <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                          loadPage(-1)
                    }}>
                        <SlArrowLeft></SlArrowLeft>
                    </div>
                    <div className='d-flex flex-wrap px-3'>
                        {renderPagination()}
                    </div>
                    <div className='nu-icon nu-icon--mid cursor-pointer' onClick={async ()=>{

                        loadPage(1)

                        
                        // setState({...state, page:state.page + 1})
                    }}>
                        <SlArrowRight></SlArrowRight>
                    </div>
                </div>
             
                

               

              
          
            </div>

       
      
        </div>

    </div>
    </>
  )
}



function NuDropDown({item, onSelect = () => {}}) {
    
    const [state, setState] = useState({
        open:false
    })

    const _onSelect = (key) => {
        setState({...state, open:false})
        onSelect(key)
    }

    return (
        <>
        <Renderif isTrue={state.open}>
        <div className='dropdown-overlay' onClick={(evt)=>{
            
            setState({...state, open:false})
        }}></div>
        </Renderif>

        <div className="nudropdow">
            <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                setState({...state, open:!state.open})
            }}>
                <BsThreeDotsVertical size={25} color={darkBlue}></BsThreeDotsVertical>
            </div>
            <Renderif isTrue={state.open}>
                <div className='dropdow-items'>
                    <div className='dropdow-item' onClick={()=>{_onSelect(0)}}>
                        <div className='nu-icon nu-icon--mid'>
                            <IoIosSearch size={18}></IoIosSearch>
                        </div>

                        <div className='nu_btn-text '>Ver perfil</div>
                    </div>
                    <div className='dropdow-item' onClick={()=>{_onSelect(1)}}>
                        <div className='nu-icon nu-icon--mid'>
                            <IoIosSearch size={18}></IoIosSearch>
                        </div>

                        <div className='nu_btn-text '>Ver documentación</div>
                    </div>
                    <Renderif isTrue={item.blocked === false}>
                        <div className='dropdow-item' onClick={()=>{_onSelect(2)}}>
                         
                                <div className='nu-icon nu-icon--mid'>
                                    <MdOutlinePersonOff size={20}></MdOutlinePersonOff>
                                </div>

                                <div className='nu_btn-text'>Bloquear</div>
                           
                        </div>
                    </Renderif>

                    <Renderif isTrue={item.blocked === true}>
                        <div className='dropdow-item' onClick={()=>{_onSelect(3)}}>
                            
                                <div className='nu-icon nu-icon--mid'>
                                    <MdOutlinePersonOff size={20}></MdOutlinePersonOff>
                                </div>

                                <div className='nu_btn-text'>Desbloquear</div>
                       
                        </div>
                    </Renderif>
                    <div className='dropdow-item' onClick={()=>{_onSelect(4)}}>
                        <div className='nu-icon nu-icon--mid'>
                            <BiTrash size={16} color={'red'}></BiTrash>
                        </div>

                        <div className='nu_btn-text text-danger '>Eliminar</div>
                    </div>
                </div>
            </Renderif>
        </div>
        </>
    )
 
}


