import React, { useContext, useState } from 'react'
import {AiOutlineCheck} from 'react-icons/ai';
import {BiErrorCircle} from 'react-icons/bi'
import { FaCheck } from 'react-icons/fa';
import { NotificationContext } from '../context/notification_context';
import { darkBlue } from '../styles/colors';
import Renderif from '../utils/renderif';



export default function NotificationHolder() {

    const {notifications, onClose} = useContext(NotificationContext)


    const renderIcon = (type) => {

        switch (type) {
            case 'success':
                return (
                    <div className='icon'>
                        <FaCheck size={24} color='green' ></FaCheck>
                    </div>
                )
            case 'error':
                return (
                    <div className='icon'>
                        <BiErrorCircle size={24} color='red' ></BiErrorCircle>
                    </div>
                )
            case 'confirmation':
                return (
                    <div className='icon'>
                        <BiErrorCircle size={24} color={darkBlue} ></BiErrorCircle>
                    </div>
                )
            
                
        

            default:
               return (<></>)
        }
    }


  return (
    <div className='notification-holder'>

     
        {/* <div  className='notification'>

            {renderIcon('error')}
            <div className='ps-2'>
                <div className='notification-title'>{'Aasd'}</div>
                <div className='notification-message'>{'ASDASD'}</div>
            </div>
        </div> */}

      
       


        {
            notifications.map((notification, i)=>{
                
               

                return (
                    <div key={i} className='notification'>

                        {renderIcon(notification.type)}
                        <div className='ps-2 w-100'>
                            <div className='notification-title'>{notification.title}</div>
                            <div className='notification-message'>{notification.message}</div>
                           <Renderif isTrue={notification.type === 'confirmation'}>
                            <div className='d-flex mt-3'>
                                    <div className='col'></div>
                                    <div className='f-14 text-gray mx-3 cursor-pointer' onClick={()=>{
                                        onClose(notification)
                                    }}>Cancelar</div>
                                    <div className='f-14 text-darkblue bold cursor-pointer' onClick={()=>{
                                        notification.onAccept(true);
                                        onClose(notification);
                                    }}>Aceptar</div>
                                </div>
                           </Renderif>
                        </div>

                    
                    </div>
                )

            })
        }






    </div>
  )
}
