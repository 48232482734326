import React, {useContext, useEffect, useState} from 'react'
import CheckBox from '../../components/nu_checkbox'
import { useNavigate } from 'react-router-dom';
import { IoIosInformationCircleOutline } from "react-icons/io";
import NuComboBox from '../../components/nu_combobox';
import { IoIosArrowBack } from "react-icons/io";
import { darkBlue } from '../../styles/colors';
import { AuthContext } from '../../context/auth_context';
import { Service } from '../../services/api';
import { NotificationContext } from '../../context/notification_context';

export default function RegisterAddCenterView() {

    const {user, user_data} = useContext(AuthContext);
    const {notify} = useContext(NotificationContext);

    const navigate = useNavigate();
    const [state, setState] = useState({
        // name:'Nombre',
        // address:'Direccion',
        // postalCode:'112133',
        // province:'provincia',
        // description:'asd',
        // parking:'',
        // dietas:'',
        // uniforme:'',
        // location:'',
        // notes:'Test notes',
        // add:false

        name:'',
        address:'',
        postalCode:'',
        province:'',
        description:'',
        parking:'',
        dietas:'',
        uniforme:'',
        location:'',
        notes:'',
        add:false,
        centers:[],
        center_selected:'',
        
        

        
    })

    useEffect(()=>{
        const init = async () => {
            let _centerType = await Service('GET', 'centerType/getAll');
            if(_centerType.status === 200){
                state.centers = _centerType.data.list
            }
            setState({...state})
        }
        init()
        return () => {}
    },[])
    const provincias = [
        {
            provincia: 'Andalucía',
            colegios: ['Almería', 'Cádiz', 'Córdoba', 'Granada', 'Huelva', 'Jaén', 'Málaga', 'Sevilla']
        },
        {
            provincia: 'Aragón',
            colegios: ['Zaragoza', 'Teruel', 'Huesca']
        },
        {
            provincia: 'Principado de Asturias',
            colegios: ['Asturias']
        },
        {
            provincia: 'Illes Balears',
            colegios: ['Illes Balears']
        },
        {
            provincia: 'Canarias',
            colegios: ['Las Palmas', 'Santa Cruz de Tenerife']
        },
        {
            provincia: 'Cantabria',
            colegios: ['Cantabria']
        },
        {
            provincia: 'Castilla y León',
            colegios: ['Ávila', 'Burgos', 'León', 'Palencia', 'Salamanca', 'Segovia', 'Soria', 'Valladolid', 'Zamora']
        },
        {
            provincia: 'Castilla y la Mancha',
            colegios: ['Albacete', 'Ciudad Real', 'Cuenca', 'Guadalajara y Toledo']
        },
        {
            provincia: 'Cataluña',
            colegios: ['Barcelona', 'Tarragona', 'Girona', 'Lleida']
        },
        {
            provincia: 'Comunidad Valenciana',
            colegios: ['Alicante', 'Valencia', 'Castellón']
        },
        {
            provincia: 'Extremadura',
            colegios: ['Cáceres','Badajoz']
        },
        {
            provincia: 'Galicia',
            colegios: ['A Coruña','Lugo', 'Ourense', 'Pontevedra']
        },
        {
            provincia: 'Murcia',
            colegios: ['Murcia']
        },
        {
            provincia: 'Madrid',
            colegios: ['Madrid']
        },
        {
            provincia: 'Navarra',
            colegios: ['Navarra']
        },
        {
            provincia: 'País Vasco',
            colegios: ['Bizcaia','Gipuzkoa','Araba']
        },
        {
            provincia: 'La Rioja',
            colegios: ['La Rioja']
        },
        {
            provincia: 'Ceuta',
            colegios: ['Ceuta']
        }
        ,
        {
            provincia: 'Melilla',
            colegios: ['Melilla']
        }
        
    ];



    const validator = () => {
        state.errors = {}
        
        if(state.name === ''){
            state.errors.name = 'Nombre completo es obligatorio'
        }
       
        if(state.postalCode === ''){
            state.errors.postalCode = 'Código postal es obligatorio'
        }
        if(state.address === ''){
            state.errors.address = 'Dirección es obligatorio'
        }
        if(state.province === ''){
            state.errors.province = 'Provincia es obligatorio'
        }
        if(state.parking === ''){
            state.errors.parking = 'Parking es obligatorio'
        }
        if(state.dietas === ''){
            state.errors.dietas = 'Dietas es obligatorio'
        }
        if(state.uniforme === ''){
            state.errors.uniforme = 'Uniforme es obligatorio'
        }
        if(state.location === ''){
            state.errors.location = 'Punto de encuentro es obligatorio'
        }
      
    
        setState({...state})
        if(Object.keys(state.errors).length > 0){
            
            return false;
        }
       return true;
        

    }
    const onCreateCenter =  async () => {



    

        if(!validator()){
            return;
        }

        let _description = `<div><span>Parking</span>: ${state.parking}</div><div><span>Dietas</span>: ${state.dietas}</div><div><span>Uniforme</span>: ${state.uniforme}</div><div><span>Punto de encuentro</span>: ${state.location}</div><div><span>Notas</span>: ${state.notes}</div>`;

        let body = {
            idOrganization:user_data.id,
            name:state.name,
            address:state.address,
            postalCode:state.postalCode,
            province: state.province,
            description:null,
            info: JSON.stringify({
                Parking:state.parking,
                Dietas:state.dietas,
                Uniforme:state.uniforme,
                Location:state.location,
                Notas:state.notes
            }),
            idCenterType: parseInt(state.center_selected)
        }

        let _addCenter = await Service('POST', 'center/create', JSON.stringify(body) )
        if(_addCenter.status === 201){

            notify({title:'¡Listo!', message:'Haz añadido un nuevo centro.', type:'success'})
            if(state.add){
                setState({
                    ...state,
                    name:'',
                    address:'',
                    postalCode:'',
                    province:'',
                    description:'',
                    parking:'',
                    dietas:'',
                    uniforme:'',
                    location:'',
                    notes:'',
                    add:false,
                    center_selected:''
    
                })
                return;
            }

            
            notify({title:'¡Bienvenido!', message:user.name, type:'success'})
            navigate('/clinic')
          

            
        }else{
            notify({title:'Error', message:_addCenter?.response?.data?.error, type:'error'})
            setState({...state, loading:false})
        }
    }


  return (
    <div className='container-fluid h-100'>

        <div className='d-flex h-100'>

            <div className='col-12 col-md-3 d-none d-md-block'>
                <div className='register_bg bg-cover' style={{backgroundImage:'url(/assets/nursea5.png)'}}></div>
            </div>
            
            <div className='col-12 col-md-9 d-flex flex-column p-3 p-md-5  overflow-auto hide-scroll'>
                <div className='container d-flex flex-column px-md-5'>


                    <div className='d-flex fit-content align-items-center cursor-pointer mb-2'>
                        {/* <div className='nu-icon nu-icon--mid'>
                            <IoIosArrowBack size={20} color={darkBlue}></IoIosArrowBack>
                        </div> */}
                        {/* <div className='f-16 bold text-darkblue'>Atras</div> */}
                    </div>



                    <div className='f-28 bold'>Añadir Centro Sanitario</div>

                    <div className='d-flex flex-wrap'>
                        <div className='col-12 col-md pe-0 pe-md-2'>


                            <div className='f-16 bold mt-3 mb-1'>Nombre de centro</div>
                            <input type='text' value={state.name} onChange={(evt)=>{
                                setState({...state, name:evt.currentTarget.value })
                            }} className='nu_input' placeholder='Nombre de centro *'></input>
                               <div className='text-danger f-12'>{state.errors?.name}</div>

                        </div>

                        <div className='col-12 col-md ps-0 ps-md-2'>

                                <div className='f-16 bold mt-3 mb-1'>Tipo de Centro</div>
                    

                                <select 
                                
                                    
                                    value={state.center_selected}
                                    className={`nu-select nu-input--light`} 
                                    onChange={(evt)=>{
                                        state.center_selected = parseInt(evt.currentTarget.value)
                                        setState({...state})
                                    }}>

                                        <option value=''>Seleccionar</option> 
                                            {state.centers.map((item,i)=>{
                                            if(item.id==1) return;
                                        
                                            
                                            return (
                                                <option key={i} value={item.id}>{item.description}</option> 
                                            )
                                        
                                    })}
                                </select>
                        </div>

                      
                    </div>

                    <div className='d-flex'>
                        <div className='col'>
                            <div className='f-16 bold mt-3 mb-1'>Dirección completa</div>
                            <input type='text' value={state.address} onChange={(evt)=>{
                                setState({...state, address:evt.currentTarget.value })
                            }} className='nu_input' placeholder='Dirección completa *'></input>
                               <div className='text-danger f-12'>{state.errors?.address}</div>
                        </div>

                    </div>


                    <div className='d-flex flex-wrap'>

                        <div className='col-12 col-md pe-0 pe-md-2'>
                            <div className='f-16 bold mt-3 mb-1'>Código postal</div>
                            <input type='text' value={state.postalCode} onChange={(evt)=>{
                                setState({...state, postalCode:evt.currentTarget.value })
                            }} className='nu_input' placeholder='Código postal *'></input>
                               <div className='text-danger f-12'>{state.errors?.postalCode}</div>
                          
                        </div>

                        <div className='col-12 col-md ps-0 ps-md-2'>
                            <div className='f-16 bold mt-3 mb-1'>Comunidad autónoma</div>

                            <select value={state.province} onChange={(evt)=>{
                                     
                                     setState({...state, province:evt.currentTarget.value})

                                     

                                 }}  className='nu-select nu-input--light'>
                                 <option value="" disabled>Seleccionar</option>
                                 {
                                     provincias.map((item, i)=>{
                                         return(
                                             <option key={i} value={item.provincia}>{item.provincia}</option>
                                         )
                                     })
                                 }
                          
                                </select>
                                <div className='text-danger f-12'>{state.errors?.province}</div>

                     

                        </div>
                        
                    </div>

                    <div className='d-flex mt-5'>
                        <div className='f-28 bold'>Información del Centro Sanitario</div>
                    </div>

                    <div className='d-flex'>
                        <div className='col'>
                            <div className='f-16 bold mt-3 mb-1'>Parking</div>
                            <input type='text' value={state.parking} onChange={(evt)=>{
                                setState({...state, parking:evt.currentTarget.value })
                            }}  className='nu_input' placeholder='Ej. No hay parking disponible pero es fácil aparcar *'></input>
                               <div className='text-danger f-12'>{state.errors?.parking}</div>

                            <div className='f-16 bold mt-3 mb-1'>Dietas</div>
                            <input type='text' value={state.dietas} onChange={(evt)=>{
                                setState({...state, dietas:evt.currentTarget.value })
                            }} className='nu_input' placeholder='Ej. Se incluye comida o cena en turnos superiores a 8 horas *'></input>
                               <div className='text-danger f-12'>{state.errors?.dietas}</div>

                            <div className='f-16 bold mt-3 mb-1'>Uniforme</div>
                            <input type='text' value={state.uniforme} onChange={(evt)=>{
                                setState({...state, uniforme:evt.currentTarget.value })
                            }} className='nu_input' placeholder='Ej. Traer zapato blanco, se entrega uniforme en recepción *'></input>
                               <div className='text-danger f-12'>{state.errors?.uniforme}</div>
                            
                            <div className='f-16 bold mt-3 mb-1'>Punto de encuentro</div>
                            <input type='text' value={state.location} onChange={(evt)=>{
                                setState({...state, location:evt.currentTarget.value })
                            }} className='nu_input' placeholder='Presentarse 20’ antes del turno en el hall central. Planta 0. Preguntar por... *'></input>
                               <div className='text-danger f-12'>{state.errors?.location}</div>

                            <div className='f-16 bold mt-3 mb-1'>Notas</div>
                            <input type='text' value={state.notes} onChange={(evt)=>{
                                setState({...state, notes:evt.currentTarget.value })
                            }} className='nu_input' placeholder='Ej. Particularidades no contempladas *'></input>
                               <div className='text-danger f-12'>{state.errors?.notes}</div>

                     

                            <div className='f-16 bold mt-3 mb-1'>¿Quieres añadir un nuevo Centro Sanitario dentro de la misma organización?</div>

                            <CheckBox checked={state.add} onChange={(evt)=>{

                                    setState({...state, add:evt.value})
                               
                            }}   text="Si, quiero añadir un nuevo Centro Sanitario"></CheckBox>

                            {/* <CheckBox checked={state.add === false} onChange={(evt)=>{
                                
                               
                            }}   text="No"></CheckBox> */}
                            
                            {/* <NuComboBox multiple={false} checks={['Si, quiero añadir un nuevo Centro', 'No']} onChange={(evt)=>{
                                if(evt[0].key === 'No'){
                                    state.add = true;
                                }else{
                                    state.add = false;
                                }
                            }}></NuComboBox> */}

                        </div>
                    </div>
                
                    <div className='mt-5'>
                        <div className='nu_btn nu_btn--overlay' onClick={()=>{
                           onCreateCenter()
                        }}>
                            <div className='nu_btn-text f-18'>Siguiente</div>
                            <div className='nu_btn-overlay'></div>
                        </div>
                    </div>
                    <div className='d-block d-md-none my-5'></div>

                    
                </div>                
            </div>

        </div>

    </div>
  )
}
