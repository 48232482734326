import logo from './logo.svg';

import './styles/calendar.css';
import './styles/components.css';
import './styles/form.css';
import './App.css';
import './styles/overwrite.css';
import LoginView from './views/commun/login_commun_view';
import RegisterView from './views/commun/register_commun_view';
import HomeProfesionalView from './views/profesional/home_profesional_view';
import IntroView from './views/commun/intro_commun_view';
import RegisterEnfermeria from './views/commun/register_commun_enfermeria';
import RegisterEstudiante from './views/commun/register_commun_estudiante';
import RegisterTCAE from './views/commun/register_commun_tcae';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import PorfesionalNavigator from './navigator/profesional_navigator';
import ClinicNavigator from './navigator/clinic_navigator';
import AuthContextProvider from './context/auth_context';
import RegisterClinicaView from './views/clinica/register_clinica_view';
import RegisterAddCenterView from './views/clinica/register_add_center_view';
import AdminNavigator from './navigator/admin_navigator';
import NotificationProvider from './context/notification_context';
import NotificationHolder from './components/nu_notification_holder';
import GlobalContextProvider from './context/global_context';
import RegisterConfirmationView from './views/profesional/register_confirmation_view';
import AdminContextProvider from './context/admin_context';

function App() {
 
  return (
   
      <BrowserRouter>
        <NotificationProvider>
            <AuthContextProvider>
            <NotificationHolder></NotificationHolder>

          
                <Routes>
                    <Route path="/test" element={<RegisterAddCenterView></RegisterAddCenterView>}/>

                    <Route path="/" element={<LoginView></LoginView>}/>
               
                    <Route path="/register/:user_type" element={<RegisterView></RegisterView>}/>
                    <Route path="/register/clinica" element={<RegisterClinicaView></RegisterClinicaView>}/>
                    <Route path="/register/enfermeria" element={<RegisterEnfermeria></RegisterEnfermeria>}/>
                    <Route path="/register/estudiante" element={<RegisterEstudiante></RegisterEstudiante>}/>
                    <Route path="/register/tcae" element={<RegisterTCAE></RegisterTCAE>}/>
                    
                    <Route exact path="/profesional/*" element={
                     <GlobalContextProvider>
                      <PorfesionalNavigator></PorfesionalNavigator>
                     </GlobalContextProvider>} />
                    <Route exact path="/clinic/*" element={
                     <GlobalContextProvider>
                      <ClinicNavigator></ClinicNavigator>
                    </GlobalContextProvider>
                    } />
                    <Route exact path="/admin/*" element={
                    <AdminContextProvider>
                      <AdminNavigator></AdminNavigator>
                    </AdminContextProvider>} />
                </Routes> 
           

            </AuthContextProvider>
          </NotificationProvider>
      </BrowserRouter>

  )

}

export default App;
