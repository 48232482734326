import React, {useState} from 'react'
import CheckBox from '../../components/nu_checkbox'
import { profile_data } from '../../utils/forms'
import NuComboBox from '../../components/nu_combobox'
import Renderif from '../../utils/renderif';
import { useNavigate } from 'react-router-dom';

export default function RegisterEstudiante() {
    const navigate = useNavigate();

    var forms = profile_data;

    const [state, setState] = useState({
        rol_selected:0,
        form_step:1
    })
  return (
    <div className='container-fluid h-100'>

        <div className='d-flex h-100'>

            <div className='col-12 col-md-3 d-none d-md-block'>
                <div className='register_bg bg-cover'></div>
            </div>
            
            <div className='col-12 col-md-9 d-flex flex-column overflow-auto hide-scroll'>

          
                <div className='container'>
                    <div className='d-flex justify-content-center py-5 mx-5 mt-3 rt-10 bg-darkblue'>
                       <div className='f-34 text-white bold'>Estudiantes de enfermería</div>
                    </div>
                </div>
         

                <div className='container d-flex flex-column px-5 '>
                    <div className='sticky-top'>
                        <div className='d-flex bg-white py-4'>

                            <div className='col pe-5'>
                                <div className={`bar bar--${state.form_step >= 1 ? 'active':''}`}></div>
                                <div className='f-16 bolder line-1'>Paso 1</div>
                                <div className='f-16 text-gray line-1'>General</div>
                            </div>

                            <div className='col pe-5'>
                                <div className={`bar bar--${state.form_step >= 2 ? 'active':''}`}></div>
                                <div className='f-16 bolder line-1'>Paso 2</div>
                                <div className='f-16 text-gray line-1'>Preferencias</div>
                            </div>

                            <div className='col pe-5'>
                                <div className={`bar bar--${state.form_step >= 3 ? 'active':''}`}></div>
                                <div className='f-16 bolder line-1'>Paso 3</div>
                                <div className='f-16 text-gray line-1'>Experiencia</div>
                            </div>


                        
                        </div>
                    </div>

                    <Renderif isTrue={state.form_step === 1}>
                        <div className='bg-gray-00 -gray r-10 p-5'>
                            <div className='f-32 bolder mb-3'>Bienvenido (a)</div>
                            <div className='f-22 bold'>Define tu perfil profesional y tus preferencias</div>
                            <div className='f-16'>Sigue con el registro y verás publicados turnos de acuerdo con tu <span className='bolder'>criterio de elección</span>.</div>
                        </div>

                        <div className='bg-gray-00 -gray r-10 p-5 mt-3'>
                            <div className='f-22 bold'>Universidad</div>
                            <div className='f-16'>Más adelante te pediremos la foto de tu carnet de colegiado/a.</div>
                            <div className='mt-3'>
                                <select defaultValue=""  className='nu-select nu-input--light'>
                                    <option value="" disabled>Lorem ipsum dolor sit amet</option>
                                    <option>1</option>
                                    <option>1</option>
                                    <option>1</option>
                                </select>
                            </div>

                            <div className='mt-4'>
                                <div className='f-22 bold'>Curso Finalizado</div>

                                <div className='d-flex justify-content-between w-25 my-2 '>
                                    <NuComboBox  multiple={false} checks={["2o Curso", "3r Curso"]} onChange={(value)=>{
                                   
                                    }}></NuComboBox>
                                </div>
                            </div>

                            
                        </div>

                        <div className='bg-gray-00 -gray r-10 p-5 mt-3'>
                     
                            <div className='f-22 bold'>¿Hasta qué distancia de tu código postal <br></br> quieres que te mostremos turnos?</div>
                            <div className='mt-3 mx-5 px-5 '>

                                <div className='d-flex'>
                                    <div className='col-1 d-flex align-items-end'>
                                        <div className='f-12 bold'>0-5km</div>
                                    </div>
                                  
                                    <div className='col-4 justify-content-center d-flex align-items-end '>
                                        <div className='f-12  bold ps-3'>5-10km</div>
                                    </div>


                                    <div className='col-2 justify-content-center  d-flex align-items-end text-center '>
                                        <div className='f-12  bold'>10-15km</div>
                                    </div>
                               
                                    <div className='col-4 justify-content-center  d-flex align-items-end '>
                                        <div className='f-12 bold pe-3'>15-20km</div>
                                    </div>
                               
                        
                             
                                    <div className='col-1  d-flex align-items-end '>
                                        <div className='f-12 bold'>Cualquier distancia</div>
                                    </div>
                              
                                </div>
                                <input className='' step={1}  max={4} type='range'></input>
                            </div>
                           
                        </div>
                    </Renderif>

                    <Renderif isTrue={state.form_step === 2}>
                        
                        {
                            forms.slice(2,5).map((item, i)=>{

                           
                                return (
                                    <div key={i} className='bg-gray-00 -gray r-10 p-5 mt-3'>

                                        <div className='d-flex'>
                                            <div className='col'><div className='f-22 bold'>{item.title}</div></div>
                                            <div className='col'></div>
                                        </div>

                                        <div className='f-16'>{item.description}</div>
                                        <div className='my-4'></div>

                                    
                                           <Renderif isTrue={!item.multiple}>
                                             <div className='col'>
                                                <NuComboBox key={i} multiple={item.multiple} checks={item.checks} onChange={(value)=>{
                                               
                                                }}></NuComboBox>
                                            </div>
                                           </Renderif>

                                           <Renderif isTrue={item.multiple}>
                                                <div className='d-flex'>
                                                    <div className='col'>
                                                        <NuComboBox key={i} multiple={item.multiple} checks={item.checks.slice(0,Math.round(item.checks.length/2))} onChange={(value)=>{
                                                          
                                                        }}></NuComboBox>
                                                    </div>

                                                    <div className='col'>

                                                        <NuComboBox key={i} multiple={item.multiple} checks={item.checks.slice(Math.round(item.checks.length/2))} onChange={(value)=>{
                                                       
                                                        }}></NuComboBox>
                                                    </div>
                                                </div>
                                           </Renderif>

                            
                                    </div>
                                 
                                )
                            })
                        }
                    </Renderif>

                    <Renderif isTrue={state.form_step === 3}>
                        {
                            forms.slice(5,7).map((item, i)=>{

                             

                                return (
                                    <div key={i} className='bg-gray-00 -gray r-10 p-5 mt-3'>

                                        <div className='d-flex'>
                                            <div className='col'><div className='f-22 bold'>{item.title}</div></div>
                                            <div className='col'></div>
                                        </div>

                                        <div className='f-16'>{item.description}</div>
                                        <div className='my-4'></div>

                                    
                                           <Renderif isTrue={!item.multiple}>
                                            
                                             <div className='col'>
            
                                                <NuComboBox key={i} multiple={item.multiple} checks={item.checks} onChange={(value)=>{
                                                   
                                                }}></NuComboBox>
                                            </div>

                                                <Renderif isTrue={item.type === 'option'}>
                                           
                                                
                                                        <div className='d-flex my-3'>
                                                            <div className='col'></div>
                                                        
                                                            
                                                            <div className='col d-flex justify-content-around '>
                                                                
                                                                
                                                            <div className='col '>
                                                                <div className="f-12 bold text-center line-1 ps-3 ">No tengo <br></br>experiencia</div>
                                                            </div>
                                                    
                                                                <div className="f-12 bold text-center line-1">Menos <br></br>de 1 año</div>
                
                                                            <div className='col '>
                                                                <div className="f-12 bold text-center line-1 pe-2">Más de <br></br> 1 año</div>
                                                            </div>

                                                            </div>

                                                        </div>
                                                
                                                        
                                                        { item?.options?.map((option, i)=>{
                                                            return ( 
                                                            
                                                                <div key={i} className={`d-flex py-1 ${i%2 == 0  ? 'bg-white' : ''}`}>
                                                                    <div className='col'>
                                                                        <div className='f-14 text-dark bold ps-4'>{option.key}</div>
                                                                    </div>
                                                            
                                                                    <div className='col d-flex justify-content-evenly '>
                                                                        
                                                                    <NuComboBox showkey={false} key={i} multiple={item.multiple} checks={option.values} onChange={(value)=>{
                                                                          
                                                                        }}></NuComboBox>

                                                                    </div>

                                                                </div>
                                                            
                                                        
                                                            
                                                            )


                                                        })
                                                        
                                                        }
                                               
                                                </Renderif>

                                           </Renderif>

                                           <Renderif isTrue={item.multiple}>
                                            
                                                <div className='d-flex'>
                                                    <div className='col'>
                                                        <NuComboBox key={i} multiple={item.multiple} checks={item.checks.slice(0,Math.round(item.checks.length/2))} onChange={(value)=>{
                                                          
                                                        }}></NuComboBox>
                                                    </div>

                                                    <div className='col'>

                                                        <NuComboBox key={i} multiple={item.multiple} checks={item.checks.slice(Math.round(item.checks.length/2))} onChange={(value)=>{
                                                        
                                                        }}></NuComboBox>
                                                    </div>
                                                </div>
                                           </Renderif>

                            
                                    </div>
                                 
                                )
                            })
                        }
                    </Renderif>

                    <div className='mt-3 mb-5'>
                        <div className='d-flex'>
                            <div className='nu_btn nu_btn--overlay' onClick={()=>{
                                  
                                    if(state.form_step > 1){
                                        setState({...state, form_step:state.form_step - 1})
                                    }

                                }}>
                                <div className='nu_btn-text f-18 px-4'>Atrás</div>
                                <div className='nu_btn-overlay'></div>
                            </div>
                            <div className='col'></div>
                            <div className='nu_btn nu_btn--overlay' onClick={()=>{
                                 
                                    if(state.form_step === 3){
                                        navigate('/profesional')
                                    }
                                    if(state.form_step <= 2){
                                        setState({...state, form_step:state.form_step + 1})
                                    }
                                }}>
                                <div className='nu_btn-text f-18 px-1'>Siguiente</div>
                                <div className='nu_btn-overlay'></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col'></div>
                

                {/* <div className='d-flex justify-content-evenly my-3 mx-2'>
                    <div className='f-12 bold text-decoration-underline cursor-pointer'>Terminos y condiciones</div>
                    <div className='f-12 bold text-decoration-underline cursor-pointer'>Aviso de privacidad</div>
                </div> */}

            </div>
        </div>
    </div>
  )
}
