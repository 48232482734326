import React, {useContext, useEffect, useState} from 'react';
import CheckBox from '../../components/nu_checkbox';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Login, Register, Service } from '../../services/api';
import { NotificationContext } from '../../context/notification_context';
import NuLoading from '../../components/nu_loading';
import Renderif from '../../utils/renderif';
import { AuthContext } from '../../context/auth_context';
import TycosViewProfesional from '../profesional/tycos_view';
import { BsEye, BsEyeSlash } from 'react-icons/bs';

export default function RegisterView() {

    const navigate = useNavigate();
    const location = useLocation();
    const {user_type} = useParams()
    const {notify} = useContext(NotificationContext);
    const {user, setUser, logout} = useContext(AuthContext);
    const [state, setState] = useState({
        password: '',
        confirmPassword:'',
        contry_code:'',
        email: '',
        phone: '',
        name: '',
        nif: '',
        postal_code:'',
        user_type: parseInt(user_type),
        errors: {},
        tycos: false,
        loading:false,
        showTyCos:false,
        showPassword:false,
        envelop: '',
    })
    const checkPassword = (password) => {
        var re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-/]).{8,}$/;
        // var re = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        return re.test(password);
    }
    const checkEmail = (email) => {
        var re = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
        return re.test(email);
    }
    const validator = () => {
        state.errors = {}
        
        if(state.name === ''){
            state.errors.name = 'Nombre completo es obligatorio'
        }
        if(state.postal_code === ''){
            state.errors.postal_code = 'Código postal es obligatorio'
        }
        // if(state.phone.length !== 10){
        //     state.errors.phone = 'Teléfono debe tener 10 digitos'
        // }
        if(state.phone === ''){
            state.errors.phone = 'Teléfono es obligatorio'
        }
        // if(state.contry_code === ''){
        //     state.errors.phone = 'Código de pais es obligatorio'
        // }
        if(state.email === ''){
            state.errors.email = 'Correo electrónico es obligatorio'
        }
        if(!checkEmail(state.email)){
            state.errors.email = 'Correo electrónico incorrecto'
        }

        if (!state.password) {
            state.errors.password = "La contraseña es obligatoria";
          } else if (!checkPassword(state.password)) {
            state.errors.password =
              "Debe tener mínimo 8 caracteres, 1 letra Mayúscula, 1 letra minúscula, 1 número y 1 carácter especial (!&%#*?-^/)";
        }

        if(state.confirmPassword !== state.password){
            state.errors.confirmPassword = 'Las contraseñas no coinciden'
        }
        if(!state.tycos){
            state.errors.tycos = 'Debes leer y aceptar las Políticas de privacidad'
        }
        if(state.nif === ''){
            state.errors.nif = 'DNI es obligatorio'
        }
        setState({...state})
        if(Object.keys(state.errors).length > 0){
            
            return false;
        }
       return true;
        

    }

    
    useEffect(() => {
        logout();
        /* This part is used for obtain the param of docusign when docusign will be implemented */
        // const queryParams = new URLSearchParams(location.search);
        // const eventParam = queryParams.get('event')
        // try{
        //     if (eventParam === "signing_complete") {
        //         const userToRegister = localStorage.getItem('toRegister')
        //         localStorage.removeItem('toRegister')
        //         onRegister(JSON.parse(userToRegister))
        //     }
        // } catch (error) {
        //     localStorage.removeItem('toRegister')
        //     return
        // }
    }, [])

    const onSign = async () => {
        if(!validator()){
            return;
        }
        setState({...state, loading:true})
        const domain = window.location.hostname
        const protocol = window.location.protocol
        const port = window.location.port
        const urlCurrent = `${protocol}//${domain}${port ? ':' + port : ''}${location.pathname}`

        const body = {
            returnURL: urlCurrent,
            name: state.name,
            email: state.email,
            nif: state.nif,
        }
        let _signed = await Service('POST', 'docusign/policies', body)
        if (_signed.status === 201){
            setState({...state, envelop:_signed?.data?.envelopeId})
            let userToRegister = {
                password: state.password,
                email: state.email,
                phone: '+34'+state.phone,
                name: state.name,
                nif: state.nif,
                postal_code: state.postal_code,
                user_type: parseInt(user_type),
                idPrivacyPoliciesSigned: state.envelop,
            }
            setTimeout(() => {
                localStorage.setItem('toRegister', JSON.stringify(userToRegister))
                window.location.href = _signed?.data?.redirectURL
            }, 3000);
        } 
    }

    const onRegister = async (userToRegister) => {
        /* When docusign will be implemented please comment the next if conditional */
        if(!validator()){
            return;
        }
        setState({...state, loading:true})

        /* When docusign will be implemented please comment the variable of body and uncomment the next line => let body = {...userToRegister} */
        // let body = {...userToRegister}
        let body = {
            password: state.password,
            email: state.email,
            phone: '+34'+state.phone,
            name: state.name,
            nif: state.nif,
            postal_code: state.postal_code,
            user_type: parseInt(user_type),
            idPrivacyPoliciesSigned: state.envelop,
        }
        let _register = await Register(JSON.stringify(body));
        if(_register.status === 200){

            let _login =  await Login({email:body.email, password:body.password}) 

            localStorage.setItem('user',JSON.stringify(_login.data.user))
            localStorage.setItem('token',_login.data.token)     
            localStorage.setItem('refreshToken',_login.data.refreshToken)          
            setUser({user:_login.data.user})


            switch (parseInt(user_type)) {
                case 1:
                    navigate('/register/enfermeria')
                    break;
                case 2:
                    navigate('/register/estudiante')
                    break;
                case 3:
                    navigate('/register/tcae')
                    break;
                default:
                    break;
            }
            
        }else{
            notify({title:'Error', message:_register?.response?.data?.error, type:'error'})
            setState({...state, loading:false})
        }

      

        

    }
    

  return (
    <>
     <Renderif isTrue={state.showTyCos}>
        <TycosViewProfesional name={state.name} dni={state.nif} onClose={()=>{
            setState({...state, showTyCos:false})
        }}></TycosViewProfesional>
    </Renderif>

    <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
    </Renderif>
  
    <div className='container-fluid h-100'>

        <div className='d-flex h-100'>
            <div className='col-12 col-md-3 d-none d-md-block'>
                <div className='register_bg bg-cover'></div>
            </div>
            
            <div className='col-12 col-md-9 d-flex flex-column px-3 px-sm-5 overflow-auto hide-scroll'>

                {/* <div className='col d-flex justify-content-center align-items-center'>
                    <img  src='/assets/logo.png'></img>
                </div> */}


                <div className='container d-flex flex-column p-0 p-md-3 p-lg-5'>
       
                    <div className='f-28 bold'>Sobre ti</div>

                    <div className='d-flex mt-3'>

                        <div className='row w-100'>

                      

                        <div className='col-12 col-md-6 pe-0 pe-md-4'>
                            <div className='f-16 bold mt-3 mb-1'>Nombre Completo</div>
                            <input type='text' value={state.name} onChange={(evt)=>{
                                setState({...state, name: evt.currentTarget.value})
                            }} className='nu_input' placeholder='Nombre completo'></input>
                            <div className='text-danger f-12'>{state.errors?.name}</div>

                            <div className='f-16 bold mt-3 mb-1'>Teléfono</div>

                            <div className='d-flex'>

                                <div className='col-2'>
                                    <input type='number' disabled value={state.contry_code} onChange={(evt)=>{
                                        setState({...state, contry_code: evt.currentTarget.value})
                                    }}  className='nu_input' placeholder='+34'></input>
                                </div>

                            
                                <div className='col ps-1'>
                                    <input type='number' value={state.phone} onChange={(evt)=>{
                                                    setState({...state, phone: evt.currentTarget.value})
                                                }}  className='nu_input' placeholder='Teléfono'></input>
                                </div>
                             
                                
                            </div>

                            <div className='text-danger f-12'>{state.errors?.phone}</div>                        

                            <div className='f-16 bold mt-3 mb-1'>Contraseña</div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input type={state.showPassword ? 'text' : 'password'} value={state.password}
                                    onChange={(evt) => {
                                    const newPassword = evt.currentTarget.value;
                                    let newErrors = { ...state.errors };
                                    if (!newPassword) {
                                        newErrors.password = "La contraseña es obligatoria";
                                    } else if (!checkPassword(newPassword)) {
                                        newErrors.password =
                                        "Debe tener mínimo 8 caracteres, 1 letra Mayúscula, 1 letra minúscula, 1 número y 1 carácter especial (!&%#*?-^/)";
                                    } else {
                                        delete newErrors.password;
                                    }
                                    setState({
                                        ...state,
                                        password: newPassword,
                                        errors: newErrors,
                                    });
                                    }}
                                    className='nu_input' placeholder="Contraseña*" style={{ flex: 1 }}></input>
                                    <div className='nu-icon nu-icon--mid cursor-pointer ms-2'
                                        onClick={() => { 
                                                setState({
                                                ...state,
                                                showPassword: !state.showPassword,
                                            });
                                        }} style={{ marginLeft: "8px" }}>
                                        <Renderif isTrue={state.showPassword}>
                                        <BsEye size={30}></BsEye>
                                        </Renderif>
                                        <Renderif isTrue={!state.showPassword}>
                                        <BsEyeSlash size={30}></BsEyeSlash>
                                        </Renderif>
                                    </div>
                                </div>
                                <div className='text-danger f-12'>{state.errors?.password}</div>

                            <div className='d-block d-md-none'>
                                <div className='f-16 bold mt-3 mb-1'>Confirmar contraseña</div>
                                <input type={state.showPassword ? 'text':'password'} value={state.confirmPassword} onChange={(evt)=>{
                                    setState({...state, confirmPassword: evt.currentTarget.value})
                                }} className='nu_input' placeholder='Confirmar contraseña'></input>
                                <div className='text-danger f-12'>{state.errors?.confirmPassword}</div>
                            </div>

                            <div className='f-16 bold mt-3 mb-1'>DNI <span className='f-10 bold'></span></div>
                            <input type='text' value={state.nif} onChange={(evt)=>{
                                setState({...state, nif: evt.currentTarget.value})
                            }}  className='nu_input' placeholder='DNI'></input>
                            <div className='text-danger f-12'>{state.errors?.nif}</div>
                          
                        </div>

                        <div className='col-12 col-md-6 ps-0 ps-md-4'>
                            <div className='f-16 bold mt-3 mb-1'>Código postal</div>
                            <input type='number' value={state.postal_code} onChange={(evt)=>{
                                setState({...state, postal_code: evt.currentTarget.value})
                            }} className='nu_input' placeholder='Código postal'></input>
                            <div className='text-danger f-12'>{state.errors?.postal_code}</div>

                            <div className='f-16 bold mt-3 mb-1'>Correo electrónico</div>
                            <input type='text' value={state.email} onChange={(evt)=>{
                                setState({...state, email: evt.currentTarget.value})
                            }} className='nu_input' placeholder='Correo electrónico'></input>
                            <div className='text-danger f-12'>{state.errors?.email}</div>

                            <div className='d-none d-md-block'>

                                <div className='f-16 bold mt-3 mb-1'>Confirmar contraseña</div>
                                    <input type={state.showPassword ? 'text' : 'password'} value={state.confirmPassword}
                                    onChange={(evt) => {
                                    const newConfirmPassword = evt.currentTarget.value;
                                    let newErrors = { ...state.errors };
                                    if (!newConfirmPassword) {
                                    newErrors.confirmPassword =
                                        "Confirmar la contraseña es obligatorio";
                                    } else if (newConfirmPassword !== state.password) {
                                    newErrors.confirmPassword =
                                        "Las contraseñas no coinciden";
                                    } else {
                                    delete newErrors.confirmPassword;
                                    }
                                    setState({
                                    ...state,
                                    confirmPassword: newConfirmPassword,
                                    errors: newErrors,
                                    });
                                }}
                                className='nu_input' placeholder="Confirmar contraseña *"></input>
                                <div className='text-danger f-12'>{state.errors?.confirmPassword}</div>

                          </div>


                        </div>
                    </div>                       
                </div>

                    <div className='mt-3'>
                        <div className='d-flex justify-content-start align-items-start'>
                            <CheckBox checked={state.tycos} onChange={(evt)=>{
                          
                                setState({...state, tycos:evt.value})
                            }}   text=""></CheckBox>
                            <div>He leído y estoy de acuerdo con la <span className='cursor-pointer bold text-lightblue text-decoration-underline' onClick={()=>{
                                setState({...state, showTyCos:true})
                            }}>Política de Privacidad</span> de nursea.es. <br></br></div>
                          
                        </div>
                        <div className='text-danger f-12'>{state.errors?.tycos}</div>
                    </div>

                    {/* <div className='mt-5'>
                        <div className='d-flex justify-content-evenly'>
                            <CheckBox checked={state.rol_selected === 0} onChange={(value)=>{setState({...state, rol_selected:0})}}   text="Efermeria"></CheckBox>
                            <CheckBox checked={state.rol_selected === 1} onChange={(value)=>{setState({...state, rol_selected:1})}}  text="TCAE"></CheckBox>
                            <CheckBox checked={state.rol_selected === 2} onChange={(value)=>{setState({...state, rol_selected:2})}}  text="Estudiante de enfermeria"></CheckBox>
                        </div>
                    </div> */}

                   


                    <div className='mt-5'>
                        <div className='nu_btn nu_btn--overlay' onClick={()=>{
                            /* When docusign will be implemented please comment the call of 'onRegister' and uncomment 'onSign' */
                            // onSign();
                            onRegister();

                            // switch (state.rol_selected) {
                            //     case 0:
                            //         navigate('/register/enfermeria')
                            //         break;
                            //     case 1:
                            //         navigate('/register/tcae')
                            //         break;
                            //     case 2:
                            //         navigate('/register/estudiante')
                            //         break;
                            
                            //     default:
                            //         break;
                            // }
                        }}>
                            <div className='nu_btn-text f-18'>Siguiente</div>
                            <div className='nu_btn-overlay'></div>
                        </div>
                    </div>

                    <div className='d-block d-md-none my-5'></div>

                    
                </div>



                <div className='col'></div>
                

                {/* <div className='d-flex justify-content-evenly my-3 mx-2'>
                    <div className='f-12 bold text-decoration-underline cursor-pointer'>Terminos y condiciones</div>
                    <div className='f-12 bold text-decoration-underline cursor-pointer'>Aviso de privacidad</div>
                </div> */}

                
                
            </div>

        </div>

    </div>
    </>
  )
}
