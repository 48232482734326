import React, { useEffect, useState } from 'react'
import CheckBox from './nu_checkbox'

export default function NuComboBox({...props}) {


    const [state, setState] = useState({
        value:[],
        selected:null
    })





    const _onChange =  (value) => {

        if(!props.multiple){
            
            state.value = [value] 
            state.selected = value;
            setState({...state})
            props.onChange(state.value)
            return
        }
     

        if(value.value){
            state.value.push(value)  
        }else{
            state.value = state.value.filter((item)=> item.key !== value.key);
        }
        props.onChange(state.value)
    }

 

    if(props.checks.length === 1 && props.first === true){
        let label = typeof(props.checks[0]) === 'string' ? props.checks[0] : props.checks[0][props.labelKey]
        return (
            <>
            <CheckBox showkey={props.showkey} checked={true} text={label} onChange={(value)=>{
                _onChange(value)
            }}></CheckBox>
            </>
        )
    }

  return (
    <>
        {props.checks.map((check, i)=>{

        
            let label = typeof(check) === 'string' ? check : check[props.labelKey]
          
        
            if(props.first && i === 0){
                _onChange({
                    key:check,
                    value:true
                })
                return(
                    <CheckBox className={props.className} key={i} showkey={props.showkey} checked={true} text={label} onChange={(value)=>{
                     
                        _onChange(value)
                    }}></CheckBox>
                )
            }
            return (
                <CheckBox className={props.className}  key={i} showkey={props.showkey} checked={state.selected?.key === check ? true : false} value={check} text={label} onChange={(value)=>{
             
                    _onChange(value)
                }}></CheckBox>
            )
        })}
    </>
  )
}
