import React, { useContext } from 'react'
import { IoMdClose, IoMdCloseCircle } from 'react-icons/io';
import { AuthContext } from '../../context/auth_context';
import { darkBlue } from '../../styles/colors';
import { months } from '../../utils';

export default function TycosViewProfesional({onAccept, onClose, name, dni}) {
   


let today = new Date();
  return (
    <div className='overlay'>


        <div className='letter hide-scroll absolute-center'>
            <div className='container'>
                   <div className='d-flex p-3'>
                    <div className='col'></div>
                    <div className='bv-icon bv-icon--mid cursor-pointer' onClick={()=>{
                        onClose(true)
                    }}>
                        <IoMdCloseCircle size={30} color={darkBlue}></IoMdCloseCircle>
                    </div>
                </div>

                <div className='f-16 bolder text-darkblue px-5 text-center pb-4 text-uppercase'>
                    Hoja de información y consentimiento informado <br></br>para el tratamiento de datos
                </div>


                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    CAM HEALTH, SL tiene como objetivo enlazar profesionales con centros sanitarios para cubrir turnos disponibles. Para ello, CAM HEALTH, SL. permite el registro de ambos profesionales y centros sanitarios para poder unir las necesidades a través de un formulario que describe la formación y preferencias de los profesionales con los criterios de selección de los turnos publicados por parte del Centro Sanitario.
                    </div>
                </div>

                <div className='my-3 px-5'>
                    <div className='f-14 text-justify'>
                    En aplicación al nuevo Reglamento (UE) 2016/679 del Parlamento Europeo y del consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas por lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos, (en adelante RGPD), aplicable a todos los países miembros de la Unión Europea a partir del 25 de mayo de 2018, te informamos sobre el tratamiento de tus datos personales. 
                    </div>
                </div>

                <div className='my-3 px-5'>
                    <div className='f-14 text-justify'>
                    El presente documento tiene como objetivo facilitar la información sobre cómo se recogen, tratan y protegen tus datos personales. La información que aparece en primer lugar es este cuadro en el que queda sintetizada en una primera capa la información más relevante. La información ampliada y detallada se ofrece en www.nursea.es
                    </div>
                </div>
                <table className='tycos_table mx-5 my-5' border={1}>
                    <tr>
                        <td colSpan={2}>
                            <div className='bolder f-14 text-center'>Información básica sobre protección de datos</div>
                        </td>
                    </tr>

                    <tr>
                        <td>Responsable del tratamiento</td>
                        <td>CAM HEALTH, S.L.</td>
                    </tr>
                    <tr>
                        <td>Finalidad del tratamiento</td>
                        <td>Gestión de la inscripción del profesional sanitario a la Plataforma CAM HEALTH, S.L. (www.nursea.es) y confección de su perfil de usuario, para enlazar el profesional con los turnos de personal sanitario disponibles publicados por parte de los centros sanitarios adscritos a la Plataforma</td>
                    </tr>
                    <tr>
                        <td>Categoría de datos</td>
                        <td>Identificativos, académicos y profesionales y de salud</td>
                    </tr>
                    <tr>
                        <td>Legitimación</td>
                        <td>Consentimiento del interesado</td>
                    </tr>
                    <tr>
                        <td>Destinatarios </td>
                        <td>Cesión a los Centros Sanitarios registrados en la Plataforma de CAM HEALTH, S.L. para la publicación de turnos de Enfermería disponibles en su centro</td>
                    </tr>
                    <tr>
                        <td>Derechos</td>
                        <td>Acceso, rectificación, supresión, portabilidad, limitación</td>
                    </tr>
                    <tr>
                        <td>Contacto DPD</td>
                        <td>hola@nursea.es</td>
                    </tr>
                    <tr>
                        <td>Información adicional </td>
                        <td>Puedes consultar la <span className='text-darkblue text-decoration-underline cursor-pointer' onClick={()=>{
                             window.open('https://nursea.es/terminos-y-condiciones/', '_blank')
                        }}>información adicional</span> y detallada sobre protección de datos a continuación o en la web de <span className='text-darkblue text-decoration-underline cursor-pointer' onClick={() => {
                            window.open('https://nursea.es', '_blank')
                        }}>www.nursea.es</span></td>
                    </tr>

                </table>

                <div className='my-3 px-5'>
                    <div className='f-14 text-justify'>
                    Yo, <span className='bolder'>{name}</span>, mayor de edad, con D.N.I. <span className='bolder'>{dni}</span>, actuando en nombre e interés propio, DECLARO QUE:
                    </div>
                </div>

                <div className='my-2 px-5 ms-3 d-flex'>
                    <div className='f-14 pe-2'>1. </div>
                    <div className='f-14 text-justify'>
                    He recibido información sobre la Plataforma de CAM HEALTH, S.L. (www.nursea.es) , al que estoy interesado/a en inscribirme y hacer uso.
                    </div>

                </div>
                <div className='my-2 px-5 ms-3 d-flex'>
                    <div className='f-14 pe-2'>2. </div>
                    <div className='f-14 text-justify'>
                    Se me ha informado sobre todos los aspectos relacionados con la protección de datos respecto de la gestión de datos personales que conlleva la inscripción, y la garantías dades en cumplimiento del RGPD y la LOPD.
                    </div>
                </div>


                <div className='my-3 px-5'>
                    <div className='f-14 text-justify'>
                    Por todo ello, otorgo mi CONSENTIMIENTO para que CAM HEALTH, S.L. (www.nursea.es)  trate mis datos personales, incluidos los datos de salud comunicados por mi, en cumplimiento de las siguientes finalidades:
                    </div>
                </div>

                <div className='my-3 px-5'>
                    <div className='f-14 text-justify'>
                    Gestionar mi inscripción en la Plataforma de CAM HEALTH, S.L. (www.nursea.es)  para la gestión, desarrollo y cumplimiento de los diferentes servicios que ofrece, así como la confección del perfil del usuario, para enlazar el profesional con los turnos de Enfermería disponibles publicados por parte de los centros sanitarios adscritos a la Plataforma

                    </div>
                </div>
                <div className='my-3 px-5'>
                    <div className='f-14 text-justify'>
                    Autorizar la comunicación y cesión de mis datos académicos, identificativos,
profesionales y de salud comunicados por mi, a los Centros Sanitarios registrados
en la Plataforma de CAM HEALTH, S.L. para la publicación de turnos de Enfermería
disponibles en su centro
                    </div>
                </div>
                <div className='my-3 px-5'>
                    <div className='f-14 text-justify'>
                    Enviar correos electrónicos comerciales o promocionales de CAM HEALTH, S.L, (www.nursea.es)  con información de interés profesional y no profesional.
                    </div>
                </div>
                <div className='my-3 px-5'>
                    <div className='f-14 text-justify'>
                    Analizar con fines estadísticos y de uso interno de CAM HEALTH, S.L. (www.nursea.es)  el éxito de las campañas así como la adecuación de la plataforma a las demandas de los usuarios.

                    </div>
                </div>
                <div className='my-3 px-5'>
                    <div className='f-14 text-justify'>
                    El anterior consentimiento se otorga, asimismo, para que CAM HEALTH, S.L. (www.nursea.es) conserve mis datos en tanto se mantenga mi suscripción en la plataforma y posteriormente a mi baja, sólo durante los períodos de prescripción previstos legalmente.

                    </div>
                </div>

                <div className='my-3 px-5'>
                    <div className='f-14 text-justify'>
                    {/* En ______________, a ____ de __________, del año ______. */}
                    </div>
                </div>




              

                <div className='my-5 py-5'></div>



                
                
           
   
            </div>
        </div>

    </div>
  )
}
