import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { MdArrowBack, MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { FaFolder, FaStar } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { AdminSideMenu, ClinicSideMenu, ProfesionalSideMenu } from '../../components/nu_sidemenu';
import { AuthContext } from '../../context/auth_context';
import { Bs0CircleFill, BsBack, BsCheck, BsCheckCircle, BsCheckCircleFill, BsInfo, BsInfoCircleFill, BsThreeDotsVertical, BsUpload } from 'react-icons/bs';
import { useFilePicker } from 'use-file-picker';
import { Service } from '../../services/api';
import NuLoading from '../../components/nu_loading';
import Renderif from '../../utils/renderif';
import { NotificationContext } from '../../context/notification_context';
import { GlobalContext } from '../../context/global_context';
import { FiInfo } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import { darkBlue } from '../../styles/colors';
import { documentsNurse, documentsTCAE } from '../../utils';
export default function DocumentacionUsuarioClinicaView() {

    const {user} = useContext(AuthContext);
    
    const navigate = useNavigate()

    const {idUser} = useParams() 

    const { openFilePicker, filesContent, loading, plainFiles } = useFilePicker({
        accept: '*',
    });
    
    const {notify} = useContext(NotificationContext);

    const [state, setState] = useState({
        show_menu:false,
        loading:false,
        active_file_type:null,
        documents: [],
        _docuemnts:[],
        user:null
    })


  
    useEffect(()=>{
        const init = async () => {
            setState({...state, loading:true})

            let _user = await Service('GET', `user/getById/${idUser}`)
            state.user = _user.data.user;
            console.log(_user.data.user)

            if(state.user.idUserType === 1){
                state._docuemnts = documentsNurse;
            }
            if(state.user.idUserType === 3){
                state._docuemnts = documentsTCAE;
            }

            for (let i = 0; i < state._docuemnts.length; i++) {
                let _doc = await Service('GET', `document/view/${idUser}/${state._docuemnts[i].type}`);
                if(_doc.status === 201){
                    let document = {
                        type:state._docuemnts[i].type,
                        file:{path:_doc.data.url},
                        label:''
                    }
                    state.documents.push(document)
                
                }
            }

            setState({...state, loading:false})
         

        };

        init();
        return () => {}
    },[])

    const onValidate = async () => {
        setState({...state, loading:false})
        let _validate = await Service('PUT', `user/validate/${idUser}`);
        if(_validate.status === 201){
            notify({title:'Listo!', message:'Usuario validado exitosamente.', type:'success'})
        }else{
            notify({title:'Erorr!', message:'No se ha podido validar el usuario.', type:'error'})
        }
   
    }

 


      
    useEffect( ()=>{


        const init = async () => {

            // if(filesContent.length > 0 && state.active_file_type !== null){     
        
            //     setState({...state, loading:true})
            //     await upLoadDocument(plainFiles[0], state.active_file_type)
            //     state.active_file_type = null
            //     setState({...state, loading:false})
            // }

        }
        init();
        

        return () => {}

     
    },[filesContent])


   
    const getDocument = (type) => {

        let _doc = state.documents.filter((item)=>item.type === type);

        if(_doc.length === 0){
            return  {
                file: {path:"Sin documento"}
            }
        }
  
        return _doc[0];
    }

    const validationProgress = (documents) => {
        return (
            <div className='d-flex flex-column justify-content-center bg-dark r-10 py-3 h-100'>
                                
                <Renderif isTrue={documents.length === 8}>
                    <div className='f-16 text-white text-center bold mb-2'>Completada</div>
                </Renderif>
                <Renderif isTrue={documents.length < 8}>
                    <div className='f-16 text-white text-center bold mb-2'>Pendiente</div>
                </Renderif>

                <div className='position-relative d-flex justify-content-center align-items-center py-4'>
                    <img className='position-absolute' src='/assets/circle.png'></img>
                    <div className='text-white text-center bold'>{documents.length}/8</div>
                </div>

                <Renderif isTrue={documents.length == 8}>
                    <div className='f-14 text-white text-center mt-2'>¡Todo listo!</div>
                </Renderif>

            </div>
        )
    }

    
   

  return (
    <>
        <Renderif isTrue={state.loading}>
            <NuLoading></NuLoading>
        </Renderif>
        <div className='container-fluid overflow-hidden d-flex h-100'>


       

            <ClinicSideMenu onClose={()=>{setState({...state, show_menu:false})}} show={state.show_menu} page={'profesionales'}/>
    

            <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

                <div className='bg-white sticky-top pt-1 pt-md-3 px-1 px-md-3'>
        

                    <div className='nu-snack'>
                    <div>
                            <div className='f-22 text-white bold'>Hola, {user?.name}</div>
                            <div className='d-flex align-items-center'>
                                <div className='nu-icon nu-icon--mid'>
                                    <IoMdStar color='#FFB054' size={24}></IoMdStar>
                                </div>

                                <div className='f-16 text-white'>5.0 (0 reseñas)</div>

                            </div>
                        
                    </div>
                    <div className='col'></div>
                        <div className='avatar avatar--mid' style={{backgroundImage: `url(${user?.picture})`}}></div>
                        <div className='nu-snack-overlay'></div>
                        <div className='ms-2 d-block d-sm-none'>
                        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                            setState({...state, show_menu:true})
                        }}>
                            <BsThreeDotsVertical size={50} color='white'></BsThreeDotsVertical>
                        </div>
                    </div>
                    </div>
                        
                </div>

                {/* <div className='container mt-3 p-3'>
                    <div className='d-flex'>
                        <div className='col-10 pe-3'>
                            <div className='d-flex flex-column justify-content-center bg-darkblue r-10 p-3 h-100'>
                                <div className='f-22 text-white bolder'>Subir documentación</div>
                                <div className='f-16 text-white my-3'>Para poder validar tu perfil, puedas asignarte turnos y podamos tramitar tu contrato con el Centro Sanitario, necesitaremos los siguientes documentos. Puedes subir imágenes o PDFs de 100 MB tamaño máximo.</div>
                            </div>
                        </div>
                        <div className='col-2'>

                            {validationProgress(documents)}

                        </div>
                    </div>
                </div> */}

                <div className='container-fluid container-xl mx-auto mt-3 p-3'>

                    <div className='d-flex align-items-center cursor-pointer' onClick={()=>{
                        navigate(-1)
                    }}>
                            <div className='nu-icon nu-icon--mid'>
                                <MdArrowBack size={17} color={darkBlue}></MdArrowBack>
                            </div>
                            <div className='f-14 bold text-darkblue'>Atrás</div>
                    </div>


                    <div className='f-22 bold ps-1'>Documentación del profesional</div>


                    <div className='bg-light r-10 p-3 mt-3'>
                        <div className='d-flex flex-wrap align-items-center'>

                            <div>
                                <div className='avatar avatar--mid'></div>
                            </div>

                            <div className='ps-3 '>
                                <div className='f-16 bolder'>{state.user?.name}</div>

                                <Renderif isTrue={state.user?.idUserType === 1}>
                                    <div className='f-14 text-gray line-0'>Enfermera/o</div>
                                </Renderif>
                               <Renderif isTrue={state.user?.idUserType === 3}>
                                    <div className='f-14 text-gray line-0'>Enfermera/o</div>
                                </Renderif>
                            

                            </div>
                            <div className='col'></div>

                           <div className='d-flex flex-column mt-3 mt-md-0'>
                                <div>
                                    <span className='bold'>DNI:</span> {state.user?.cif}
                                </div>
                              
                                <div>
                                    <span className='bold'>Colegio:</span> {state.user?.idUserType === 1 ? state.user?.nurseData?.college : state.user?.tcaeData?.college}
                                </div>
                                <div>
                                    <span className='bold'>No. de Colegiado:</span> {state.user?.idUserType === 1 ? state.user?.nurseData?.collegiateNumber : state.user?.tcaeData?.collegiateNumber }
                                </div>
                           </div>
                         
                            

                        </div>
                    </div>

                    <div className='d-flex flex-wrap'>

                        {
                        state._docuemnts.map((item, i)=>{
                            
                                return (

                                    <div key={i} className={`col-12 col-md-6 py-2 mt-md-3 ${i%2 ? 'ps-md-3' : ''}`}>
                                        <div className='bg-light r-10 p-3'>
                                            
                                            <div className='d-flex align-items-center pb-2 ps-1'>
                                                <div className='f-16 bold'>{item.label}</div>
                                                <div className='col'></div>
                                                <Renderif isTrue={getDocument(item.type).file?.path !== 'Sin documento'}>
                                                    <div className='nu-icon nu-icon--mid'>
                                                        <BsCheckCircle color='green' size={30}></BsCheckCircle>
                                                    </div>
                                                </Renderif>
                                                <Renderif isTrue={getDocument(item.type).file?.path === 'Sin documento'}>
                                                    <div className='nu-icon nu-icon--mid'>
                                                        <FiInfo color='red' size={30}></FiInfo>
                                                    </div>
                                                </Renderif>
                                            </div>
                
                                            <div className='d-flex  align-items-center'>
                
                                                <div className='col pe-2'>
                                                    <input disabled placeholder={getDocument(item.type).file?.path} className='nu-input--light nu-input--disabled'></input>
                                                </div>
                
                                                <div className={`nu_btn  py-2 ${getDocument(item.type).file?.path !== 'Sin documento' ? 'nu_btn--primary' : 'nu_btn--disabled' }`} onClick={()=>{ 
                                                    
                                                    if(getDocument(item.type).file?.path !== 'Sin documento'){
                                                        window.open(getDocument(item.type).file?.path, '_blank')
                                                    }
                                                   

                                                    }}>
                                                    <div className='nu-icon nu-icon--mid me-2'>
                                                        <FaFolder size={18}></FaFolder>
                                                    </div>
                                                    <div className='nu_btn-text'>Ver archivo</div>
                                                </div>
                
                                            </div>
                                        </div>
                                    </div>



                                )
                            })
                        }

                    

                    

                    </div>
                </div>


                <div className='my-5 py-5'></div>



        
        
            </div>

    
    
    
        
        </div>
    </>
  )
}
