import React, { useContext, useEffect } from 'react'
import { useState } from 'react';
import { IoMdClose, IoMdCloseCircle } from 'react-icons/io';
import { AuthContext } from '../../context/auth_context';
import { GlobalContext } from '../../context/global_context';
import { Service } from '../../services/api';
import { darkBlue } from '../../styles/colors';
import { months, UTCDateParser } from '../../utils';

export default function TurnoConfirmationView({onAccept, onClose, turno}) {
    const {user, user_data} = useContext(AuthContext)
    const {
        getCenter
    } = useContext(GlobalContext)

    const [state, setState] = useState({
        organization:null,
        
    })
    


    useEffect(()=>{

        const init = async () => {
    
            let _organization = await Service('GET', `organizationData/getById/${turno.idOrganization}`)
    
            setState({...state, organization:_organization.data.organizationData})
        };
        init();
    },[])

let today = new Date();
  return (
    <div className='overlay'>


        <div className='letter hide-scroll absolute-center'>
            <div className='container'>

                <div className='d-flex p-3'>
                    <div className='col'></div>
                    <div className='bv-icon bv-icon--mid cursor-pointer' onClick={()=>{
                        onClose(true)
                    }}>
                        <IoMdCloseCircle size={30} color={darkBlue}></IoMdCloseCircle>
                    </div>
                </div>

                <div className='f-18 bolder text-darkblue px-5'>
                    CARTA DE COMPROMISO DE PRESTACIÓN DE <br></br> SERVICIO DEL PROFESIONAL
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14'>
                    Por el medio de la presente, yo, <span className='text-darkblue bolder'>{user?.name}</span>, con titulación de (enfermera/ TCAE) con DNI <span className='text-darkblue bolder'>{user?.cif_nif}</span> me comprometo con NURSEA (CAM HEALTH S.L.) a realizar el turno completo solicitado a través de Nursea.es en el centro sanitario <span className='text-darkblue bolder'>{getCenter(turno?.idCenter)?.name}</span>, el día <span className="text-darkblue bolder">{UTCDateParser(new Date(turno.date).toUTCString(), true)}</span>, y acepto las condiciones de prestación de servicio ofertadas por el centro sanitario.
                    </div>
                </div>
                
                <div className='mt-3 px-5'>
                    <ul>
                        <li>
                            <div className='f-14 pb-2'>
                            Confirmo que la experiencia y formación declarada es fiel a la realidad.
                            </div>
                        </li>
                        <li>
                            <div className='f-14 pb-2'>Acepto el precio bruto definido en la oferta publicada.</div>
                        </li>
                        <li>
                            <div className='f-14 pb-2'>
                            Realizaré la cobertura en la ubicación seleccionada y definida en la oferta, siendo esta no modificable sin mi consentimiento expreso. La modificación de la unidad de cobertura del profesional implicaría una sanción económica a la empresa ofertante por incumplimiento de contrato. En caso de producirse dicho incumplimiento me comprometo a comunicarlo a <span className='bold'>CAM HEALTH S.L.</span>
                            </div>
                        </li>
                        <li>
                            <div className='f-14 pb-2'>
                            Acepto el horario de inicio y fin de cobertura definido en oferta publicada, siendo este no extensible sin mi consentimiento expreso y puesto en conocimiento de <span className='bold'>CAM HEALTH S.L</span> 
                            </div>
                        </li>
                        <li>
                            <div className='f-14 pb-2'>
                            <span className='bold'>CAM HEALTH S.L.</span> no comparte en ningún momento tus datos personales, ni de contacto, más allá de los estrictamente necesarios para formalizar la contratación.
                            </div>
                        </li>
                    </ul>
                </div>

                <div className='mt-2 px-5'>
                    <div className='f-14'>
                    En caso de no poder asistir al turno asignado, deberías comunicarte lo antes posible al siguiente contacto. {state.organization?.name},  {state.organization?.email},  {state.organization?.phone_number}

                    </div>
                </div>

                {/* <div className='px-5 mt-2 f-12'>
                    En
                    <span className=' f-12 text-darkblue bold'> Barcelona, ESP </span> a 
                    <span className=' f-12 text-darkblue bold'> {today.getDate()}</span> de 
                    <span className=' f-12 text-darkblue bold'> {months[today.getMonth()]}</span> del 
                    <span className=' f-12 text-darkblue bold'> {today.getFullYear()}</span>
                </div> */}
                <div className='d-flex mt-3'>
                        <div className='col'></div>
                        <div className='nu_btn nu_btn--overlay' onClick={()=>{
                            // navigate('/clinic/publicar')
                            onAccept(true)
                        }}>
                            <div className='nu_btn-text f-18 px-5'>Acepto</div>
                            <div className='nu_btn-overlay'></div>
                        </div>
                        <div className='col'></div>
                    </div>
                    <div className='d-block d-md-none my-5'></div>
                  
            </div>
        </div>

    </div>
  )
}
