import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { FaStar } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";
import { ClinicSideMenu, ProfesionalSideMenu } from '../../components/nu_sidemenu';
import { AuthContext } from '../../context/auth_context';
import { Service } from '../../services/api';
import { GlobalContext } from '../../context/global_context';
import { BsThreeDotsVertical } from 'react-icons/bs';
export default function ValoracionesClinicaView() {
    const {user, user_data} = useContext(AuthContext);
    const {getCenter, center} = useContext(GlobalContext);
  

    const [state, setState] = useState({
        show_menu:false,
        valoraciones:[]
    })



    useEffect(()=>{
        const init = async() => {

            // let _centers = center.filter((center) =>  center.idOrganization === user_data.id)
            // console.log(_centers) 
            // let _valoraciones
            // _centers.map(async(center)=>{
            //     _valoraciones = await Service('GET', 'rating/getAll?idCenter='+center.id);
            //     console.log(_valoraciones);
            //     // setState({...state, valoraciones:_valoraciones.data.list})

            // })
           
            let _valoraciones = await Service('GET', 'rating/getAll?page=1&pageCount=9999');
            let valoraciones = []
            console.log(center.filter((center)=>center.idOrganization === user_data?.id ))
            center.filter((center)=>center.idOrganization === user_data?.id).map((item_center)=>{
                
                _valoraciones.data.list.map((valoracion)=>{
                    if(valoracion.idCenter === item_center.id && valoracion.type === '1'){
                        valoraciones.push(valoracion);
                    }
                })
            })


            console.log(_valoraciones)
            setState({...state, valoraciones:valoraciones})
           
        };
        init();
    },[])
  return (
    <div className='container-fluid overflow-hidden d-flex h-100'>


    
        <ClinicSideMenu onClose={()=>{setState({...state, show_menu:false})}} show={state.show_menu} page='valoraciones' />
   


        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-1 pt-md-3 px-1 px-md-3'>
     

                <div className='nu-snack'>
                <div>
                        <div className='f-22 text-white bold'>Hola, {user?.name}</div>
                        <div className='d-flex align-items-center'>
                            <div className='nu-icon nu-icon--mid'>
                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                            </div>

                            <div className='f-16 text-white'>5.0 (0 reseñas)</div>

                        </div>
                    
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid' style={{backgroundImage: `url(${user?.picture})`}}></div>
                    <div className='nu-snack-overlay'></div>
                    <div className='ms-2 d-block d-sm-none'>
                        <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                            setState({...state, show_menu:true})
                        }}>
                            <BsThreeDotsVertical size={50} color='white'></BsThreeDotsVertical>
                        </div>
                    </div>
                </div>
                       
            </div>

            <div className='container-fluid container-xl mx-auto p-3'>


                {/* <div className='col bg-darkblue p-4 r-10'>
                    <div className='f-22 text-white  bold'>Tus valoraciones son privadas</div>
                    <div className='f-16 text-white'>El feedback es anónimo y puede ayudarte a mejorar en tu carrera.</div>
                </div> */}

                <div className='f-22  bold mt-4'>Mis valoraciones</div>
                {state.valoraciones.map((item, i)=>{
                    return (
                        <div key={i} className='bg-gray r-10 p-4 mt-2'>

                            <div>
                                <div className='d-flex'>
                                    <div className='f-16'><span className='bold'>Tipo de Centro:</span> {getCenter(item.idCenter).name}</div>
                                    <div className='col'></div>
                                    <div className='d-flex align-items-center'>
                                        <div className='nu-icon nu-icon--mid'>
                                            <FaStar size={16} color='#FFB054'></FaStar>
                                        </div>
                                        <div className='f-16 bolder'>{item.stars}</div>
                                    </div>
                                </div>
                                {/* <div className='f-16'><span className='bold'>Turno de:</span> {item.type === '0' ? 'Acogida':'Cobertura'}</div> */}
                              
                                <div className='f-16'>
                                {item.comment}
                                </div>
                            </div>
        
                        </div>
                    )
                })}

             

              
        
                

               

              
          
            </div>

       
      
        </div>

   
   
  
     
    </div>
  )
}
