

import { BrowserRouter, Navigate, Route, Router, Routes } from 'react-router-dom';


import React, { useContext } from 'react';
import HomeAdminView from '../views/admin/home_admin_view';
import TurnosAdminView from '../views/admin/turnos_admin_view';
import ProfesionalesAdminView from '../views/admin/profesionales_admin_view';
import ClinisAdminView from '../views/admin/clinics_admin_view';
import ValoracionesAdminView from '../views/admin/valoraciones_admin_view';
import FacturaciónAdminView from '../views/admin/facturacion_admin_view';
import PublicarTurnoAdminView from '../views/admin/publicar_turno_admin_view';
import ClinicaAdminView from '../views/admin/clinica_admin_view';
import { AuthContext } from '../context/auth_context';
import DocumentacionUsurioAdminView from '../views/admin/documentacion_usuario_admin_view';
import PerfilProfesionalAdminView from '../views/admin/perfil_profesional_admin_view';
import PerfilClinicaAdminView from '../views/admin/perfil_clinica_admin_view';

function AdminNavigator() {
  return (
  
    <PrivateRoute>
        <Routes>
            <Route  path="/" element={<HomeAdminView></HomeAdminView>} />
            <Route  path="/turnos" element={<TurnosAdminView></TurnosAdminView>} />
            <Route  path="/profesionales" element={<ProfesionalesAdminView></ProfesionalesAdminView>} />
            <Route  path="/clinics" element={<ClinisAdminView></ClinisAdminView>} />
            <Route  path="/valoraciones/:idUser/:type" element={<ValoracionesAdminView></ValoracionesAdminView>} />
            <Route  path="/facturacion" element={<FacturaciónAdminView></FacturaciónAdminView>} />
            <Route  path="/publicar" element={<PublicarTurnoAdminView></PublicarTurnoAdminView>} />
            <Route  path="/clinica" element={<ClinicaAdminView></ClinicaAdminView>} />
            <Route  path="/documentacion/:idUser" element={<DocumentacionUsurioAdminView></DocumentacionUsurioAdminView>} />
            <Route  path="/profesional/:idUser" element={<PerfilProfesionalAdminView></PerfilProfesionalAdminView>} />
            <Route  path="/clinica/:idUser" element={<PerfilClinicaAdminView></PerfilClinicaAdminView>} />
        </Routes>  
    </PrivateRoute>
    
  );
}



function PrivateRoute({ children }) {
  
  // const { user } = useContext(AuthContext);
  // console.log(user)
  let user = JSON.parse(localStorage.getItem('user'));
  let token = localStorage.getItem('token');
  if(user !== null && token !== null){

    if(user.idUserType === 5){
      return children;
    }
    return<Navigate to="/" />

  }
  return <Navigate to="/" />

  return user?.idUserType === 5 ? children : <Navigate to="/" />;

}

export default AdminNavigator;
