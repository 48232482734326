import React, { useContext, useEffect, useState } from "react";
import { IoMdDocument, IoMdEye } from "react-icons/io";
import { MdArrowBack } from "react-icons/md";
import { IoCloseCircleOutline, IoAddCircleOutline, IoCloseCircle } from "react-icons/io5";

import { IoMdSave, IoMdStar } from "react-icons/io";
import { ClinicSideMenu } from "../../components/nu_sidemenu";
import { darkBlue } from "../../styles/colors";
import Renderif from "../../utils/renderif";
import { AuthContext } from "../../context/auth_context";
import { useNavigate, useParams } from "react-router-dom";
import { Service } from "../../services/api";
import { NotificationContext } from "../../context/notification_context";
import NuLoading from "../../components/nu_loading";
import { useFilePicker } from "use-file-picker";
import CheckBox from "../../components/nu_checkbox";
import NuComboBox from "../../components/nu_combobox";
import { GlobalContext } from "../../context/global_context";
import { BsCheckCircle, BsPersonSlash, BsUpload } from "react-icons/bs";
import { FiInfo } from "react-icons/fi";
import {
  FileSizeValidator,
  FileTypeValidator
} from 'use-file-picker/validators';
import { documentsNurse, documentsTCAE, restrictionReasons, UTCDateParser } from "../../utils";
import { FaFolder } from "react-icons/fa";
import { RiCloseCircleLine } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import NuSelect from "../../components/nu_select";

export default function PerfilProfesionalClinicView() {
  const { user, user_data, getUserDateNurse, updateUserPictue, setUserPicture } =
    useContext(AuthContext);
  const { idUser } = useParams();

  const navigate = useNavigate();
  const { notify } = useContext(NotificationContext);
  const { specialization, centerType, professionalField, assistanceUnit, documents, upLoadDocument, loadDocuments, getRating, getCenterType, getAssistanceUnit, getProfessionalField, center, loadCenters } =
    useContext(GlobalContext);



  // const { filesContent, plainFiles } = useFilePicker({
  //   accept: "*",
  // });

  const [state, setState] = useState({
    user_data: null,
    assistanceUnit: [],
    professionalField: [],

    loading: false,
    editing_general: false,
    editing_preferences: null,
    user: {
      name: null,
      phone: null,
      nif: null,
      postal_code: null,
    },
    nurse_data: {},
    preferredCenter: [],
    preferredProfessionalField: [],
    preferredAssistanceUnit: [],
    educationAssistanceUnit: [],
    educationProfessionalField: [],
    assistanceUnitExperience: [],
    professionalFieldExperience: [],

    password: "",
    passwordConfirmed: "",
    errors: {},
    provincias: [
      {
        provincia: "Andalucía",
        colegios: [
          "Almería",
          "Cádiz",
          "Córdoba",
          "Granada",
          "Huelva",
          "Jaén",
          "Málaga",
          "Sevilla",
        ],
      },
      {
        provincia: "Aragón",
        colegios: ["Zaragoza", "Teruel", "Huesca"],
      },
      {
        provincia: "Principado de Asturias",
        colegios: ["Asturias"],
      },
      {
        provincia: "Illes Balears",
        colegios: ["Illes Balears"],
      },
      {
        provincia: "Canarias",
        colegios: ["Las Palmas", "Santa Cruz de Tenerife"],
      },
      {
        provincia: "Cantabria",
        colegios: ["Cantabria"],
      },
      {
        provincia: "Castilla y León",
        colegios: [
          "Ávila",
          "Burgos",
          "León",
          "Palencia",
          "Salamanca",
          "Segovia",
          "Soria",
          "Valladolid",
          "Zamora",
        ],
      },
      {
        provincia: "Castilla y la Mancha",
        colegios: ["Albacete", "Ciudad Real", "Cuenca", "Guadalajara y Toledo"],
      },
      {
        provincia: "Cataluña",
        colegios: ["Barcelona", "Tarragona", "Girona", "Lleida"],
      },
      {
        provincia: "Comunidad Valenciana",
        colegios: ["Alicante", "Valencia", "Castellón"],
      },
      {
        provincia: "Extremadura",
        colegios: ["Cáceres", "Badajoz"],
      },
      {
        provincia: "Galicia",
        colegios: ["A Coruña", "Lugo", "Ourense", "Pontevedra"],
      },
      {
        provincia: "Murcia",
        colegios: ["Murcia"],
      },
      {
        provincia: "Madrid",
        colegios: ["Madrid"],
      },
      {
        provincia: "Navarra",
        colegios: ["Navarra"],
      },
      {
        provincia: "País Vasco",
        colegios: ["Bizcaia", "Gipuzkoa", "Araba"],
      },
      {
        provincia: "La Rioja",
        colegios: ["La Rioja"],
      },
      {
        provincia: "Ceuta",
        colegios: ["Ceuta"],
      },
      {
        provincia: "Melilla",
        colegios: ["Melilla"],
      },
    ],

    active_file_type:null,
    documents: [],
    _docuemnts:[],
    _turnos:[],
    _turnos_show:5, 
    user:null,
    filter_center:"",
    filter:"",
    organization: null,
    centers: null,
    reasons: [],
    modalToBlock: false,
  });

const { openFilePicker, filesContent, loading, plainFiles, errors } = useFilePicker({
    accept: ['.png', '.pdf', '.jpg', 'jpeg'],
    // accept: ['.png'],
    // accept: "image/*",
   
   validators:[
    // new FileTypeValidator(['jpg', 'png']),
    new FileSizeValidator({ maxFileSize: 10 * 1024 * 1024 /* 50 MB */ })
   ]
});

  useEffect(() => {
    const init = async () => {
      setState({ ...state, loading: true });

      let _assistanceUnit = await Service("GET", `assistanceUnit/getAll`);
      let _professionalField = await Service("GET", `professionalField/getAll`);
      
      let _userdata = await Service("GET", `user/getById/${idUser}`);
  
      let _turnos = await Service('GET', `shift/getAll?idUser=${idUser}`) 

     
      state._turnos = _turnos.data.list?.sort((a,b) => b.date - a.date)


      state.user = _userdata.data.user;
  

      if(state.user.idUserType === 1){
          state._docuemnts = documentsNurse;
      }
      if(state.user.idUserType === 3){
          state._docuemnts = documentsTCAE;
      }

      for (let i = 0; i < state._docuemnts.length; i++) {
          let _doc = await Service('GET', `document/view/${idUser}/${state._docuemnts[i].type}`);
          console.log(_doc)
       
          if(_doc.status === 201){
              let document = {
                  type:state._docuemnts[i].type,
                  file:{path:_doc.data.url},
                  label:''
              }
              state.documents.push(document)
          
          }
      }


      var nurse_data_aux =
        _userdata.data.user.idUserType === 1
          ? _userdata.data.user?.nurseData
          : _userdata.data.user.idUserType === 3
          ? _userdata.data.user?.tcaeData
          : {};

      
      // Info to block user
      let _organization = await Service('GET', `organizationData/getById/${user_data.id}`)
        

      state.organization = _organization.data.organizationData;
      let _centers = await loadCenters()

      state.centers = _centers.filter((center) => center.idOrganization === _organization.data.organizationData.id)
      // Info to block user
      

      setState({
        ...state,
        loading: false,

        user_data: _userdata.data.user,
        assistanceUnit: _assistanceUnit.data.list.filter(
          (item) => item.id !== 16
        ),
        professionalField: _professionalField.data.list,

        user: {
          name: _userdata.data.user?.name,
          phone: _userdata.data.user?.phone_number,
          nif: _userdata.data.user?.cif,
          postal_code: _userdata.data.user?.postal_code,
        },
        nurse_data: {
          ...nurse_data_aux,
        },
        preferredCenter: _userdata.data.user.preferredCenter,
        preferredProfessionalField:
          _userdata.data.user.preferredProfessionalField,
        preferredAssistanceUnit: _userdata.data.user.preferredAssistanceUnit,
        educationAssistanceUnit: _userdata.data.user.educationAssistanceUnit,
        educationProfessionalField:
          _userdata.data.user.educationProfessionalField,
        assistanceUnitExperience: _userdata.data.user.assistanceUnitExperience,
        professionalFieldExperience:
          _userdata.data.user.professionalFieldExperience,
      });
    };
    init();
    return () => {};
  }, []);

  useEffect( ()=>{


    const init = async () => {
        // console.log(errors)
        // if(errors.length > 0){
        //     notify({title:'¡Error!', message:'Debes subir un archivo no mayor a 10MB', type:'error'})
        // }else{

        //     if(filesContent.length > 0 && state.active_file_type !== null){     
        
        //         setState({...state, loading:true})
        //         await upLoadDocument(plainFiles[0], state.active_file_type)
        //         await loadDocuments();
        //         state.active_file_type = null
        //         setState({...state, loading:false})
                
        //     }
        // }

    }
    init();
    

    return () => {}

 
},[filesContent, errors])

let _documents = []

   

    if(user?.idUserType == 1){
        _documents = documentsNurse;
    }else{
        _documents = documentsTCAE;
    }



    const getDocument = (type) => {

      let _doc = state.documents.filter((item)=>item.type === type);

      if(_doc.length === 0){
          return  {
              file: {path:"Sin documento"}
          }
      }

      return _doc[0];
  }

  const nurse_data = [
    {
      title: "Años de experiencia que tienes en la profesión",
      tags: [],
    },
    {
      title: "Especialización EIR",
      tags: [],
    },
    {
      title: "Centros donde tienes interés en realizar turnos",
      tags: [],
    },
    {
      title: "Ámbitos profesionales donde tienes interés en realizar turnos",
      tags: [],
    },
    {
      title: "Unidades Asistenciales donde tienes interés en realizar turnos",
      tags: [],
    },
    {
      title:
        "Dispones de formación tipo master o postgrado relacionados con las siguientes unidades asistenciales",
      tags: [],
    },
    {
      title:
        "Dispones de formación tipo master o postgrado relacionados con los siguientes ámbitos profesionales",
      tags: [],
    },
  ];
  const tcae_data = [
    {
      title: "Años de experiencia que tienes en la profesión",
      tags: [],
    },
    {
      title: "",
      tags: [],
    },

    {
      title: "Centros donde tienes interés en realizar turnos",
      tags: [],
    },
    {
      title: "Ámbitos profesionales donde tienes interés en realizar turnos",
      tags: [],
    },
    {
      title: "Unidades Asistenciales donde tienes interés en realizar turnos",
      tags: [],
    },
    {
      title: "",
      tags: [],
    },
    {
      title: "",
      tags: [],
    },
  ];

  const profile_data = () => {
    if (state.user_data?.idUserType === 1) {
      return nurse_data;
    }
    if (state.user_data?.idUserType === 3) {
      return tcae_data;
    }
  };

  const checkPassword = (password) => {
    var re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-/]).{8,}$/;
    // var re = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    return re.test(password);
  };

  const validator = () => {
    state.errors = {};

    if (!checkPassword(state.passwordConfirmed)) {
      state.errors.password =
        "Debe tener mínimo 8 caracteres, 1 letra Mayúscula, 1 letra minúscula, 1 número y 1 carácter especial (!&%#*?-^/)";
    }

    // if(state.passwordConfirmed !== state.password){
    //     state.errors.confirmPassword = 'Las contraseñas no coinciden'
    // }

    setState({ ...state });
    if (Object.keys(state.errors).length > 0) {
      return false;
    }
    return true;
  };

  const onUpdateProfile = async () => {
    let body = state.user;

    let update = await Service("PUT", "user/updateProfessional", body);
    if (state.user_data?.idUserType === 1) {
      let _update = await Service("PUT", "nurseData/update", state.nurse_data);
    }

    if (state.user_data?.idUserType === 3) {
      let _update = await Service("PUT", "tcaeData/update", state.nurse_data);
    }

    if (update.status === 201) {
      notify({
        title: "Información",
        message: "Tu datos han sido actualizados",
        type: "success",
      });
    } else {
      notify({
        title: "Información",
        message: "No ha sido posible actualizar tus datos.",
        type: "error",
      });
    }

    if (state.passwordConfirmed !== "") {
      body = {
        password: state.password,
        new_password: state.passwordConfirmed,
      };

      if (!validator()) {
        return;
      }
      let update_password = await Service("PUT", "user/updatePassword", body);

      if (update_password.status === 201) {
        notify({
          title: "Contraseña",
          message: "Tu contraseña ha sido actualizada",
          type: "success",
        });
      } else {
        notify({
          title: "Contraseña",
          message: "No ha sido posible cambiar tu contraseña",
          type: "error",
        });
      }
      setState({
        ...state,
        password: "",
        passwordConfirmed: "",
      });
    }
    getUserDateNurse();
  };

  const assistanceUnitOptionChecked = (value, option) => {
    let _aux = state.assistanceUnitExperience.filter((item) => {
      if (
        item.idAssistanceUnit === option.id &&
        item.yearsOfExperience === value
      ) {
        return true;
      }
      return false;
    });

    if (_aux.length > 0) {
      return true;
    }
    return false;
  };

  const optionChecked = (value, option) => {
    let _aux = state.professionalFieldExperience.filter((item) => {
      if (
        item.idProfessionalField === option.id &&
        item.yearsOfExperience === value
      ) {
        return true;
      }
      return false;
    });

    if (_aux.length > 0) {
      return true;
    }
    return false;
  };

  const yearsExperienceOptions = [
    {
      id: 0,
      desctiprion: "No tengo experiencia",
    },
    {
      id: 1,
      desctiprion: "Menos de un año",
    },
    {
      id: 2,
      desctiprion: "Mas de 1 año",
    },
  ];

  const onBlockProfesional = async () => {
    setState({...state, loading:true})
    
    if(!state.reasons.length) {
      notify({title:'¡Error!', message:'Por favor selecciona al menos un motivo.', type:'error'})
      return
    }
    const reasonsBlock = state.reasons.sort().map((item) => restrictionReasons[item])
    state.centers.map(async (center, i)=>{
      let body = {
          type:'0',
          idCenter:center.id,
          idUser:idUser,
          reason: JSON.stringify(reasonsBlock),
      }
      
      let _block = await Service('POST', 'restriction/create', body);

      if(_block.status === 201){
        notify({title:'¡Listo!', message:`Profesional bloqueado exitosamente en el centro ${center.name}.`, type:'success'})
      }else{
        if(_block.response.status === 400){
          notify({title:'Profesional bloqueado!', message:`Ya has bloqueado este profesional en el centro ${center.name}.`, type:'error'})  
        }else{
          notify({title:'Error!', message:`Ocurrio un error al itentar bloquear profesional en el centro ${center.name}.`, type:'error'})
        }
      }
    })

    setState({...state, loading:false, reasons:[], modalToBlock:false})
  }

  return (
    <>
      <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
      </Renderif>

      <div className="container-fluid overflow-hidden d-flex h-100">
        <ClinicSideMenu page="profesionales" />

        <div className="container-fluid overflow-auto hide-scroll col  mx-auto">
          <div className="bg-white sticky-top pt-3 px-3">
            <div className="nu-snack">
              <div>
                <div className="f-22 text-white bold">Hola, {user?.name}</div>
                <div className="d-flex align-items-center">
                  <div className="nu-icon nu-icon--mid">
                    <IoMdStar color="#FFB054" size={24}></IoMdStar>
                  </div>
                  <div className="f-16 text-white">5.0 (0 reseñas)</div>
                </div>
              </div>
              <div className="col"></div>
              <div
                className="avatar avatar--mid"
                style={{ backgroundImage: `url(${user?.picture})` }}
              ></div>
              <div className="nu-snack-overlay"></div>
            </div>
          </div>

          <div className="container-fluid container-xl mx-auto p-3">
        
       

                <div
                  className="d-flex align-items-center cursor-pointer mb-3"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <div className="nu-icon nu-icon--mid">
                    <MdArrowBack size={17} color={darkBlue}></MdArrowBack>
                  </div>
                  <div className="f-14 bold text-darkblue">Atrás</div>
                </div>

                <div className="d-flex align-items-center ">

                
                  <div className='f-18 bolder'>Perfil</div>

          
                  <div className='col'></div>
                  
                  <div className='d-flex me-2'>
                   
                    <div className='nu_btn nu_btn--danger  w-100' onClick={()=>{
                                    setState({...state, modalToBlock:true})
                                  }}>
                                      <div className='nu-icon nu-icon--mid me-1'>
                                          <BsPersonSlash size={20}></BsPersonSlash>
                                      </div>
                                      <div className='nu_btn-text f-14'>Bloquear profesional</div>
                    </div>
                    
                  </div>

                  <div className='d-flex'>
                 
                      <div className='nu_btn nu_btn--primary' onClick={()=>{
                   
                         
                                navigate('/clinic/publicar/', { state: {profesional:{id:parseInt(idUser), ...state.user}} } )
                      }}>
                          <div className='nu-icon nu-icon--mid '>
                              <CiEdit color="white" size={20}></CiEdit>
                          </div>
                          <div className='nu_btn-text f-14 px-1 bold'>Publicar nuevo turno</div>
                          {/* <div className='nu_btn-overlay'></div> */}
                      </div>
                  </div>

                </div>

                <div className="d-flex align-items-center bg-light r-10 p-3 mt-2">

                <div className="d-none d-lg-flex flex-column align-items-center me-3">
                  <div className="avatar avatar--mid"></div>
                </div>

                <div className="col">

                  <div className="d-flex flex-wrap">
                    <div className="col-6 col-lg-2">
                      <div className="bold">{state.user_data?.name} {state.user_data?.lastName}</div>
                    </div>
                    <div className="col-6 col-lg-2"><span className="bold">DNI:</span>{state.user_data?.cif}</div>
                    <div className="col-6 col-lg-4"><span className="bold">Colegio:</span> {state.user_data?.idUserType === 1 ? state.user_data?.nurseData?.college : state.user_data?.tcaeData?.college}</div>
                    <div className="col-6 col-lg-4"><span className="bold">No. de Colegiado:</span> {state.user_data?.idUserType === 1 ? state.user_data?.nurseData?.collegiateNumber : state.user_data?.tcaeData?.collegiateNumber }</div>
                  </div>


                  <div className="d-flex flex-wrap mt-2">
                    <div className="col-6 col-lg-2">
                      <div>Enfermera</div>
                    </div>
                    <div className="col-6 col-lg-2">
                      <div className="d-flex align-items-center">
                        <div className="nu-icon nu-icon--mid">
                          <IoMdStar color="#FFB054" size={24}></IoMdStar>
                        </div>
                        <div className="f-16">{state.user_data?.stars?.toFixed(2)}</div>
                      </div>
                    </div>

                    {/* <div className="col-6 col-lg-1"><span className="bold">Nivel:</span></div> */}
                    <div className="col-6 col-lg-3"><span className="bold">Años de Experiencia:</span>{state.user_data?.idUserType === 1 ? state.user_data?.nurseData?.yearsOfExperience : state.user_data?.tcaeData?.yearsOfExperience }</div>
                    <div className="col-6 col-lg-2"><span className="bold">Turnos completados:</span>{state._turnos.filter((turno)=> turno.status === "2").length}</div>
                  </div>
                </div>

                </div>

                <div className="d-flex align-items-center mt-4">
                    <div className="f-18 bolder">Turnos</div>
                </div>
                
                <div className="d-flex align-items-end w-100  ">
                  
                 
               <div>
               <NuSelect className="nu-select--sm" value={state._turnos_show} onChange={(evt)=>{
                    setState({...state, _turnos_show:evt.currentTarget.value })
                  }}>
                    <option>5</option>
                    <option>10</option>
                    <option>20</option>
                    <option>50</option>
                    <option>100</option>
                  </NuSelect>
               </div>


                  <div className="col"></div>

                 <div className="col-2 ms-1">
                    <div className="f-12 bold text-gray">Centro</div>
                    <NuSelect className={'nu-select--sm'} value={state.filter_center} onChange={(evt)=>{
                      setState({...state, filter_center:evt.currentTarget.value })
                    }}>
                      {/* <option value="">Filtrar por:</option> */}
                      <option value="">Todos</option>
                      {center.filter((item)=>item.idOrganization === user_data?.id).map((item,i)=>{
                                    
                                    return (
                                        <option key={i} value={item.id}>{item.name}</option> 
                                    )
                                })}
              
                    
                    </NuSelect>
                 </div>

                 <div className="col-2 ms-1">
                 <div className="f-12 bold text-gray">Status</div>
                 <NuSelect className="nu-select--sm" value={state.filter} onChange={(evt)=>{
                    setState({...state, filter:evt.currentTarget.value })
                  }}>
                    {/* <option value="">Filtrar por:</option> */}
                    <option value="">Todos</option>
                    <option value="1">Asignados</option>
                    <option value="2">Completados</option>
                  
                  </NuSelect>
                 </div>

                 
                </div>


                <div className="d-flex mt-2 w-100 ">
           

                    <div className="bg-gray w-100 r-10 mt-2 overflow-auto hide-scroll">
                      
                      <table className="w-100 p-3">
                        <thead className="p-3">
                          <th className="p-2 f-14">Status</th>
                          <th className="p-2 f-14">Día</th>
                          <th className="p-2 f-14">Horario</th>
                   
                          <th className="p-2 f-14">Centro</th>
                          <th className="p-2 f-14">Tipo de Centro</th>
                          <th className="p-2 f-14">Unidad Asistencial</th>
                          <th className="p-2 f-14">Ambito Profesional</th>
                        </thead>
                       
                        <tbody className="bg-light">
                          {state._turnos
                          .filter((item) => item?.center?.idOrganization === user_data.id)
                          .filter((item) => state.filter === "" ? true : item.status === state.filter )
                          .filter((item) => state.filter_center === "" ? true : item?.center?.id === parseInt(state.filter_center) )
                          .map((turno, i)=>{
                       
                            console.log(turno)


                     
                          
                            if(i > state._turnos_show) return
                            return (
                              <tr key={i}>
                                <td className="p-2">
                                  {turno.status === "1" ? <div className="nu-tag nu-tag--green f-10 bolder px-2 py-1">Asignado</div> : <div className="nu-tag nu-tag--blue f-10 bolder px-2 py-1">Completado</div>}
                                </td>
                                <td className="p-2"><div className='f-12 bold ellipsis'>{UTCDateParser(new Date(turno.date).toUTCString(), true)}</div></td>
                                <td className="p-2"><div className="f-12 bold ellipsis">{turno.initialHour} - {turno.finalHour}</div></td>
                                {/* <td className="p-2"><div className="f-12 bold"></div></td> */}
                                <td className="p-2"><div className="f-12 bold ellipsis">{turno?.center?.name}</div></td>
                                <td className="p-2"><div className="f-12 bold ellipsis">{getCenterType(turno?.idCenterType)}</div></td>
                                <td className="p-2"><div className="f-12 bold ellipsis">{getAssistanceUnit(turno?.idAssistanceUnit)}</div></td>
                                <td className="p-2"><div className="f-12 bold ellipsis">{getProfessionalField(turno?.idProfessionalField)}</div></td>
                               
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                </div>

                <div className="d-flex align-items-center mt-4">
                    <div className="f-18 bolder">Documentos</div>
                </div>

                <div className='d-flex flex-wrap mt-2 '>
                  
                {
                        state._docuemnts.map((item, i)=>{
                          console.log(item)
                            
                                return (

                                    <div key={i} className={`col-12 col-md-6 py-2 mt-md-3 ${i%2 ? 'ps-md-3' : ''}`}>
                                        <div className='bg-light r-10 p-3'>
                                            
                                            <div className='d-flex align-items-center pb-2 ps-1'>
                                                <div className='f-16 bold'>{item.label}</div>
                                                <div className='col'></div>
                                                <Renderif isTrue={getDocument(item.type).file?.path !== 'Sin documento'}>
                                                    <div className='nu-icon nu-icon--mid'>
                                                        <BsCheckCircle color='green' size={30}></BsCheckCircle>
                                                    </div>
                                                </Renderif>
                                                <Renderif isTrue={getDocument(item.type).file?.path === 'Sin documento'}>
                                                    <div className='nu-icon nu-icon--mid'>
                                                        <FiInfo color='red' size={30}></FiInfo>
                                                    </div>
                                                </Renderif>
                                            </div>
                
                                            <div className='d-flex  align-items-center'>
                
                                                <div className='col pe-2'>
                                                    <input disabled placeholder={getDocument(item.type).file?.path} className='nu-input--light nu-input--disabled'></input>
                                                </div>
                
                                                <div className={`nu_btn  py-2 ${getDocument(item.type).file?.path !== 'Sin documento' ? 'nu_btn--primary' : 'nu_btn--disabled' }`} onClick={()=>{ 
                                                    
                                                    if(getDocument(item.type).file?.path !== 'Sin documento'){
                                                        window.open(getDocument(item.type).file?.path, '_blank')
                                                    }
                                                   

                                                    }}>
                                                    <div className='nu-icon nu-icon--mid me-2'>
                                                        <FaFolder size={18}></FaFolder>
                                                    </div>
                                                    <div className='nu_btn-text'>Ver archivo</div>
                                                </div>
                
                                            </div>
                                        </div>
                                    </div>



                                )
                            })
                        }

                </div>

      


                <div className="bg-gray r-10 p-3 mt-5  d-none">
                  <div className="d-flex">
                    <div className="f-22 bold mb-3">Profesional</div>
                    <div className="col"></div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center  mt-2">
                    <div className="col">
                      <div className="f-16 pe-5 bold">Nombre Completo</div>
                    </div>
                    <div className="col">
                      <input
                        disabled={!state.editing_general}
                        defaultValue={state.user_data?.name}
                        onChange={(evt) => {
                          state.user.name = evt.currentTarget.value;
                        }}
                        className={`nu-input--light p-2 ${
                          !state.editing_general ? "nu-input--disabled" : ""
                        }`}
                      ></input>
                    </div>

                    <div className="col">
                      <div className="f-16 ps-5 bold">
                        {state.user_data?.idUserType === 1
                          ? "Colegio"
                          : "Comunidad Autónoma"}
                      </div>
                    </div>
                    <div className="col">
                      <Renderif isTrue={state.user_data?.idUserType === 1}>
                        <select
                          disabled={!state.editing_general}
                          value={state.nurse_data?.college}
                          onChange={(evt) => {
                            state.nurse_data.college = evt.currentTarget.value;
                            setState({ ...state });
                          }}
                          className={`nu-select nu-input--light p-2 ${
                            !state.editing_general ? "nu-input--disabled" : ""
                          }`}
                        >
                          <option value="">Seleccionar</option>
                          {state.provincias
                            .filter(
                              (item) =>
                                item.provincia ===
                                state.nurse_data?.collegeProvince
                            )[0]
                            ?.colegios.map((item, i) => {
                              
                              return (
                                <option key={i} value={item}>
                                  {item}
                                </option>
                              );
                            })}
                        </select>
                      </Renderif>

                      <Renderif isTrue={state.user_data?.idUserType !== 1}>
                        <select
                          disabled={!state.editing_general}
                          value={state.nurse_data?.province}
                          onChange={(evt) => {
                            state.nurse_data.province = evt.currentTarget.value;
                            setState({ ...state });
                          }}
                          className={`nu-select nu-input--light p-2 ${
                            !state.editing_general ? "nu-input--disabled" : ""
                          }`}
                        >
                          <option value="">Seleccionar</option>
                          {state.provincias.map((item, i) => {
                            return (
                              <option key={i} value={item.provincia}>
                                {item.provincia}
                              </option>
                            );
                          })}
                        </select>
                      </Renderif>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center  mt-2">
                    <div className="col">
                      <div className="f-16 pe-5 bold">Teléfono</div>
                    </div>
                    <div className="col">
                      <input
                        disabled={!state.editing_general}
                        defaultValue={state.user_data?.phone_number}
                        className={`nu-input--light p-2 ${
                          !state.editing_general ? "nu-input--disabled" : ""
                        }`}
                        onChange={(evt) => {
                          state.user.phone = evt.currentTarget.value;
                        }}
                      ></input>
                    </div>
                    <div className="col">
                      <div className="f-16 ps-5 bold">DNI</div>
                    </div>
                    <div className="col">
                      <input
                        disabled={!state.editing_general}
                        defaultValue={state.user_data?.cif}
                        className={`nu-input--light p-2 ${
                          !state.editing_general ? "nu-input--disabled" : ""
                        }`}
                        onChange={(evt) => {
                          state.user.nif = evt.currentTarget.value;
                        }}
                      ></input>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center  mt-2">
                    <div className="col">
                      <div className="f-16 pe-5 bold">Correo electrónico</div>
                    </div>
                    <div className="col">
                      <input
                        disabled
                        defaultValue={state.user_data?.email}
                        className={`nu-input--light p-2 nu-input--disabled`}
                      ></input>
                    </div>
                    <div className="col">
                      <div className="f-16 ps-5 bold">Documentos</div>
                    </div>
                    <div className="col">
                      <div
                        className="nu_btn nu_btn--primary"
                        onClick={() => {
                          navigate("/clinic/documentacion/" + idUser);
                        }}
                      >
                        <div className="nu-icon">
                          <IoMdDocument size={14}></IoMdDocument>
                        </div>
                        <div className="nu_btn-text f-12">Ver documentos</div>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center  mt-2">
                    <div className="col">
                      <div className="f-16 pe-5 bold">Código postal</div>
                    </div>
                    <div className="col">
                      <input
                        disabled={!state.editing_general}
                        defaultValue={state.user_data?.postal_code}
                        className={`nu-input--light p-2 ${
                          !state.editing_general ? "nu-input--disabled" : ""
                        }`}
                        onChange={(evt) => {
                          state.user.postal_code = evt.currentTarget.value;
                        }}
                      ></input>
                    </div>
                    <div className="col"></div>
                    <div className="col">
                      <div className="text-danger f-12">
                        {state.errors?.password}
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center  mt-2">
                    <div className="col">
                      <Renderif isTrue={state.user_data?.idUserType === 1}>
                        <div className="f-16 pe-5 bold">No. de Colegiado/a</div>
                      </Renderif>
                    </div>
                    <div className="col">
                      <Renderif isTrue={state.user_data?.idUserType === 1}>
                        <input
                          disabled={!state.editing_general}
                          defaultValue={state.nurse_data?.collegiateNumber}
                          className={`nu-input--light p-2 ${
                            !state.editing_general ? "nu-input--disabled" : ""
                          }`}
                        ></input>
                      </Renderif>
                    </div>

                    <div className="col"></div>
                    <div className="col"></div>
                  </div>

                  <Renderif isTrue={state.user_data?.idUserType === 1}>
                    <div className="d-flex justify-content-center align-items-center  mt-2">
                      <div className="col">
                        <div className="f-16 pe-5 bold">Comunidad Autónoma</div>
                      </div>
                      <div className="col">
                        <select
                          disabled={!state.editing_general}
                          defaultValue={
                            state.user_data?.idUserType === 1
                              ? state.nurse_data?.collegeProvince
                              : state.nurse_data?.province
                          }
                          onChange={(evt) => {
                            if (state.user_data?.idUserType === 1) {
                              state.nurse_data.collegeProvince =
                                evt.currentTarget.value;
                            } else {
                              state.nurse_data.province =
                                evt.currentTarget.value;
                            }
                            setState({ ...state });
                          }}
                          className={`nu-select nu-input--light p-2 ${
                            !state.editing_general ? "nu-input--disabled" : ""
                          }`}
                        >
                          <option value="" disabled>
                            Seleccionar
                          </option>
                          {state.provincias.map((item, i) => {
                            return (
                              <option key={i} value={item.provincia}>
                                {item.provincia}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="col"></div>
                      <div className="col"></div>
                    </div>
                  </Renderif>

                  <Renderif isTrue={state.editing_general}>
                    <div className="d-flex mt-5">
                      <div className="col"></div>

                      <div
                        className="nu_btn nu_btn--primary"
                        onClick={() => {
                          onUpdateProfile();
                        }}
                      >
                        <div className="nu-icon nu-icon--mid me-1">
                          <IoMdSave size={18}></IoMdSave>
                        </div>
                        <div className="nu_btn-text">Guardar</div>
                      </div>
                    </div>
                  </Renderif>
                </div>

                {/* {profile_data()?.map((item, j) => {
                  if (state.user_data?.idUserType === 3 && j === 1) return;
                  if (state.user_data?.idUserType === 3 && j === 5) return;
                  if (state.user_data?.idUserType === 3 && j === 6) return;

                  return (
                    <div key={j} className="bg-gray r-10 p-3 mt-3">
                      <div className="d-flex">
                        <div className="f-16 bold mb-3">{item.title}</div>
                        <div className="col"></div>

                        <div
                          className="d-flex align-items-center cursor-pointer"
                          onClick={() => {
                            if (state.editing_preferences === j) {
                              setState({ ...state, editing_preferences: null });
                              return;
                            }
                            setState({ ...state, editing_preferences: j });
                          }}
                        >
                          <div className="f-14 text-darkblue bold mx-2">
                            Ver
                          </div>
                          <div className="nu-icon nu-icon--mid cursor-pointer">
                            <IoMdEye color={darkBlue} size={24}></IoMdEye>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex flex-wrap">
                        {item.tags.map((tag, i) => {
                          return (
                            <div
                              key={i}
                              className="nu-tag nu-tag--disabled  nu-tag nu-tag--active"
                            >
                              <div>{tag}</div>

                              <Renderif
                                isTrue={state.editing_preferences === j}
                              >
                                <div className="nu-icon nu-icon--mid ms-2">
                                  <IoCloseCircleOutline
                                    size={24}
                                  ></IoCloseCircleOutline>
                                </div>
                              </Renderif>
                            </div>
                          );
                        })}
                      </div>

                      <Renderif isTrue={state.editing_preferences === j}>
                        <Renderif isTrue={j === 0}>
                          <div className="d-flex flex-wrap mt-3">
                            <div
                              className={`nu-tag nu-tag--disabled cursor-pointer ${
                                state.nurse_data.yearsOfExperience === "0"
                                  ? "nu-tag nu-tag--active"
                                  : ""
                              }`}
                              onClick={() => {
                                state.nurse_data.yearsOfExperience = "0";
                                setState({ ...state });
                              }}
                            >
                              <div>Sin experiencia</div>
                              <div className="nu-icon nu-icon--mid ms-2">
                                <IoAddCircleOutline
                                  size={24}
                                ></IoAddCircleOutline>
                              </div>
                            </div>
                            <div
                              className={`nu-tag nu-tag--disabled cursor-pointer ${
                                state.nurse_data.yearsOfExperience === "1"
                                  ? "nu-tag nu-tag--active"
                                  : ""
                              }`}
                              onClick={() => {
                                state.nurse_data.yearsOfExperience = "1";

                                setState({ ...state });
                              }}
                            >
                              <div>1-5 años</div>
                              <div className="nu-icon nu-icon--mid ms-2">
                                <IoAddCircleOutline
                                  size={24}
                                ></IoAddCircleOutline>
                              </div>
                            </div>
                            <div
                              className={`nu-tag nu-tag--disabled cursor-pointer ${
                                state.nurse_data.yearsOfExperience === "2"
                                  ? "nu-tag nu-tag--active"
                                  : ""
                              }`}
                              onClick={() => {
                                state.nurse_data.yearsOfExperience = "2";

                                setState({ ...state });
                              }}
                            >
                              <div>6-10 años</div>
                              <div className="nu-icon nu-icon--mid ms-2">
                                <IoAddCircleOutline
                                  size={24}
                                ></IoAddCircleOutline>
                              </div>
                            </div>
                            <div
                              className={`nu-tag nu-tag--disabled cursor-pointer ${
                                state.nurse_data.yearsOfExperience === "3"
                                  ? "nu-tag nu-tag--active"
                                  : ""
                              }`}
                              onClick={() => {
                                state.nurse_data.yearsOfExperience = "3";

                                setState({ ...state });
                              }}
                            >
                              <div>{"> 10 años"}</div>
                              <div className="nu-icon nu-icon--mid ms-2">
                                <IoAddCircleOutline
                                  size={24}
                                ></IoAddCircleOutline>
                              </div>
                            </div>
                          </div>
                        </Renderif>

                        <Renderif
                          isTrue={j === 1 && state.user_data?.idUserType === 1}
                        >
                          <div className="d-flex flex-wrap mt-3">
                            {specialization.map((specialization, k) => {
                              return (
                                <div
                                  key={k}
                                  className={`nu-tag nu-tag--disabled cursor-pointer ${
                                    state.nurse_data.idSpecialization ===
                                    specialization.id
                                      ? "nu-tag nu-tag--active"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    state.nurse_data.idSpecialization =
                                      specialization.id;
                                    setState({ ...state });
                                  }}
                                >
                                  <div>{specialization.description}</div>
                                  <div className="nu-icon nu-icon--mid ms-2">
                                    <IoAddCircleOutline
                                      size={24}
                                    ></IoAddCircleOutline>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Renderif>

                        <Renderif isTrue={j === 2}>
                          <div className="d-flex flex-wrap mt-3">
                            {centerType.map((center, l) => {
                              return (
                                <div
                                  key={l}
                                  className={`nu-tag nu-tag--disabled cursor-pointer ${
                                    state.preferredCenter.some(
                                      (item) => item.idCenterType === center.id
                                    )
                                      ? "nu-tag nu-tag--active"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    if (
                                      state.preferredCenter.some(
                                        (item) =>
                                          item.idCenterType === center.id
                                      )
                                    ) {
                                      state.preferredCenter =
                                        state.preferredCenter.filter(
                                          (item) =>
                                            item.idCenterType !== center.id
                                        );
                                    } else {
                                      if (center.id === 1) {
                                        state.preferredCenter = [];
                                      } else {
                                        state.preferredCenter =
                                          state.preferredCenter.filter(
                                            (item) => item.idCenterType !== 1
                                          );
                                      }

                                      state.preferredCenter.push({
                                        id: 0,
                                        idCenterType: center.id,
                                        idUser: state.user_data?.id,
                                      });
                                    }
                                    setState({ ...state });
                                  }}
                                >
                                  <div>{center.description}</div>
                                  <div className="nu-icon nu-icon--mid ms-2">
                                    <IoAddCircleOutline
                                      size={24}
                                    ></IoAddCircleOutline>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Renderif>

                        <Renderif isTrue={j === 3}>
                          <div className="d-flex flex-wrap mt-3">
                            {professionalField
                              .filter((item) => item.id !== 7)
                              .map((field, l) => {
                                return (
                                  <div
                                    key={l}
                                    className={`nu-tag nu-tag--disabled cursor-pointer ${
                                      state.preferredProfessionalField.some(
                                        (item) =>
                                          item.idProfessionalField === field.id
                                      )
                                        ? "nu-tag nu-tag--active"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      if (
                                        state.preferredProfessionalField.some(
                                          (item) =>
                                            item.idProfessionalField ===
                                            field.id
                                        )
                                      ) {
                                        state.preferredProfessionalField =
                                          state.preferredProfessionalField.filter(
                                            (item) =>
                                              item.idProfessionalField !==
                                              field.id
                                          );
                                      } else {
                                        if (field.id === 1) {
                                          state.preferredProfessionalField = [];
                                        } else {
                                          state.preferredProfessionalField =
                                            state.preferredProfessionalField.filter(
                                              (item) =>
                                                item.idProfessionalField !== 1
                                            );
                                        }

                                        state.preferredProfessionalField.push({
                                          id: 0,
                                          idProfessionalField: field.id,
                                          idUser: state.user_data?.id,
                                        });
                                      }
                                      setState({ ...state });
                                    }}
                                  >
                                    <div>{field.description}</div>
                                    <div className="nu-icon nu-icon--mid ms-2">
                                      <IoAddCircleOutline
                                        size={24}
                                      ></IoAddCircleOutline>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </Renderif>

                        <Renderif isTrue={j === 4}>
                          <div className="d-flex flex-wrap mt-3">
                            {assistanceUnit
                              .filter(
                                (item) => item.id !== 16 && item.id !== 14
                              )
                              .map((unit, l) => {
                                return (
                                  <div
                                    key={l}
                                    className={`nu-tag nu-tag--disabled cursor-pointer ${
                                      state.preferredAssistanceUnit.some(
                                        (item) =>
                                          item.idAssistanceUnit === unit.id
                                      )
                                        ? "nu-tag nu-tag--active"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      if (
                                        state.preferredAssistanceUnit.some(
                                          (item) =>
                                            item.idAssistanceUnit === unit.id
                                        )
                                      ) {
                                        state.preferredAssistanceUnit =
                                          state.preferredAssistanceUnit.filter(
                                            (item) =>
                                              item.idAssistanceUnit !== unit.id
                                          );
                                      } else {
                                        if (unit.id === 1) {
                                          state.preferredAssistanceUnit = [];
                                        } else {
                                          state.preferredAssistanceUnit =
                                            state.preferredAssistanceUnit.filter(
                                              (item) =>
                                                item.idAssistanceUnit !== 1
                                            );
                                        }

                                        state.preferredAssistanceUnit.push({
                                          id: 0,
                                          idAssistanceUnit: unit.id,
                                          idUser: state.user_data?.id,
                                        });
                                      }
                                      setState({ ...state });
                                    }}
                                  >
                                    <div>{unit.description}</div>
                                    <div className="nu-icon nu-icon--mid ms-2">
                                      <IoAddCircleOutline
                                        size={24}
                                      ></IoAddCircleOutline>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </Renderif>

                        <Renderif
                          isTrue={j === 5 && state.user_data?.idUserType === 1}
                        >
                          <div className="d-flex flex-wrap mt-3">
                            {assistanceUnit.map((unit, l) => {
                              if (l === 0) return;

                              return (
                                <div
                                  key={l}
                                  className={`nu-tag nu-tag--disabled cursor-pointer ${
                                    state.educationAssistanceUnit?.some(
                                      (item) =>
                                        item.idAssistanceUnit === unit.id
                                    )
                                      ? "nu-tag nu-tag--active"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    if (
                                      state.educationAssistanceUnit.some(
                                        (item) =>
                                          item.idAssistanceUnit === unit.id
                                      )
                                    ) {
                                      state.educationAssistanceUnit =
                                        state.educationAssistanceUnit.filter(
                                          (item) =>
                                            item.idAssistanceUnit !== unit.id
                                        );
                                    } else {
                                      state.educationAssistanceUnit.push({
                                        id: 0,
                                        idAssistanceUnit: unit.id,
                                        idUser: state.user_data?.id,
                                      });
                                    }
                                    setState({ ...state });
                                  }}
                                >
                                  <div>{unit.description}</div>
                                  <div className="nu-icon nu-icon--mid ms-2">
                                    <IoAddCircleOutline
                                      size={24}
                                    ></IoAddCircleOutline>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Renderif>

                        <Renderif
                          isTrue={j === 6 && state.user_data?.idUserType === 1}
                        >
                          <div className="d-flex flex-wrap mt-3">
                            {professionalField.map((field, l) => {
                              if (l === 0) return;

                              return (
                                <div
                                  key={l}
                                  className={`nu-tag nu-tag--disabled cursor-pointer ${
                                    state.educationProfessionalField?.some(
                                      (item) =>
                                        item.idProfessionalField === field.id
                                    )
                                      ? "nu-tag nu-tag--active"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    if (
                                      state.educationProfessionalField.some(
                                        (item) =>
                                          item.idProfessionalField === field.id
                                      )
                                    ) {
                                      state.educationProfessionalField =
                                        state.educationProfessionalField.filter(
                                          (item) =>
                                            item.idProfessionalField !==
                                            field.id
                                        );
                                    } else {
                                      state.educationProfessionalField.push({
                                        id: 0,
                                        idProfessionalField: field.id,
                                        idUser: state.user_data?.id,
                                      });
                                    }
                                    setState({ ...state });
                                  }}
                                >
                                  <div>{field.description}</div>
                                  <div className="nu-icon nu-icon--mid ms-2">
                                    <IoAddCircleOutline
                                      size={24}
                                    ></IoAddCircleOutline>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Renderif>

                        <Renderif isTrue={j === 7}>
                          <div className="d-flex flex-wrap mt-3">
                            {assistanceUnit.map((unit, l) => {
                              if (unit.id === 1) {
                                return;
                              }

                              return (
                                <div
                                  key={l}
                                  className={`nu-tag nu-tag--disabled cursor-pointer ${
                                    state.assistanceUnitExperience.some(
                                      (item) =>
                                        item.idAssistanceUnit === unit.id
                                    )
                                      ? "nu-tag nu-tag--active"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    if (
                                      state.assistanceUnitExperience.some(
                                        (item) =>
                                          item.idAssistanceUnit === unit.id
                                      )
                                    ) {
                                      state.assistanceUnitExperience =
                                        state.assistanceUnitExperience.filter(
                                          (item) =>
                                            item.idAssistanceUnit !== unit.id
                                        );
                                    } else {
                                      state.assistanceUnitExperience.push({
                                        id: 0,
                                        assistanceUnitExperience: "1",
                                        idAssistanceUnit: unit.id,
                                        idUser: state.user_data?.id,
                                      });
                                    }
                                    setState({ ...state });
                                  }}
                                >
                                  <div>{unit.description}</div>
                                  <div className="nu-icon nu-icon--mid ms-2">
                                    <IoAddCircleOutline
                                      size={24}
                                    ></IoAddCircleOutline>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Renderif>

                        <Renderif isTrue={j === 8}>
                          <div className="d-flex flex-wrap mt-3">
                            {professionalField.map((field, l) => {
                              if (field.id === 1) {
                                return;
                              }

                              return (
                                <div
                                  key={l}
                                  className={`nu-tag nu-tag--disabled cursor-pointer ${
                                    state.professionalFieldExperience.some(
                                      (item) =>
                                        item.idProfessionalField === field.id
                                    )
                                      ? "nu-tag nu-tag--active"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    if (
                                      state.professionalFieldExperience.some(
                                        (item) =>
                                          item.idProfessionalField === field.id
                                      )
                                    ) {
                                      state.professionalFieldExperience =
                                        state.professionalFieldExperience.filter(
                                          (item) =>
                                            item.idProfessionalField !==
                                            field.id
                                        );
                                    } else {
                                      state.professionalFieldExperience.push({
                                        id: 0,
                                        yearsOfExperience: "1",
                                        idProfessionalField: field.id,
                                        idUser: state.user_data?.id,
                                      });
                                    }
                                    setState({ ...state });
                                  }}
                                >
                                  <div>{field.description}</div>
                                  <div className="nu-icon nu-icon--mid ms-2">
                                    <IoAddCircleOutline
                                      size={24}
                                    ></IoAddCircleOutline>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Renderif>
                      </Renderif>

                      <Renderif isTrue={state.editing_preferences === j}>
                        <div className="d-flex">
                          <div className="col"></div>
                        </div>
                      </Renderif>
                    </div>
                  );
                })} */}

                <div className="bg-gray r-10 p-3 mt-3 d-none">
                  <div className="d-flex">
                    <div className="f-16 bold mb-3">
                      En qué Unidades Asistenciales tienes experiencia
                    </div>
                    <div className="col"></div>

                    <div
                      className="d-flex align-items-center cursor-pointer"
                      onClick={() => {
                        if (state.editing_preferences === "UAE") {
                          setState({ ...state, editing_preferences: null });
                          return;
                        }
                        setState({ ...state, editing_preferences: "UAE" });
                      }}
                    >
                      <div className="f-14 text-darkblue bold mx-2">Ver</div>
                      <div className="nu-icon nu-icon--mid cursor-pointer">
                        <IoMdEye color={darkBlue} size={24}></IoMdEye>
                      </div>
                    </div>
                  </div>

                  <Renderif isTrue={state.editing_preferences === "UAE"}>
                    <div className="f-16"></div>
                    <div className="my-4"></div>
                    <div className="col px-3 px-md-5 event-none">
                      <CheckBox
                        onChange={(evt) => {
                          if (evt.value) {
                            state.assistanceUnitExperience = [];
                          }
                          setState({ ...state });
                        }}
                        checked={
                          state.assistanceUnitExperience.length === 0
                            ? true
                            : false
                        }
                        text="No tengo experiencia en ninguna Unidad Asistencial"
                      ></CheckBox>
                    </div>
                    <div className="d-flex my-3 px-3 px-md-5">
                      <div className="col"></div>
                      <div className="col d-flex justify-content-around ">
                        <div className="col ">
                          <div className="f-12 bold text-center line-1 ps-3 ">
                            No tengo <br></br>experiencia
                          </div>
                        </div>
                        <div className="f-12 bold text-center line-1">
                          Menos <br></br>de 1 año
                        </div>
                        <div className="col ">
                          <div className="f-12 bold text-center line-1 pe-2">
                            Más de <br></br> 1 año
                          </div>
                        </div>
                      </div>
                    </div>
                    {assistanceUnit?.map((option, i) => {
                      if (i === 0) {
                        return;
                      }
                      return (
                        <div
                          key={i}
                          className={`d-flex px-3 px-md-5 py-1 event-none ${
                            i % 2 == 0 ? "bg-white" : ""
                          }`}
                        >
                          <div className="col">
                            <div className="f-14 text-dark bold ps-4">
                              {option.description}
                            </div>
                          </div>

                          <div className="col d-flex justify-content-evenly ">
                            <CheckBox
                              text=""
                              value="0"
                              checked={assistanceUnitOptionChecked("0", option)}
                              onChange={(evt) => {
                                let unit = {
                                  idUser: state.user_data?.id,
                                  idAssistanceUnit: option.id,
                                  yearsOfExperience: evt.key,
                                };

                                if (evt.value) {
                                  state.assistanceUnitExperience =
                                    state.assistanceUnitExperience.filter(
                                      (item) =>
                                        item.idAssistanceUnit !== option.id
                                    );
                                  state.assistanceUnitExperience.push(unit);
                                } else {
                                  state.assistanceUnitExperience =
                                    state.assistanceUnitExperience.filter(
                                      (item) =>
                                        item.idAssistanceUnit !== option.id
                                    );
                                }

                                setState({ ...state });
                              }}
                            ></CheckBox>
                            <CheckBox
                              text=""
                              value="1"
                              checked={assistanceUnitOptionChecked("1", option)}
                              onChange={(evt) => {
                                let unit = {
                                  idUser: state.user_data?.id,
                                  idAssistanceUnit: option.id,
                                  yearsOfExperience: evt.key,
                                };
                                if (evt.value) {
                                  state.assistanceUnitExperience =
                                    state.assistanceUnitExperience.filter(
                                      (item) =>
                                        item.idAssistanceUnit !== option.id
                                    );
                                  state.assistanceUnitExperience.push(unit);
                                } else {
                                  state.assistanceUnitExperience =
                                    state.assistanceUnitExperience.filter(
                                      (item) =>
                                        item.idAssistanceUnit !== option.id
                                    );
                                }

                                setState({ ...state });
                              }}
                            ></CheckBox>
                            <CheckBox
                              text=""
                              value="2"
                              checked={assistanceUnitOptionChecked("2", option)}
                              onChange={(evt) => {
                                let unit = {
                                  idUser: state.user_data?.id,
                                  idAssistanceUnit: option.id,
                                  yearsOfExperience: evt.key,
                                };
                                if (evt.value) {
                                  state.assistanceUnitExperience =
                                    state.assistanceUnitExperience.filter(
                                      (item) =>
                                        item.idAssistanceUnit !== option.id
                                    );
                                  state.assistanceUnitExperience.push(unit);
                                } else {
                                  state.assistanceUnitExperience =
                                    state.assistanceUnitExperience.filter(
                                      (item) =>
                                        item.idAssistanceUnit !== option.id
                                    );
                                }

                                setState({ ...state });
                              }}
                            ></CheckBox>

                            <div className="d-none">
                              <NuComboBox
                                showkey={false}
                                key={i}
                                multiple={false}
                                checks={yearsExperienceOptions}
                                onChange={(evt) => {
                                  let unit = {
                                    idUser: state.user_data?.id,
                                    idAssistanceUnit: option.id,
                                    yearsOfExperience: evt[0].key.id.toString(),
                                  };
                                  if (evt[0].value) {
                                    state.assistanceUnitExperience =
                                      state.assistanceUnitExperience.filter(
                                        (item) =>
                                          item.idAssistanceUnit !== option.id
                                      );
                                    state.assistanceUnitExperience.push(unit);
                                  } else {
                                    state.assistanceUnitExperience =
                                      state.assistanceUnitExperience.filter(
                                        (item) =>
                                          item.idAssistanceUnit !== option.id
                                      );
                                  }

                                  setState({ ...state });
                                }}
                              ></NuComboBox>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Renderif>
                </div>

                <div className="bg-gray r-10 p-3 mt-3 d-none">
                  <div className="d-flex">
                    <div className="f-16 bold mb-3">
                      En qué ámbitos profesionales tienes experiencia
                    </div>
                    <div className="col"></div>

                    <div
                      className="d-flex align-items-center cursor-pointer"
                      onClick={() => {
                        if (state.editing_preferences === "APE") {
                          setState({ ...state, editing_preferences: null });
                          return;
                        }
                        setState({ ...state, editing_preferences: "APE" });
                      }}
                    >
                      <div className="f-14 text-darkblue bold mx-2">Ver</div>
                      <div className="nu-icon nu-icon--mid cursor-pointer">
                        <IoMdEye color={darkBlue} size={24}></IoMdEye>
                      </div>
                    </div>
                  </div>

                  <Renderif isTrue={state.editing_preferences === "APE"}>
                    <div className="f-16"></div>
                    <div className="my-4"></div>
                    <div className="col px-3 px-md-5 event-none">
                      <CheckBox
                        onChange={(evt) => {
                          if (evt.value) {
                            state.professionalFieldExperience = [];
                          }
                          setState({ ...state });
                        }}
                        checked={
                          state.professionalFieldExperience.length === 0
                            ? true
                            : false
                        }
                        text="No tengo experiencia en ningún ámbito profesional"
                      ></CheckBox>
                    </div>
                    <div className="d-flex my-3 px-3 px-md-5">
                      <div className="col"></div>
                      <div className="col d-flex justify-content-around ">
                        <div className="col ">
                          <div className="f-12 bold text-center line-1 ps-3 ">
                            No tengo <br></br>experiencia
                          </div>
                        </div>
                        <div className="f-12 bold text-center line-1">
                          Menos <br></br>de 1 año
                        </div>
                        <div className="col ">
                          <div className="f-12 bold text-center line-1 pe-2">
                            Más de <br></br> 1 año
                          </div>
                        </div>
                      </div>
                    </div>

                    {professionalField
                      .filter((item) => item.id !== 7)
                      .map((option, i) => {
                        if (i === 0) {
                          return;
                        }
                        return (
                          <div
                            key={i}
                            className={`d-flex px-3 px-md-5 py-1 event-none ${
                              i % 2 == 0 ? "bg-white" : ""
                            }`}
                          >
                            <div className="col">
                              <div className="f-14 text-dark bold ps-4">
                                {option.description}
                              </div>
                            </div>

                            <div className="col d-flex justify-content-evenly ">
                              <CheckBox
                                text=""
                                value="0"
                                checked={optionChecked("0", option)}
                                onChange={(evt) => {
                                  let unit = {
                                    idUser: state.user_data?.id,
                                    idProfessionalField: option.id,
                                    yearsOfExperience: evt.key,
                                  };

                                  if (evt.value) {
                                    state.professionalFieldExperience =
                                      state.professionalFieldExperience.filter(
                                        (item) =>
                                          item.idProfessionalField !== option.id
                                      );
                                    state.professionalFieldExperience.push(
                                      unit
                                    );
                                  } else {
                                    state.professionalFieldExperience =
                                      state.professionalFieldExperience.filter(
                                        (item) =>
                                          item.idProfessionalField !== option.id
                                      );
                                  }

                                  setState({ ...state });
                                }}
                              ></CheckBox>
                              <CheckBox
                                text=""
                                value="1"
                                checked={optionChecked("1", option)}
                                onChange={(evt) => {
                                  let unit = {
                                    idUser: state.user_data?.id,
                                    idProfessionalField: option.id,
                                    yearsOfExperience: evt.key,
                                  };
                                  if (evt.value) {
                                    state.professionalFieldExperience =
                                      state.professionalFieldExperience.filter(
                                        (item) =>
                                          item.idProfessionalField !== option.id
                                      );
                                    state.professionalFieldExperience.push(
                                      unit
                                    );
                                  } else {
                                    state.professionalFieldExperience =
                                      state.professionalFieldExperience.filter(
                                        (item) =>
                                          item.idProfessionalField !== option.id
                                      );
                                  }

                                  setState({ ...state });
                                }}
                              ></CheckBox>
                              <CheckBox
                                text=""
                                value="2"
                                checked={optionChecked("2", option)}
                                onChange={(evt) => {
                                  let unit = {
                                    idUser: state.user_data?.id,
                                    idProfessionalField: option.id,
                                    yearsOfExperience: evt.key,
                                  };
                                  if (evt.value) {
                                    state.professionalFieldExperience =
                                      state.professionalFieldExperience.filter(
                                        (item) =>
                                          item.idProfessionalField !== option.id
                                      );
                                    state.professionalFieldExperience.push(
                                      unit
                                    );
                                  } else {
                                    state.professionalFieldExperience =
                                      state.professionalFieldExperience.filter(
                                        (item) =>
                                          item.idProfessionalField !== option.id
                                      );
                                  }

                                  setState({ ...state });
                                }}
                              ></CheckBox>

                              <div className="d-none">
                                <NuComboBox
                                  showkey={false}
                                  key={i}
                                  multiple={false}
                                  checks={yearsExperienceOptions}
                                  onChange={(value) => {
                                    let unit = {
                                      idUser: state.user_data?.id,
                                      idProfessionalField: option.id,
                                      yearsOfExperience:
                                        value[0].key.id.toString(),
                                    };
                                    if (value[0].value) {
                                      state.professionalFieldExperience =
                                        state.professionalFieldExperience.filter(
                                          (item) =>
                                            item.idProfessionalField !==
                                            option.id
                                        );
                                      state.professionalFieldExperience.push(
                                        unit
                                      );
                                    } else {
                                      state.professionalFieldExperience =
                                        state.professionalFieldExperience.filter(
                                          (item) =>
                                            item.idProfessionalField !==
                                            option.id
                                        );
                                    }
                                  }}
                                ></NuComboBox>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </Renderif>
                </div>
          </div>

          <div className="py-5 my-5"> </div>
 
          
        </div>

 
        <Renderif isTrue={state.modalToBlock}>
          <div className='nu-modal'>
              <div className='nu-modal-body' onClick={(evt)=>{evt.stopPropagation()}}>
                  <div className='d-flex'>
                      <div className='col'></div>
                      <div className='nu-icon nu-icon--big cursor-pointer' onClick={()=>{
                          setState({...state, modalToBlock:false})
                      }}>
                          <IoCloseCircle color={darkBlue} size={30}></IoCloseCircle>
                      </div>
                  </div>
                  <div className='col d-flex flex-column justify-content-center align-items-center'>
                      <img width="100px" src='/assets/tick-circle-warning.png'></img>
                      <div className='f-26 bold text-center'>Marca los motivos por los cuales este<br></br>profesional dejará de ver tus turnos publicados: </div>
                  </div>

                  <div className='py-1'></div>

                  {restrictionReasons.map((item, i)=>{
                      return (
                          <div key={i} className='px-3'>
                              <CheckBox text={item.label} value={item.type} onChange={(evt)=>{
                                if(evt.value){
                                  setState({...state, reasons: [...state.reasons, item.type]})
                                } else {
                                  state.reasons = state.reasons.filter((reason) => reason!==item.type)
                                }
                              }}/>
                          </div>
                      )
                  })}

                  <div className='pt-2 f-14 text-center text-red'>Debes elegir al menos 1 motivo para poder bloquear</div>

                  <div className='d-flex align-items-center border-bottom py-3'>
                      <div className='col d-flex justify-content-center'>
                          <div className='nu_btn nu_btn--gray' style={{ paddingInline: '2rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                              evt.stopPropagation()
                              setState({...state, modalToBlock:false})
                          }}>
                              <div className='nu_btn-text f-16'>Cancelar</div>
                          </div>
                      </div>
                      <div className='col d-flex justify-content-center'>
                          <div className='nu_btn nu_btn--overlay' style={{ paddingInline: '3rem', paddingBlock: '0.6rem' }} onClick={(evt) => {
                              evt.stopPropagation()
                              onBlockProfesional()
                          }}>
                              <div className='nu_btn-text f-16'>Aceptar</div>
                              <div className='nu_btn-overlay'></div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </Renderif>

   

      </div>
  
    </>
  );
}
