import React, { useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { MdModeEdit } from "react-icons/md";
import { IoCloseCircleOutline,IoAddCircleOutline } from "react-icons/io5";

import { IoIosSearch } from "react-icons/io";
import { IoMdSave } from "react-icons/io";
import { ClinicSideMenu, ProfesionalSideMenu } from '../../components/nu_sidemenu';
import { darkBlue } from '../../styles/colors';
import Renderif from '../../utils/renderif';
export default function ClinicaAdminView() {
    const [state, setState] = useState({
        editing_general:false,
        editing_preferences:null,
        editing_center:false
    })

    const sample = [
        {
            title:'Distancia desde tu código postal donde quieres que te mostremos turnos',
            tags:['Muéstrame turnos a cualquier distancia desde mi código postal']
        },
        {
            title:'Años de experiencia que tienes en la profesión',
            tags:['1-5 años']
        },
        {
            title:'Especialización EIR',
            tags:['Enf. Geriátrica', 'Enf. de Salud Mental']
        },
        {
            title:'Centros donde tienes interés en realizar turnos',
            tags:['Sala de Partos']
        },
        {
            title:'Ámbitos profesionales donde tienes interés en realizar turnos',
            tags:['Muéstrame turnos en todos los ámbitos profesionales']
        },
        {
            title:'Ámbitos profesionales en los que tienes experiencia',
            tags:['Medicina interna']
        },
        {
            title:'Dispones de formación tipo master o postgrado relacionados con las siguientes unidades asistenciales',
            tags:['UCI']
        },
        {
            title:'Dispones de formación tipo master o postgrado relacionados con los siguientes ámbitos profesionales',
            tags:['No tengo formación máster o posgrado']
        },
        // {
        //     title:'Lorem',
        //     tags:[10101010]
        // },
    ]
  return (
    <div className='container-fluid overflow-hidden d-flex h-100'>


        <ClinicSideMenu page='perfil'/>
   


        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-3 px-3'>
     

                <div className='nu-snack'>
                <div>
                        <div className='f-22 text-white bold'>Hola, Admin</div>
                        <div className='d-flex align-items-center'>
                            <div className='nu-icon nu-icon--mid'>
                                <IoMdStar color='#FFB054' size={24}></IoMdStar>
                            </div>

                            <div className='f-16 text-white'>5.0 (0 reseñas)</div>

                        </div>
                    
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid'></div>
                    <div className='nu-snack-overlay'></div>
                </div>
                       
            </div>

            <div className='container p-3'>

                <div className='f-22 bold mb-3'>Perfil organizacion</div>


                <div className='d-flex'>

                    <div className='d-flex flex-column align-items-center pe-3'>
                        <div className='avatar avatar--big'></div>
                        <div className='nu-icon nu-icon--mid cursor-pointer'>
                            <MdModeEdit color={darkBlue} size={24}></MdModeEdit>
                        </div>
                    </div>

                    <div className='col d-flex flex-column'>
                        <div className='col'>

                            <div className='bg-gray r-10 p-4'>
                                <div className='d-flex'>
                                    <div className='f-22 bold mb-3'>Sobre ti</div>
                                    <div className='col'></div>

                                    <div className='d-flex align-items-center cursor-pointer' onClick={()=>{
                                        setState({...state, editing_general:!state.editing_general})
                                    }}>
                                        <div className='f-14 text-darkblue bold mx-2'>Editar</div>
                                        <div className='nu-icon nu-icon--mid' onClick={()=>{

                                        }}>
                                            <MdModeEdit color={darkBlue} size={24}></MdModeEdit>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col'>
                                        <div className='f-16 pe-5 bold'>Organización</div>
                                    </div>
                                    <div className='col'>
                                        <input className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}></input>
                                    </div>
                                    <div className='col'>
                                        <div className='f-16 ps-5 bold'>Nueva contraseña</div>
                                    </div>
                                    <div className='col'>
                                    <input className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}></input>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col'>
                                        <div className='f-16 pe-5 bold'>CIF / NIF</div>
                                    </div>
                                    <div className='col'>
                                        <input className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}></input>
                                    </div>
                                    <div className='col'>
                                        <div className='f-16 ps-5 bold'>Confirmar constraseña</div>
                                    </div>
                                    <div className='col'>
                                    <input className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}></input>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col'>
                                        <div className='f-16 pe-5 bold'>Dirección</div>
                                    </div>
                                    <div className='col'>
                                        <input className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}></input>
                                    </div>
                                    <div className='col'>
                                        <div className='f-16 ps-5 bold'>Personal de referencia contratación:</div>
                                    </div>
                                    <div className='col'>
                                    {/* <input className={`nu-input--light p-2 opacity-0 ${!state.editing_general ? 'nu-input--disabled': ''}`}></input> */}
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col'>
                                        <div className='f-16 pe-5 bold'>Código postal</div>
                                    </div>
                                    <div className='col'>
                                        <input className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}></input>
                                    </div>
                                    <div className='col'>
                                        <div className='f-16 ps-5 bold'>Nombre</div>
                                    </div>
                                    <div className='col'>
                                    <input className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}></input>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col'>
                                        <div className='f-16 pe-5 bold'>Provincia</div>
                                    </div>
                                    <div className='col'>
                                        <input className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}></input>
                                    </div>
                                    <div className='col'>
                                        <div className='f-16 ps-5 bold'>Correo electrónico</div>
                                    </div>
                                    <div className='col'>
                                        <input className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}></input>
                                    </div>
                                </div>


                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col'>
                                        <div className='f-16 pe-5 bold'>Teléfono</div>
                                    </div>
                                    <div className='col'>
                                        <input className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}></input>
                                    </div>
                                    <div className='col'>
                                        {/* <div className='f-16 ps-5 bold'>Correo electrónico</div> */}
                                    </div>
                                    <div className='col'>
                                        {/* <input className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}></input> */}
                                    </div>
                                </div>

                                <Renderif isTrue={state.editing_general}>
                                    <div className='d-flex'>
                                        
                                        <div className='col'></div>

                                        <div className='nu_btn nu_btn--primary' onClick={()=>{
                                            setState({...state, show_turno_detail:!state.show_turno_detail})
                                        }}>
                                            <div className='nu-icon nu-icon--mid me-1'>
                                                <IoMdSave size={18}></IoMdSave>
                                            </div>
                                            <div className='nu_btn-text'>Guardar</div>
                                        </div>
                                    </div>
                                </Renderif>

                            </div>

                        </div>

                        <div className='col mt-4'>

                            <div className='bg-gray r-10 p-4'>
                                <div className='d-flex'>
                                    <div className='f-22 bold mb-3'>Información del Centro Sanitario</div>
                                    <div className='col'></div>

                                    <div className='d-flex align-items-center cursor-pointer' onClick={()=>{
                                        setState({...state, editing_center:!state.editing_center})
                                    }}>
                                        <div className='f-14 text-darkblue bold mx-2'>Editar</div>
                                        <div className='nu-icon nu-icon--mid' onClick={()=>{

                                        }}>
                                            <MdModeEdit color={darkBlue} size={24}></MdModeEdit>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col-2'>
                                        <div className='f-16 pe-5 bold'>Nombre</div>
                                    </div>
                                    <div className='col'>
                                        <input className={`nu-input--light p-2 ${!state.editing_center ? 'nu-input--disabled': ''}`}></input>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col-2'>
                                        <div className='f-16 pe-5 bold'>Dirección</div>
                                    </div>
                                    <div className='col'>
                                        <input className={`nu-input--light p-2 ${!state.editing_center ? 'nu-input--disabled': ''}`}></input>
                                    </div>
                                </div>

            
                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col-2'>
                                        <div className='f-16 pe-5 bold'>Código postal</div>
                                    </div>
                                    <div className='col'>
                                        <input className={`nu-input--light p-2 ${!state.editing_center ? 'nu-input--disabled': ''}`}></input>
                                    </div>
                                    <div className='col-2'>
                                        <div className='f-16  text-center bold'>Provincia</div>
                                    </div>
                                    <div className='col'>
                                    <input className={`nu-input--light p-2 ${!state.editing_center ? 'nu-input--disabled': ''}`}></input>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col-2'>
                                        <div className='f-16 pe-5 bold'>Parking</div>
                                    </div>
                                    <div className='col'>
                                        <input className={`nu-input--light p-2 ${!state.editing_center ? 'nu-input--disabled': ''}`}></input>
                                    </div>
                                </div>


                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col-2'>
                                        <div className='f-16 pe-5 bold'>Dietas</div>
                                    </div>
                                    <div className='col'>
                                        <input className={`nu-input--light p-2 ${!state.editing_center ? 'nu-input--disabled': ''}`}></input>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col-2'>
                                        <div className='f-16 pe-5 bold'>Uniforme</div>
                                    </div>
                                    <div className='col'>
                                        <input className={`nu-input--light p-2 ${!state.editing_center ? 'nu-input--disabled': ''}`}></input>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col-2'>
                                        <div className='f-16 pe-5 bold'>Punto de encuentro</div>
                                    </div>
                                    <div className='col'>
                                        <input className={`nu-input--light p-2 ${!state.editing_center ? 'nu-input--disabled': ''}`}></input>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col-2'>
                                        <div className='f-16 pe-5 bold'>Notas</div>
                                    </div>
                                    <div className='col'>
                                        <input className={`nu-input--light p-2 ${!state.editing_center ? 'nu-input--disabled': ''}`}></input>
                                    </div>
                                </div>

                                <Renderif isTrue={state.editing_center}>
                                    <div className='d-flex mt-3'>
                                        
                                        <div className='col'></div>

                                        <div className='nu_btn nu_btn--primary' onClick={()=>{
                                            setState({...state, show_turno_detail:!state.show_turno_detail})
                                        }}>
                                            <div className='nu-icon nu-icon--mid me-1'>
                                                <IoMdSave size={18}></IoMdSave>
                                            </div>
                                            <div className='nu_btn-text'>Guardar</div>
                                        </div>
                                    </div>
                                </Renderif>

                            </div>

                        

                        

                        </div>
                    </div>
                </div>



            </div>

            
 

    
        </div>

   
   
  
     
    </div>
  )
}
