import React, { useContext } from 'react'
import { IoMdClose, IoMdCloseCircle } from 'react-icons/io';
import { AuthContext } from '../../context/auth_context';
import { darkBlue } from '../../styles/colors';
import { months } from '../../utils';

export default function TycosViewClinic({onAccept, onClose}) {
   


let today = new Date();
  return (
    <div className='overlay'>


        <div className='letter hide-scroll absolute-center'>
            <div className='container'>
                   <div className='d-flex p-3'>
                    <div className='col'></div>
                    <div className='bv-icon bv-icon--mid cursor-pointer' onClick={()=>{
                        onClose(true)
                    }}>
                        <IoMdCloseCircle size={30} color={darkBlue}></IoMdCloseCircle>
                    </div>
                </div>

                <div className='f-20 bolder text-darkblue px-5 text-center pb-4'>
                  CONTRATO DE PRESTACIÓN DE SERVICIOS <br></br> CAM HEALTH SL
                </div>


                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    <span className='bold'>CAM HEALTH SL</span> . <span className='bold'>(nombre comercial: NURSEA)</span> , sociedad de nacionalidad española, domiciliada en la calle Muntaner, número 532, 6-1, CP 08022, en Barcelona, provista de CIF B 56646805. (en adelante, CAM HEALTH).
                    </div>
                </div>

                <div className='my-3 px-5'>
                    <div className='f-14 text-justify'>
                    Se encuentra representada en este acto, en calidad de Administradora Única de CAM HEALTH SL, por doña <span className="bold">Cristina Romagosa Vives de la Cortada</span> con DNI <span className='bold'>46358630Z </span> y con domicilio a efectos de notificaciones en la calle Muntaner, número 532, 6-1, CP 08022, en Barcelona, en Barcelona.
                    </div>
                </div>


                <div className='bolder f-16 text-center'>CLÁUSULAS</div>
                
                <div className='mt-3 px-5'>
                    <ul className=' list-unstyled'>
                       
                        <li>
                            <div className='f-14 text-justify pb-3'>
                            <span className='bolder'>I.-</span> Que CAM HEALTH es una sociedad limitada que tiene como objeto social 8299 “Otras actividades de apoyo a las empresas”, y como objetivo enlazar oferta y demanda entre profesionales de la salud y centros sanitarios, para cubrir turnos disponibles de ejercicio profesional. <br></br><br></br>
                            Para ello, CAM HEALTH, SL. permite, a través de su Plataforma, el registro de los profesionales y centros sanitarios, para unir las necesidades a través de un formulario que describe la formación y preferencias de los profesionales, así como establece los criterios de selección de los turnos publicados por parte del Centro Sanitario.
                            </div>
                        </li>
                   
                        <li>
                            <div className='f-14 text-justify pb-3'>
                            <span className='bolder'>II.-</span> Que el Centro Sanitario está interesado en contratar los servicios de CAM HEALTH, a fin de hacer uso de los servicios que esta oferta, a través de su Plataforma.
                            </div>
                        </li>
                    </ul>
                </div>

                <div className='bolder f-16 text-center'>EXPONE</div>

               <div className='mt-3 px-5'>
                <div className='bolder f-16'>Primera.- Objeto y obligaciones de las partes.</div>
               </div>

               <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    Mediante la aceptación de los términos y condiciones por parte del Centro Sanitario, éste contrata los servicios prestados por CAM HEALTH, todo de conformidad con la voluntad expresa de las partes.
                    </div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    Por este motivo, CAM HEALTH prestará sus servicios profesionales sin relación de dependencia respecto a la estructura organizativa del Centro Sanitario, en régimen de prestación de servicios.
                    </div>
                </div>

                <div className='mt-3 px-5'>
                    <ul className='list-unstyled'>
                       
                        <li>
                            <div className='f-14 text-justify pb-3'>
                            <span className='bolder'>I.-</span> Sin embargo, esta prestación de servicios comprende las siguientes obligaciones específicas, que deberá cumplir el <span className='bold'>Centro Sanitario</span>:
                            </div>
                            <div>
                            <ul className='ul-disc'>
                                <li>
                                    <div className='f-14 text-justify'>
                                    El CS deberá registrarse en la plataforma, indicando la denominación, el correspondiente CIF, el domicilio, así como una persona de contacto, que tendrá acceso al área privada y recibirá la documentación de los profesionales asignados a los turnos publicados para poder formalizar la contratación.

                                    </div>
                                </li>
                                <li>
                                    <div className='f-14 text-justify'>
                                    El CS deberá publicar las ofertas de trabajo en la plataforma mediante el área privada. Asimismo, también podrá enviarlas a CAM HEALTH dirigiendo un correo electrónico a la dirección hola@nursea.es 
                                    </div>
                                </li>
                                <li>
                                    <div className='f-14 text-justify'>
                                    El CS deberá indicar y especificar, en las publicaciones de ofertas de trabajo a través del área privada, o por correo electrónico, las necesidades, características y criterios de selección y del turno ofrecido. Los criterios de selección pueden ser modificados en cualquier momento, siempre que sea previo a la asignación del turno.
                                    </div>
                                </li>
                                <li>
                                    <div className='f-14 text-justify'>
                                    En cada publicación/oferta, el CS deberá indicar la tipología del turno que se oferta: turno de acogida, turno de cobertura sin acogida previa, o turno de cobertura con acogida previa.
                                    </div>
                                </li>
                                <li>
                                    <div className='f-14 text-justify'>
                                    El CS deberá abonar la remuneración a CAM HEALTH que se indica en la cláusula segunda, en concepto de contraprestación por la prestación del servicio, o bien a través de la pasarela de pago integrada en la página web, o bien por transferencia bancaria ordinaria. Al finalizar el mes en curso, el cliente recibirá la factura para realizar el pago dentro de los siguientes 30 días desde la emisión de la factura.

                                    </div>
                                </li>
                                <li>
                                    <div className='f-14 text-justify'>
                                    El CS se compromete a contratar al profesional asignado con las mismas condiciones publicadas en la plataforma (centro, unidad, horario, etc.). En ningún caso estas condiciones podrán ser modificadas. Se permite la posibilidad de indicar "unidad no definida", en caso de que no sea posible indicarla en el momento de su publicación.

                                    </div>
                                </li>
                                <li>
                                    <div className='f-14 text-justify'>
                                    El CS se compromete a no contactar directamente con el personal sanitario de NURSEA para coberturas de ninguna tipología , siempre que la primera relación contractual entre el profesional y el CS haya sido fruto de la publicación de una oferta a través de NURSEA.

                                    </div>
                                </li>
                                <li>
                                    <div className='f-14 text-justify'>
                                    Una vez completado el turno, el CS valorará al profesional sanitario indicando la puntuación alcanzada (de 1 a 5 estrellas), que permitirá; en primer lugar, que la plataforma sugiera el centro al profesional; y, en segundo, el CS tenga la opción de bloquear las publicaciones a los profesionales. La acción de bloqueo es reversible por parte del propio CS
                                    </div>
                                </li>
                            </ul>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    La contravención de cualquiera de las obligaciones descritas en este Contrato por parte del Centro Sanitario, comportará la asunción íntegra de las responsabilidades que se deriven de tal actuación con total indemnidad para CAM HEALTH.
                    </div>
                </div>

                {/* <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    Específicamente, el incumplimiento de la obligación “ El CS se compromete a no contactar directamente con el personal sanitario de NURSEA para coberturas de ninguna tipología, siempre que la primera relación contractual entre el profesional y el CS haya sido fruto de la publicación de una oferta a través de NURSEA” , generará una indemnización a favor de CAM HEALTH que se pacta en <span className='bolder'>5.000</span> euros en el caso de enfermería y <span className='bolder'>4.000</span> euros en el caso auxiliares de enfermería (TCAE) en <span className='bolder'>concepto de “adquisición”.</span>
                    </div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    No obstante lo anterior, y como excepción, si el CS y personal de enfermería (enfermería o TCAE) llegan a un acuerdo de contratación para una cobertura temporal no publicada en NURSEA, el CS tendrá la obligación de comunicar dicha cobertura y abonar a CAM HEALTH el 20% del precio bruto total que se retribuirá al profesional por los servicios prestados durante la vigencia del contrato entre el CS y dicho profesional. Es importante destacar que esta excepción está limitada a contrataciones temporales y siempre que exista la comunicación a NURSEA, y no exime al CS de cumplir con las demás disposiciones establecidas en el contrato.
                    </div>
                </div> */}

                <div className='mt-3 px-5'>
                    <ul className='list-unstyled'>
                        <li>
                            <div className='f-14 text-justify pb-3'>
                            <span className='bolder'>II.-</span> En cuanto a las obligaciones específicas de CAM HEALTH , se expresan a continuación:
                            </div>
                            <ul className='ul-disc'>
                                <li>
                                    <div className='f-14 text-justify'>
                                    Facilitar el área privada al CS, que tendrá acceso la persona de contacto, y podrá gestionar los datos, así como realizar publicaciones de turnos  de trabajo.
                                    </div>
                                </li>
                                <li>
                                    <div className='f-14 text-justify'>
                                    Visibilizar las publicaciones que realice el CS en la plataforma a los profesionales sanitarios adscritos que cumplan con los criterios de selección indicados por el CS.
                                    </div>
                                </li>
                                <li>
                                    <div className='f-14 text-justify'>
                                    Una vez que se asigne un profesional al turno ofertado por el CS, CAM HEALTH facilitará al CS los datos necesarios del profesional sanitario, a través del correo electrónico registrado. Concretamente, se informarán, si se trata de la primera asignación, de los siguientes datos: DNI, número de cuenta bancaria, número de afiliación a la seguridad social, certificados de antecedentes penales y naturaleza sexual, carné de colegiado/ ada, copia de la titulación oficial, copia de la titulación de posgrado/máster, copia de la titulación EIR. No se facilitarán los datos de teléfono ni dirección de correo electrónico.
                                    </div>
                                </li>
                                <li>
                                    <div className='f-14 text-justify'>
                                    En caso de no tratarse de la primera asignación del profesional sanitario al CS, se facilitarán únicamente los datos de nombre, apellidos y número de DNI.
                                    </div>
                                </li>
                                <li>
                                    <div className='f-14 text-justify'>
                                    La asignación del profesional sanitario cumplirá con todos y cada uno de los criterios de selección exigidos por el Centro en la publicación de la oferta de trabajo.
                                    </div>
                                </li>
                                <li>
                                    <div className='f-14 text-justify'>
                                    CAM HEALTH formalizará un documento de compromiso por parte del profesional una vez éste se asigne un turno con un CS determinado, precisando los datos del centro, el día y hora del turno asignado, la unidad y ámbito de especialidad.
                                    </div>
                                </li>
                            </ul>
                        </li>
                       
                    </ul>
                </div>

                <div className='mt-3 px-5'>
                    <div className='bolder f-16'>Segunda.- Precio y forma de pago.</div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>CAM HEALTH presta sus servicios en el Centro Sanitario a cambio de las siguientes contraprestaciones:</div>
                    <div className='f-14 text-justify ps-4'> - El coste de publicación (1).</div>
                    <div className='f-14 text-justify ps-4'> - El coste de asignación de cada profesional sanitario (2).</div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    El coste de publicación (1) será gratuito durante el primer año de vigencia del Contrato, siendo negociado entre las partes a partir de la renovación contractual.
                    </div>
                  
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    El importe total a percibir por la publicación (1) se facturará al tipo del 100% previo a la publicación del anuncio en la Plataforma.
                    </div>
                  
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    El coste de asignación del profesional (2), salvo pacto expreso, se corresponde con el 20% de la retribución por turno ofrecido por parte del CS al profesional sanitario. Es decir, se calcula el coste de asignación en relación con la retribución ofrecida en cada publicación. Por tanto, si un turno se ofrece a 100€, el profesional recibirá 100€ (importe en bruto) y el coste de asignación de CAM HEALTH será de 20€. CAM HEALTH emitirá la factura por importe de 20 €.
                    </div>
                  
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    Los pagos se efectuarán a través de la pasarela de pago integrada en la plataforma web, en la que el CS tiene la posibilidad de abonar la contraprestación para cada asignación, o bien por abono vía transferencia bancaria ordinaria.
                    </div>
                  
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    La factura se emitirá a finales de mes con el recuento de asignaciones, y también estará disponible en el área privada de NURSEA del CS.
                    </div>
                  
                </div>

                <div className='mt-3 px-5'>
                    <div className='bolder f-16'>Tercera.- Ausencia de relación laboral entre las partes.</div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    Mediante este documento, CAM HEALTH y el Centro Sanitario constituyen una relación jurídica de prestación de servicios profesionales.
                    </div>
                  
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    Por este motivo, CAM HEALTH prestará los servicios sin que en modo alguno formen parte de la estructura organizativa del Centro Sanitario, y, por tanto, sin integrarse en el mismo, de forma que su actuación será independiente a todos los efectos.
                    </div>
                  
                </div>

                <div className='mt-3 px-5'>
                    <div className='bolder f-16'>Tercera.- Ausencia de relación laboral entre las partes.</div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    El Centro Sanitario manifiesta ser conocedor de que la Plataforma propiedad de CAM HEALTH constituye, en su integridad, una obra sujeta a la normativa de propiedad intelectual, el Real Decreto Legislativo 1/1996, de 12 de abril, Texto Refundido de la Ley de Propiedad Intelectual.
                    </div>
                  
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    El titular de los derechos de propiedad intelectual de la obra es CAM HEALTH, sin que el uso de la Plataforma por parte del Centro Sanitario le atribuya ningún derecho sobre la obra, del que es pleno titular CAM HEALTH.

                    </div>
                  
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    El Centro Sanitario se compromete a cumplir estrictamente la legislación vigente en materia de Propiedad Intelectual e Industrial, absteniéndose de realizar acciones como el uso, distribución, reproducción, transmisión, comunicación, modificación, de la Plataforma propiedad de CAM HEALTH así como de su contenido.
                    </div>
                  
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    Asimismo, se hace responsable y con total indemnidad de CAM HEALTH, frente a sanciones/cargas económicas que pudieran derivarse con motivo de acciones, reclamaciones, conflictos o inspecciones derivadas del incumplimiento de estas obligaciones.

                    </div>
                  
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    El prestamista de servicios no podrá utilizar los símbolos o logos identificativos de CAM HEALTH fuera de las actividades objeto del presente Contrato.

                    </div>
                  
                </div>

                <div className='mt-3 px-5'>
                    <div className='bolder f-16'>Quinta.- Protección de Datos personales.</div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    CAM HEALTH constituye el Responsable de Tratamiento de los datos personales de los profesionales inscritos en la Plataforma, siendo la base legítima de tratamiento el consentimiento informado del interesado, en cumplimiento de la normativa reguladora de la protección de los datos personales; el Reglamento (UE) 2016/679, del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales ya la libre circulación de los datos; así como la Ley Orgánica 3/2018 de 5 de diciembre, de protección de datos personales y garantía de los derechos digitales.

                    </div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    Una vez que el profesional determinado aplique la oferta del Centro Sanitario, CAM HEALTH le comunicará los datos del profesional interesado.

                    </div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    Esta comunicación de datos es debidamente informada y consentida por los interesados
                    </div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    Las partes se comprometen a cumplir estrictamente con la normativa anteriormente mencionada, asumiendo cada una de ellas únicamente la responsabilidad derivada de sus actos
                    </div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    Por lo que respecta al Centro Sanitario, éste no constituye un encargado de tratamiento de CAM HEALTH, sino que es destinatario de los datos personales, sin perjuicio de la condición de Responsable de Tratamiento de los datos comunicados.
                    </div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='bolder f-16'>Sexta.- Datos de los intervinientes.</div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    Los datos personales incluidos en este documento serán tratados por la otra parte con el fin de permitir el desarrollo, cumplimiento y control del acuerdo concertado, siendo la base del tratamiento el cumplimiento de la relación contractual, conservando los datos durante todo el tiempo en que ésta subsista y hasta que prescriban las eventuales responsabilidades derivadas de la misma.

                    </div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    Finalizados estos plazos de prescripción, sus datos serán eliminados o, alternativamente, anonimizados.
                    </div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>
                    A efectos de lo establecido en la normativa de protección de datos, los intervinientes se dan por informados de lo siguiente:
                    </div>
                </div>

                <div className='mt-3 px-5'>
                    <ul className=' list-unstyled'>
                        <li>
                            <div className='f-14 text-justify mt-2 d-flex'>
                            <div className='bold pe-2'>a)</div> Los respectivos responsables del tratamiento de datos personales, son cada una de las partes a las que se les facilita los datos de los intervinientes.
                            </div>
                        </li>
                        <li>
                            <div className='f-14 text-justify mt-2 d-flex'>
                            <div className='bold pe-2'>b)</div> Los datos personales de los intervinientes no serán cedidos a terceras entidades ni serán objeto de decisiones automatizadas, así como tampoco se realizan transferencias internacionales.
                            </div>
                        </li>
                        <li>
                        <div className='f-14 text-justify mt-2 d-flex'>
                            <div className='bold pe-2'>c)</div> Las partes podrán ejercitar sus derechos de acceso, rectificación, supresión, limitación, oposición y portabilidad, mediante escrito a la dirección postal o por correo electrónico al responsable del tratamiento o al delegado de protección de datos.
                            </div>
                        </li>
                        <li>
                        <div className='f-14 text-justify mt-2 d-flex'>
                            <div className='bold pe-2'>d)</div> Si consideran que el tratamiento de sus datos personales vulnera la normativa, pueden presentar una reclamación: (i) a los responsables de seguridad o delegados de protección de datos antes citados; o, (ii) ante la Autoridad Catalana de Protección de Datos, www.apdcat.gencat.cat o (según el caso), en la Agencia Española de Protección de Datos, www.agpd.es .
                            </div>
                        </li>

                    </ul>
                </div>

                <div className='mt-3 px-5'>
                    <div className='bolder f-16'>Séptima.- Compliance.</div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>El Centro Sanitario manifiesta que asume la tolerancia cero a los riesgos penales, prohibiendo las conductas que de forma directa o mediante terceros puedan incurrir en la comisión de algún delito en relación con la actividad contratada.</div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>El Centro Sanitario garantiza que, en su ámbito de responsabilidad, cumple con la normativa vigente, en especial, en relación con el cumplimiento respecto a la anticorrupción, competencia y protección de datos. De igual modo, garantiza que sus trabajadores están sometidos a un pacto de confidencialidad en relación con CAM HEALTH y el presente encargo.</div>
                </div>
             
                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>El Centro Sanitario prescindirá de entregar cualquier obsequio o regalo a trabajadores o socios de CAM HEALTH. Cualquier incumplimiento de los apartados anteriores por parte del Centro Sanitario supondrá la resolución de pleno derecho del presente documento por parte de CAM HEALTH, con asunción por parte del primero, de todos aquellos daños y perjuicios de cualquier naturaleza que puedan causar a CAM HEALTH por su incumplimiento.</div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='bolder f-16'>Octava.- Confidencialidad.</div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>El Centro Sanitario se obliga a mantener en todo momento la confidencialidad de todos aquellos datos o informaciones de los que tenga conocimiento con motivo u ocasión del desarrollo del presente documento, comprometiéndose a no comunicar ni divulgar información relativa alguna a CAM HEALTH.</div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='bolder f-16'>Novena.- Impuestos.</div>
                </div>
                      
                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>Serán a cargo del Centro Sanitario todos los impuestos, tasas, arbitrios y licencias que deban ser abonados por la prestación de los servicios contratados, en su caso.</div>
                </div>

                <div className='mt-3 px-5'>
                    <div className='bolder f-16'>Disposición final.-</div>
                </div>
                      
                <div className='mt-3 px-5'>
                    <div className='f-14 text-justify'>El presente documento constituye el acuerdo de las partes respecto a la materia objeto del mismo, que se formalizará en un contrato que se hará llegar al representante legal de la organización a través del correo electrónico registrado o facilitado por la propia organización.</div>
                </div>

                <div className='my-5 py-5'></div>



                
                
           
   
            </div>
        </div>

    </div>
  )
}
