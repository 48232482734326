import React from 'react'

export default function NuLoading() {
  return (
    <div className='nu-loading'>
        <div className="spinner-border text-light"></div>
    </div>
  )
}

