import React, { useContext, useEffect, useState } from 'react'
import { IoMdClose, IoMdStar } from "react-icons/io";
import { MdArrowBack, MdCheck, MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import NuCalendar from '../../components/nu_calendar';
import { MdModeEdit } from "react-icons/md";
import { IoCloseCircleOutline,IoAddCircleOutline } from "react-icons/io5";

import { IoIosSearch } from "react-icons/io";
import { IoMdSave } from "react-icons/io";
import { AdminSideMenu, ClinicSideMenu, ProfesionalSideMenu } from '../../components/nu_sidemenu';
import { darkBlue } from '../../styles/colors';
import Renderif from '../../utils/renderif';
import { AuthContext } from '../../context/auth_context';
import { GlobalContext } from '../../context/global_context';
import { Service } from '../../services/api';
import { NotificationContext } from '../../context/notification_context';
import NuLoading from '../../components/nu_loading';
import { useFilePicker } from 'use-file-picker';
import { useNavigate, useParams } from 'react-router-dom';
import { AdminContext } from '../../context/admin_context';
import { alphaOrder } from '../../utils';
export default function PerfilClinicaAdminView() {

    const {user, user_data, updateUserPictue, setUserPicture} = useContext(AuthContext)
    console.log(user_data)

    const {idUser} = useParams()

    const navigate = useNavigate()

 
    const {notify} = useContext(NotificationContext);
    const {center, loadCenters, centerType} = useContext(AdminContext);
    const { openFilePicker, filesContent, loading, plainFiles } = useFilePicker({
        accept: '*',
    });
    

    
    const [state, setState] = useState({
        editing_general:false,
        editing_preferences:null,
        editing_center:-1, 
        organization:null,
        centers:null,
        password:'',
        passwordConfirmed:'',
        description: null,
        loading:false
        
    })
    const provincias = [
        {
            provincia: 'Andalucía',
            colegios: ['Almería', 'Cádiz', 'Córdoba', 'Granada', 'Huelva', 'Jaén', 'Málaga', 'Sevilla']
        },
        {
            provincia: 'Aragón',
            colegios: ['Zaragoza', 'Teruel', 'Huesca']
        },
        {
            provincia: 'Principado de Asturias',
            colegios: ['Asturias']
        },
        {
            provincia: 'Illes Balears',
            colegios: ['Illes Balears']
        },
        {
            provincia: 'Canarias',
            colegios: ['Las Palmas', 'Santa Cruz de Tenerife']
        },
        {
            provincia: 'Cantabria',
            colegios: ['Cantabria']
        },
        {
            provincia: 'Castilla y León',
            colegios: ['Ávila', 'Burgos', 'León', 'Palencia', 'Salamanca', 'Segovia', 'Soria', 'Valladolid', 'Zamora']
        },
        {
            provincia: 'Castilla y la Mancha',
            colegios: ['Albacete', 'Ciudad Real', 'Cuenca', 'Guadalajara y Toledo']
        },
        {
            provincia: 'Cataluña',
            colegios: ['Barcelona', 'Tarragona', 'Girona', 'Lleida']
        },
        {
            provincia: 'Comunidad Valenciana',
            colegios: ['Alicante', 'Valencia', 'Castellón']
        },
        {
            provincia: 'Extremadura',
            colegios: ['Cáceres','Badajoz']
        },
        {
            provincia: 'Galicia',
            colegios: ['A Coruña','Lugo', 'Ourense', 'Pontevedra']
        },
        {
            provincia: 'Murcia',
            colegios: ['Murcia']
        },
        {
            provincia: 'Madrid',
            colegios: ['Madrid']
        },
        {
            provincia: 'Navarra',
            colegios: ['Navarra']
        },
        {
            provincia: 'País Vasco',
            colegios: ['Bizcaia','Gipuzkoa','Araba']
        },
        {
            provincia: 'La Rioja',
            colegios: ['La Rioja']
        },
        {
            provincia: 'Ceuta',
            colegios: ['Ceuta']
        }
        ,
        {
            provincia: 'Melilla',
            colegios: ['Melilla']
        }
        
    ];

    

    const parseDescription = (description) => {
        
       
        try {
            let string = description;
            string = string.replaceAll('<div>', '')
            string=  string.replaceAll('</div>', '",')
            string=  string.replaceAll('<span>', '"')
            string= string.replaceAll('</span>', '"')
            string= string.replaceAll('Punto de encuentro', 'Location')
            string= string.replaceAll(':', ':"')
    
           
            
            string = string.slice(0, -1);
            string = '{' + string + '}'
            let obj = JSON.parse(string)
            return obj;
        } catch (error) {
            return {
                Parking:'',
                Dietas:'',
                Uniforme:'',
                Location:'',
                Notas:''

            }
        }
       
       
    
    }

 

    useEffect(()=>{
        const init = async () => {

            setState({...state, loading:true})
           
            let _organization = await Service('GET', `user/getById/${idUser}`)
            let _center = await Service('GET', 'center/getAll')
            console.log(_organization)

            let _organization_a = await Service('GET', `organizationData/getById/${_organization.data.user.organizationData.id}`)
            console.log(_organization_a)
    
            state.organization = _organization_a.data.organizationData;
            // let _centers = await loadCenters()
            // console.log(_centers)

            state.centers = _center.data.list.filter((center) => center.idOrganization === _organization.data.user.organizationData.id)
            // state.centers = center.filter((item)=>item.idOrganization === user_data.id);
            // state.centers = _centers.filter((center) => center.idOrganization === _organization.data.organizationData.id)

            patch_centers();
            setState({...state, loading:false})
        };
        init();
        return () => {}
    },[])

    useEffect( ()=>{


        const init = async () => {

            if(filesContent.length > 0 && state.active_file_type !== null){     
                setState({...state, loading:true})
                let a = await updateUserPictue(plainFiles[0])
          
                await setUserPicture();
                setState({...state, loading:false})
            }
        }
        init();
        
        return () => {}
    },[filesContent])


    const patch_centers = async () => {

        for (let i = 0; i < state.centers.length; i++) {
            console.log(state.centers[i])

            if(state.centers[i].description !== null && state.centers[i].info === null){
                
                

                let description_parsed = parseDescription(state.centers[i].description);

                let info_model = {
                    Parking:description_parsed.Parking,
                    Dietas:description_parsed.Dietas,
                    Uniforme:description_parsed.Uniforme,
                    Location:description_parsed.Location,
                    Notas:description_parsed.Notas
                }
                
                let body = {
            
                        id: state.centers[i].id,
                        idOrganization: state.organization.id,
                        name: state.centers[i].name,
                        address: state.centers[i].address,
                        postalCode: state.centers[i].postalCode,
                        province: state.centers[i].province,
                        description: state.centers[i].description,
                        info:JSON.stringify(info_model)
                    
                }
                state.centers[i].info = JSON.stringify(info_model)
                let _updateCenter = await Service('PUT', 'center/update', body);
                console.log(_updateCenter);



            }
            
           
            
        }
        setState({...state, loading:false})
    }


    const checkPassword = (password) => {
        var re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-/]).{8,}$/;
        // var re = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        return re.test(password);
    }
    const validator = () => {

        state.errors = {}

        if(!checkPassword(state.passwordConfirmed)){
            state.errors.password = 'Debe tener mínimo 8 caracteres, 1 letra Mayúscula, 1 letra minúscula, 1 número y 1 carácter especial (!&%#*?-^/)'
        }
        
        // if(state.passwordConfirmed !== state.password){
        //     state.errors.confirmPassword = 'Las contraseñas no coinciden'
        // }
       
        
        setState({...state})
        if(Object.keys(state.errors).length > 0){
            
            return false;
        }
       return true;
        

    }
    const onUpdateProfile = async () => {
        
        let body = {
            phone: state.organization.phone_number,
            name: state.organization.name,
            cif_nif: state.organization.cif,
            postal_code: state.organization.postal_code,
            address: state.organization.address,
            community: state.organization.community,
            province: state.organization.province,
            contact_name: state.organization.contact_name,
            contact_email: state.organization.email
        }
    
        let update = await Service('PUT', 'user/updateOrganization', body );
       
      
        if(update.status === 201){
            notify({title:"Listo!", message:'Tu datos han sido actualizados', type:'success'})
        }else{
            notify({title:"Error!", message:update?.response?.data.message?.error, type:'error'})
        }
        
        if(state.passwordConfirmed !== ''){

            body = {
                password:state.password,
                new_password:state.passwordConfirmed
            }

            if(!validator()){
           
                return;
            }
            let update_password = await Service('PUT', 'user/updatePassword', body );
          
            if(update_password.status === 201){
                notify({title:"Contraseña", message:'Tu contraseña ha sido actualizada', type:'success'})
            }else{
                notify({title:"Contraseña", message:'No ha sido posible cambiar tu contraseña', type:'error'})
            }
            setState({
                ...state, 
                password:'',
                passwordConfirmed:''
            })

        }
    
    }

    const updateCenter = async () => {
        let _description = 
        `<div><span>Parking</span>: ${state.description.Parking}</div><div><span>Dietas</span>: ${state.description.Dietas}</div><div><span>Uniforme</span>: ${state.description.Uniforme}</div><div><span>Punto de encuentro</span>: ${state.description.Location}</div><div><span>Notas</span>: ${state.description.Notas}</div>`;

        let _center = state.centers.filter((item)=> item.id === state.editing_center)[0]
        let body = {
            
                id: state.editing_center,
                idOrganization: state.organization.id,
                name: _center.name,
                address: _center.address,
                postalCode: _center.postalCode,
                province: _center.province,
                description: _description
            
        }
   

        let _updateCenter = await Service('PUT', 'center/update', body);
   
        if(_updateCenter.status === 201){
            notify({title:"Centro Sanitario", message:'Tu datos han sido actualizados', type:'success'})
        }else{
            notify({title:"Centro Sanitario", message:'No ha sido posible actualizar tus datos.', type:'error'})
        }
        loadCenters()

        setState({...state, editing_center:-1, description:null })

    }

  
  return (
    <>
    <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
    </Renderif>
    <div className='container-fluid overflow-hidden d-flex h-100'>


        <AdminSideMenu page='organizaciones'/>
   


        <div className='container-fluid overflow-auto hide-scroll col  mx-auto'>

            <div className='bg-white sticky-top pt-3 px-3'>
     

                <div className='nu-snack'>
                <div>
                        <div className='f-22 text-white bold'>Hola, {user?.name}</div>
                        
                    
                </div>
                <div className='col'></div>
                    <div className='avatar avatar--mid'></div>
                    <div className='nu-snack-overlay'></div>
                </div>
                       
            </div>

            <div className='container p-3'>

                


                <div className='d-flex'>

                    <div className='d-flex flex-column align-items-center pe-3 mt-4 pt-3'>
                        <div className='avatar avatar--big' style={{backgroundImage: `url(${user?.picture})`}}></div>
                        {/* <div className='nu-icon nu-icon--mid cursor-pointer' onClick={()=>{
                             openFilePicker()
                        }}>
                            <MdModeEdit color={darkBlue} size={24}></MdModeEdit>
                        </div> */}
                    </div>

                    <div className='col d-flex flex-column'>
                        <div className='col '>

                        <div className='d-flex align-items-center cursor-pointer mb-3' onClick={()=>{
                            navigate(-1)
                        }}>
                                <div className='nu-icon nu-icon--mid'>
                                    <MdArrowBack size={17} color={darkBlue}></MdArrowBack>
                                </div>
                                <div className='f-14 bold text-darkblue'>Atrás</div>
                        </div>

                            <div className='bg-gray r-10 p-4'>
                                <div className='d-flex'>
                                    {/* <div className='f-22 bold mb-3'>Sobre ti</div> */}
                                    <div className='col'></div>

                                    {/* <div className='d-flex align-items-center cursor-pointer' onClick={()=>{
                                        setState({...state, editing_general:!state.editing_general})
                                    }}>
                                        <div className='f-14 text-darkblue bold mx-2'>Editar</div>
                                        <div className='nu-icon nu-icon--mid' onClick={()=>{

                                        }}>
                                            <MdModeEdit color={darkBlue} size={24}></MdModeEdit>
                                        </div>
                                    </div> */}
                                </div>

                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col'>
                                        <div className='f-16 pe-5 bold'>Organización</div>
                                    </div>
                                    <div className='col'>
                                        <input disabled={!state.editing_general} defaultValue={state.organization?.name} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                            state.organization.name = evt.currentTarget.value;
                                            setState({...state})
                                        }}></input>
                                    </div>
                                    <div className='col'>
                                    <div className='f-16 ps-5 bold'>Contraseña actual:</div>
                                </div>
                                <div className='col'>
                                <input disabled={!state.editing_general} value={state.password} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                     state.password = evt.currentTarget.value;
                                     setState({...state})
                                }}></input>
                                </div>
                                </div>

                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col'>
                                        <div className='f-16 pe-5 bold'>CIF / NIF</div>
                                    </div>
                                    <div className='col'>
                                        <input disabled={!state.editing_general} defaultValue={state.organization?.cif} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                            state.organization.cif = evt.currentTarget.value;
                                        }}></input>
                                    </div>
                                    <div className='col'>
                                        <div className='f-16 ps-5 bold'>Nueva contraseña</div>
                                    </div>
                                    <div className='col'>
                                        <input disabled={!state.editing_general}  value={state.passwordConfirmed}  className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                        state.passwordConfirmed = evt.currentTarget.value;
                                        setState({...state})
                                    }}></input>
                                    <div className='text-danger f-12'>{state.errors?.password}</div>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col'>
                                        <div className='f-16 pe-5 bold'>Dirección</div>
                                    </div>
                                    <div className='col'>
                                    <input disabled={!state.editing_general} defaultValue={state.organization?.address} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                            state.organization.address = evt.currentTarget.value;
                                        }}></input>
                                    </div>
                                    <div className='col'>
                                        <div className='f-16 ps-5 bold'>Personal de referencia contratación:</div>
                                    </div>
                                    <div className='col'>
                                    {/* <input className={`nu-input--light p-2 opacity-0 ${!state.editing_general ? 'nu-input--disabled': ''}`}></input> */}
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col'>
                                        <div className='f-16 pe-5 bold'>Código postal</div>
                                    </div>
                                    <div className='col'>
                                    <input disabled={!state.editing_general} defaultValue={state.organization?.postal_code} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                            state.organization.postal_code = evt.currentTarget.value;
                                        }}></input>
                                    </div>
                                    <div className='col'>
                                        <div className='f-16 ps-5 bold'>Nombre</div>
                                    </div>
                                    <div className='col'>
                                    <input disabled={!state.editing_general} defaultValue={state.organization?.contact_name} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                            state.organization.contact_name = evt.currentTarget.value;
                                        }}></input>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col'>
                                        <div className='f-16 pe-5 bold'>Comunidad Autónoma</div>
                                    </div>
                                    <div className='col'>

                                    <select 
                                        disabled={!state.editing_general}   
                                        value={state.organization?.community} 
                                        onChange={(evt)=>{
                                            state.organization.community = evt.currentTarget.value;
                                            setState({...state})
                                        }}  
                                        className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`}>

                                        <option value="">Seleccionar</option>
                                        {
                                            provincias.map((item, i)=>{
                                                return(
                                                    <option key={i} value={item.provincia}>{item.provincia}</option>
                                                )
                                            })
                                        }
                            
                                    </select>

                                    </div>
                                    <div className='col'>
                                        <div className='f-16 ps-5 bold'>Correo electrónico</div>
                                    </div>
                                
                                    <div className='col'>
                                        <input disabled defaultValue={state.organization?.email} className={`nu-input--light p-2 nu-input--disabled`}></input>
                                    </div>
                                </div>

                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col'>
                                        <div className='f-16 pe-5 bold'>Provincia</div>
                                    </div>
                                    <div className='col'>

                                        <select
                                            disabled={!state.editing_general}   
                                            value={state.organization?.province}  
                                            className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.organization.province = evt.currentTarget.value;
                                                setState({...state })
                                            }}>
                                            <option value="">Seleccionar</option>
                                            {
                                                provincias.filter((provincia)=> provincia.provincia === state.organization?.community)[0]?.colegios?.map((item, i)=>{

                                                    
                                                    return(
                                                        <option key={i} value={item}>{item}</option>
                                                    )
                                                })
                                            }
                                        </select>


                                    </div>
                                    <div className='col'>
                                      
                                    </div>
                                    <div className='col'>
                                  
                                    </div>
                                </div>

     


                                <div className='d-flex justify-content-center align-items-center  mt-2'>
                                    <div className='col'>
                                        <div className='f-16 pe-5 bold'>Teléfono</div>
                                    </div>
                                    <div className='col'>
                                    <input disabled={!state.editing_general} defaultValue={state.organization?.phone_number} className={`nu-input--light p-2 ${!state.editing_general ? 'nu-input--disabled': ''}`} onChange={(evt)=>{
                                            state.organization.phone_number = evt.currentTarget.value;
                                        }}></input>
                                    </div>
                                    <div className='col'>
                                        {/* <div className='f-16 ps-5 bold'>Correo electrónico</div> */}
                                    </div>
                                    <div className='col'>
                                        {/* province */}
                                    </div>
                                </div>

                                <Renderif isTrue={state.editing_general}>
                                    <div className='d-flex'>
                                        
                                        <div className='col'></div>

                                        <div className='nu_btn nu_btn--primary' onClick={()=>{
                                            onUpdateProfile()
                                        }}>
                                            <div className='nu-icon nu-icon--mid me-1'>
                                                <IoMdSave size={18}></IoMdSave>
                                            </div>
                                            <div className='nu_btn-text'>Guardar</div>
                                        </div>
                                    </div>
                                </Renderif>

                            </div>

                        </div>

                        <div className='col'>

                            {state.centers?.map((center, i)=>{
                                   let info;
                                   try {
               
                                      info = JSON.parse(center.info)
                                      console.log(info)
                                    
                                  } catch (error) {
                                      console.log(error)
                                      info = {
                                          Parking:'',
                                          Dietas:'',
                                          Uniforme:'',
                                          Location:'',
                                          Notas:''
                          
                                      }
                                  }
                                return (
                                    <div key={i} className='bg-gray r-10 p-4 mt-4'>
                                    <div className='d-flex'>
                                        <div className='f-22 bold mb-3'>Información del Centro Sanitario</div>
                                        <div className='col'></div>
    
                                        {/* <div className='d-flex align-items-center cursor-pointer' onClick={()=>{
                                            state.description = parseDescription(center.description)
                                            setState({...state, editing_center:center.id})
                                        }}>
                                            <div className='f-14 text-darkblue bold mx-2'>Editar</div>
                                            <div className='nu-icon nu-icon--mid' onClick={()=>{
    
                                            }}>
                                                <MdModeEdit color={darkBlue} size={24}></MdModeEdit>
                                            </div>
                                        </div> */}
                                    </div>

                                    <div className='d-flex flex-wrap justify-content-center align-items-center  mt-2'>
                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 pe-5 bold'>Nombre</div>
                                        </div>

                                        <div className='col-8 col-md-4'>
                                            <input 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            defaultValue={state.centers[i].name} 
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.centers[i].name = evt.currentTarget.value;
                                            }}></input>
                                        </div>

                                        <div className='col-4 col-md-2'>
                                            <div className='f-16 ps-md-3 bold'>Tipo de Centro</div>
                                        </div>

                                        <div className='col-8 col-md-4 mt-2 mt-md-0'>
                                            <select 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            
                                            value={state.centers[i].idCenterType}
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.centers[i].idCenterType = parseInt(evt.currentTarget.value)
                                                setState({...state})
                                            }}>

                                            <option value=''>Seleccionar</option> 
                                                {alphaOrder(centerType, 'description').map((item,i)=>{
                                                if(item.id==1) return;
                                           
                                                
                                                return (
                                                    <option key={i} value={item.id}>{item.description}</option> 
                                                )
                                          
                                        })}
                                            </select>


                                        </div>
                                    </div>
    
                                    {/* <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-2'>
                                            <div className='f-16 pe-5 bold'>Nombre</div>
                                        </div>
                                        <div className='col'>
                                            <input 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            defaultValue={state.centers[i].name} 
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.centers[i].name = evt.currentTarget.value;
                                            }}></input>
                                        </div>
                                    </div> */}
    
                                    <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-2'>
                                            <div className='f-16 pe-5 bold'>Dirección</div>
                                        </div>
                                        <div className='col'>
                                             <input 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            defaultValue={state.centers[i].address} 
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.centers[i].address = evt.currentTarget.value;
                                            }}></input>
                                        </div>
                                    </div>
    
                
                                    <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-2'>
                                            <div className='f-16 pe-5 bold'>Código postal</div>
                                        </div>
                                        <div className='col'>
                                        <input 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            defaultValue={state.centers[i].postalCode} 
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.centers[i].postalCode = evt.currentTarget.value;
                                            }}></input>
                                        </div>
                                        <div className='col-2'>
                                            <div className='f-16  text-center bold'>Provincia</div>
                                        </div>
                                        <div className='col'>
                                        <select disabled={state.editing_center === center.id ? false : true}   defaultValue={state.centers[i].province} onChange={(evt)=>{
                                        
                                        state.centers[i].province = evt.currentTarget.value;
                                            // state.organization.province = evt.currentTarget.value;
                                            // setState({...state})

                                            }}  className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`}>
                                            <option value="" disabled>Seleccionar</option>
                                            {
                                                provincias.map((item, i)=>{
                                                    return(
                                                        <option key={i} value={item.provincia}>{item.provincia}</option>
                                                    )
                                                })
                                            }
                                
                                        </select>
                                        {/* <input 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            defaultValue={state.centers[i].province} 
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.centers[i].province = evt.currentTarget.value;
                                            }}></input> */}
                                        </div>
                                    </div>
    
                                    <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-2'>
                                            <div className='f-16 pe-5 bold'>Parking</div>
                                        </div>
                                        <div className='col'>
                                        <input 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            defaultValue={info?.Parking} 
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.description.Parking = evt.currentTarget.value
                                            }}></input>
                                        </div>
                                    </div>
    
    
                                    <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-2'>
                                            <div className='f-16 pe-5 bold'>Dietas</div>
                                        </div>
                                        <div className='col'>
                                        <input 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            defaultValue={info?.Dietas} 
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.description.Dietas = evt.currentTarget.value
                                            }}></input>
                                        </div>
                                    </div>
    
                                    <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-2'>
                                            <div className='f-16 pe-5 bold'>Uniforme</div>
                                        </div>
                                        <div className='col'>
                                        <input 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            defaultValue={info?.Uniforme} 
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.description.Uniforme = evt.currentTarget.value
                                            }}></input>
                                        </div>
                                    </div>
    
                                    <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-2'>
                                            <div className='f-16 pe-5 bold'>Punto de encuentro</div>
                                        </div>
                                        <div className='col'>
                                        <input 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            defaultValue={info?.Location} 
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.description.Location = evt.currentTarget.value
                                            }}></input>
                                        </div>
                                    </div>
    
                                    <div className='d-flex justify-content-center align-items-center  mt-2'>
                                        <div className='col-2'>
                                            <div className='f-16 pe-5 bold'>Notas</div>
                                        </div>
                                        <div className='col'>
                                        <input 
                                            disabled={state.editing_center === center.id ? false : true} 
                                            defaultValue={info?.Notas} 
                                            className={`nu-input--light p-2 ${state.editing_center === center.id ? false : true ? 'nu-input--disabled': ''}`} 
                                            onChange={(evt)=>{
                                                state.description.Notas = evt.currentTarget.value
                                            }}></input>
                                        </div>
                                    </div>
    
                                    <Renderif isTrue={state.editing_center === center.id ? true : false}>
                                        <div className='d-flex mt-3'>
                                            
                                            <div className='col'></div>
    
                                            <div className='nu_btn nu_btn--primary' onClick={()=>{
                                                // setState({...state, show_turno_detail:!state.show_turno_detail})
                                                updateCenter()
                                               
                                            }}>
                                                <div className='nu-icon nu-icon--mid me-1'>
                                                    <IoMdSave size={18}></IoMdSave>
                                                </div>
                                                <div className='nu_btn-text'>Guardar</div>
                                            </div>
                                        </div>
                                    </Renderif>
    
                                    </div>
                                )
                            })}

                         

                        

                        

                        </div>
                    </div>
                </div>



            </div>

            
 

    
        </div>

   
   
  
     
    </div>
    </>
  )
}
