import React, { useEffect, useState } from 'react'
import Calendar from 'react-calendar'
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import Renderif from '../utils/renderif';
import { MdBlock } from 'react-icons/md';
import { UTCDateParser } from '../utils';

const formatMonthYear  = (date, locale) => {
  // Formatear la fecha para mostrar sólo el mes y el año, sin "De"
  return format(date, 'MMMM yyyy', { locale: es });
}
const formatShortWeekday = (locale, date) => {
  return format(date, 'eee', { locale: es });
};
export function NuCalendarProfesional({value, onChange, turnos = []}) {

  let date = new Date();
  // date.setDate(date.getDate() + 1)
  const [state, setState] = useState({
    active:false,
    dateActive:null
  })

  return (
    <div className={`p-0 w-100 ${state.active ? 'bv-calendar-v1':'bv-calendar'}`}>
        <Calendar
          tileContent={(tile)=>{

            
            

            var e = Date.UTC(tile.date.getFullYear(), tile.date.getMonth(), tile.date.getDate(), 0,0,0)
            let _tile_date = UTCDateParser(new Date(e).toUTCString())

            
      
            let _today = new Date();
            var today = new Date(Date.UTC(_today.getFullYear(), _today.getMonth(), _today.getDate(), 0,0,0))

            
        
            let dated = [];
            turnos.map((turno)=>{
              
              let _turno_date = UTCDateParser(new Date(turno.date).toUTCString())
              if(_turno_date === _tile_date){
                dated.push(turno)
              }

            })
        
            let dots = []
            if( e < today.getTime() ){
           
              if(dated.some((item) => item.idUser !== null)){
                dots.push('darkblue');
              }
              
            }else{
             
              if(dated.some((item) => item.idUser !== null)){
                dots.push('green');
              }
              if(dated.some((item) => item.type === '0')){
                dots.push('orange');
              }
              if(dated.some((item) => item.type === '1')){
                dots.push('lightblue');
              }
            }

          
            
          
            return (

              <Renderif isTrue={dated.length > 0}>
                <div className='dot-dates'>
                  {dots.map((item, i)=>{
                    
                    return (
                      
                        
                          <div key={i} className={`dot-date dot-date--${item}`}></div>
                  
                    
                    )
                  })}
                

                </div>
              </Renderif>

            

            )
          }}
        allowPartialRange={false}
        showNeighboringMonth={false}
        formatMonthYear={(locale, date) => formatMonthYear(date, es)}
        formatShortWeekday={formatShortWeekday}
        onChange={(evt)=>{

       

          var e = Date.UTC(evt.getFullYear(), evt.getMonth(), evt.getDate(), 0,0,0)
       

          if(state.dateActive === null){        
            state.dateActive = evt
            state.active = true;
            onChange(e)
          }else{

            if(state.dateActive.toString() === evt.toString()){       
              state.active = false;
              state.dateActive = null
              onChange(null)
            }else{      
              state.dateActive = evt
              onChange(e)
            }
          }        
          
          setState({...state})
          // onChange(evt)
        }}
      
        tileClassName={['bv-calendar-day']}
        ></Calendar>
    </div>
  )
}

export function NuCalendarClinic({value, onChange, turnos = []}) {  



  const [state, setState] = useState({
    active:false,
    dateActive:null
  })


  return (
    <div className={`p-0 w-100 ${state.active ? 'bv-calendar-v1':'bv-calendar'}`}>
        <Calendar
          tileContent={(tile)=>{
            
            var e = Date.UTC(tile.date.getFullYear(), tile.date.getMonth(), tile.date.getDate(), 0,0,0)
            let _tile_date = UTCDateParser(new Date(e).toUTCString())
           


            let _today = new Date();
            var today = new Date(Date.UTC(_today.getFullYear(), _today.getMonth(), _today.getDate(), 0,0,0))
            
            let dated = [];
            turnos.map((turno)=>{
              let _turno_date = UTCDateParser(new Date(turno.date ).toUTCString())
              if(_turno_date === _tile_date){
                dated.push(turno)
              }
            })

            let dots = [];

            if( e < today.getTime() ){

              if(dated.some((item) => item.idUser !== null)){
                dots.push('darkblue');
              }

            }else{

              if(dated.some((item) => item.idUser !== null)){
                dots.push('green');
              }

              if(dated.some((item) => item.type === '0')){
                dots.push('orange');
              }

              if(dated.some((item) => item.type === '1')){
                dots.push('lightblue');
              }

            }

            return (

              <Renderif isTrue={dated.length > 0}>
                <div className='dot-dates'>
                  {dots.map((item, i)=>{
                  
                    return (
                      
                        
                          <div key={i} className={`dot-date dot-date--${item}`}></div>
                  
                      
                    )
                  })}
                

                </div>
              </Renderif>

            

            )
          }}
        allowPartialRange={false}
        showNeighboringMonth={false}
        formatMonthYear={(locale, date) => formatMonthYear(date, es)}
        formatShortWeekday={formatShortWeekday}
        onChange={(evt)=>{    
          
          var e = Date.UTC(evt.getFullYear(), evt.getMonth(), evt.getDate(), 0,0,0)

          if(state.dateActive === null){      
            state.dateActive = evt
            state.active = true;
            onChange(e)
          }else{

            if(state.dateActive.toString() === evt.toString()){
         
              state.active = false;
              state.dateActive = null
              onChange(null)
            }else{
            
              state.dateActive = evt
              onChange(e)
            }
          }
         
          
          setState({...state})
          // onChange(evt)
        }}
      
        tileClassName={['bv-calendar-day']}
        ></Calendar>
    </div>
  )
}

export function NuCalendarMulti(props) {

  const [state, setState] = useState({
    monthActive: new Date().getMonth(),
    dates:[]
  })

  useEffect(()=>{

    if(props.dates.length === 0){
      setState({...state, dates:[]})
    }
  },[props.dates])

  const filter =() =>{
    let _dates = state.dates.filter((item)=>{
   
      if(item.date.getMonth() === state.monthActive){
        return true;

      }
      return false
    })
    return _dates;
  }
  return (
    <div className='bv-calendar p-0 position-relative'>
        <Calendar
        tileContent={(tile)=>{

          let today = new Date();
          today.setHours('00','00','00')
  
          let show = false
          if(tile.date.toUTCString() === today.toUTCString()){
            show =  true;
          }



          return (
          <>
            <Renderif isTrue={show}>
              
              <div className='tile-block'></div>
            </Renderif>
            
            {/* <Renderif isTrue={today.getTime() > tile.date.getTime()}>
              <div className='tile-block'></div>
            </Renderif> */}
           
          </>
          

          )
        }}
        allowPartialRange={false}
        // selectRange={true}
        showNeighboringMonth={false}
        formatMonthYear={(locale, date) => formatMonthYear(date, es)}
        formatShortWeekday={formatShortWeekday}
        onViewChange={(evt)=>{
       
        }}
        onClickMonth={(evt)=>{
        
        }}
        onActiveStartDateChange={(evt)=>{
       
          setState({...state, monthActive:evt.activeStartDate.getMonth()})
        }}
        // defaultValue={values}

        onChange={(value, evt)=>{


          let today = new Date();
          today.setHours('00','00','00')

          
          value.setHours('23','59','59')
          
        

          if(value < today){
           
            return
          }


          if(state.dates.filter((item)=>item.date.toString() === value.toString()).length > 0){

            state.dates = state.dates.filter((item)=>item.date.toString() !== value.toString())
          }else{
            let date = {
              date:value,
              x:evt.target.offsetLeft,
              y:evt.target.offsetTop
            }
  
  
            state.dates.push(date)
          }
     
          setState({...state})
          props.onChange(value, state.dates)
        }}
      
        tileClassName={['bv-calendar-day']}
        ></Calendar>
        <div className='calendar-place'>
          {
            filter().map((item, i)=>{
              return(
                <div key={i} className='place-selected' style={{left:item.x, top:item.y}}></div>
              )
            })
          }
        </div>
    </div>
  )
}

export default function NuCalendar({value, onChange}) {

  let date = new Date(value);
  let gmt = date.toString().split(' ')
  let zone = gmt[5].split('-')[1]
  if(zone === undefined){
    zone = gmt[5].split('+')[1]
  }
  let _turno_date = new Date(Date.parse(date.toUTCString() + '-' + zone))
  console.log(date)
  console.log(zone)
  console.log(_turno_date)

  // console.log(gmt[5].split('-')[1])
  // console.log(new Date(Date.parse(date.toUTCString() + '-' + gmt[5].split('-')[1])))


  return (
    <div className='bv-calendar-v1 p-0 w-100'>
        <Calendar
        allowPartialRange={false}
        // selectRange={true}
        showNeighboringMonth={false}
        defaultValue={_turno_date}
        formatMonthYear={(locale, date) => formatMonthYear(date, es)}
        formatShortWeekday={formatShortWeekday}
        onChange={(evt)=>{
       
          onChange(evt)
        }}
      
        tileClassName={['bv-calendar-day']}
        ></Calendar>
    </div>
  )
}

// react-calendar__tile react-calendar__month-view__days__day
// react-calendar__tile react-calendar__month-view__days__day

// react-calendar__tile react-calendar__tile--active react-calendar__tile--range react-calendar__tile--rangeStart react-calendar__tile--rangeEnd react-calendar__tile--rangeBothEnds react-calendar__month-view__days__day react-calendar__month-view__days__day--weekend
